import { useRef, useEffect, useState, createContext } from 'react';
import Authentication from '../../authentication/authentication';
import { IAuthentication, TLogin } from '../../authentication/types';

const AuthContext = createContext<IAuthentication>({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => false,
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;

type AuthProviderProps = {
  login: TLogin;
  children: JSX.Element;
};

export function AuthProvider({ login, children }: AuthProviderProps) {
  const authentication = useRef<IAuthentication>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    authentication.current = new Authentication(login);
    setInitialized(true);
  }, []);

  return (
    <>
      {initialized && authentication.current && (
        <AuthContext.Provider value={authentication.current}>{children}</AuthContext.Provider>
      )}
    </>
  );
}
