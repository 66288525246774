import produce from 'immer';
import {
  MeetupAction,
  TMeetupState,
  SET_MEETUP,
  SET_LAST_MEETUP_CREATED,
  SET_IMAGE,
  SET_MEETUPS,
  SET_LOCATIONS,
  SET_REGIONS,
  SET_PARTICIPANT_TYPES,
  SET_COMMITTEES,
  SET_MEETUP_TYPES,
} from './types';

const initialState: TMeetupState = {
  meetup: {},
  lastMeetupCreated: {},
  image: null,
  meetups: [],
  committees: [],
  meetupTypes: [],
  locations: [],
  regions: [],
  participantTypes: [],
  sustainableDevelopmentGoals: []
};

const meetupReducer = (state = initialState, action: MeetupAction): TMeetupState => {
  switch (action.type) {
    case SET_MEETUP:
      return produce(state, (draft) => {
        draft.meetup = action.payload;
      });
    case SET_LAST_MEETUP_CREATED:
      return produce(state, (draft) => {
        draft.lastMeetupCreated = action.payload;
      });
    case SET_IMAGE:
      return produce(state, (draft) => {
        draft.image = action.payload;
      });
    case SET_MEETUPS:
      return produce(state, (draft) => {
        if (action.payload.concat) {
          draft.meetups = [...action.payload.meetups, ...draft.meetups];
        } else {
          draft.meetups = action.payload.meetups;
        }
      });
    case SET_COMMITTEES:
      return produce(state, (draft) => {
        draft.committees = action.payload;
      });
    case SET_MEETUP_TYPES:
      return produce(state, (draft) => {
        draft.meetupTypes = action.payload;
      });
    case SET_LOCATIONS:
      return produce(state, (draft) => {
        draft.locations = action.payload;
      });
    case SET_REGIONS:
      return produce(state, (draft) => {
        draft.regions = action.payload;
      });
    case SET_PARTICIPANT_TYPES:
      return produce(state, (draft) => {
        draft.participantTypes = action.payload;
      });
    default:
      return state;
  }
};

export default meetupReducer;
