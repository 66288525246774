import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import * as meetupActions from '../../store/meetups/actions';
import * as uiActions from '../../store/ui/actions';
import { TMeetup } from '../../store/meetups/types';
import getErrorMessage from '../../helpers/errorMessage';
import MeetupEditLocationNew from './MeetupEditLocationNew';
import { FieldsLocation } from '../../helpers/fields';
import useDebounce from '../../hooks/useDebounce';
import useFieldEvent from '../../hooks/useFieldEvent';
import { Button, Grid, Group, SelectField, TextField } from '@ingeniorforeningen/figurine-core';
import { toSelectFieldProps } from '../../helpers/utils';

const STEP_NUMBER = 3;

export default function MeetupEditLocation() {
  const dispatch = useDispatch();
  const language = useSelector((state: TState) => state.ui.language);
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const fieldValidationState = useSelector((state: TState) => state.ui.fieldValidation);
  const locations = useSelector((state: TState) => state.meetups.locations);
  const regionsList = useSelector((state: TState) => state.meetups.regions);
  const regions =
    meetup.MeetupNumber == null
      ? regionsList.filter((region) => {
          return region.value !== 0 && region.label !== 'Online arrangement';
        })
      : regionsList;
  const [showModal, setShowModal] = useState(false);
  const [locationInputText, setLocationInputText] = useState('');
  const [locationInputLoading, setLocationInputLoading] = useState(false);
  const debouncedLocationInputText = useDebounce(locationInputText, 120) as string;
  const { onInputChange, onInputBlur } = useFieldEvent(STEP_NUMBER);

  useEffect(() => {
    // Populate location dropdown when meetup already has a meetup location.
    if (meetup.LocationId) {
      dispatch(meetupActions.getLocation(meetup.LocationId));
    }

    if (regions.length === 0) {
      dispatch(meetupActions.getRegions());
    }

    if (meetup.LocationId) {
      // This catches when a new location is created.
      dispatch(
        uiActions.updateFieldValidation({
          step: STEP_NUMBER,
          fields: [FieldsLocation.LocationId],
          meetup,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetup]);

  // Fetch meetup locations by debouncing user input for 200 ms in order not to create too many calls.
  useEffect(() => {
    const fetchLocations = async () => {
      setLocationInputLoading(true);
      await dispatch(meetupActions.getLocations(debouncedLocationInputText));
      setLocationInputLoading(false);
    };
    if (debouncedLocationInputText && debouncedLocationInputText.length > 1) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocationInputText]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onSelectChange = (e: {
    value: number | string | string[];
    name: string;
    event?: React.ChangeEvent<HTMLInputElement>;
  }) => {
    const payload = {
      ...meetup,
      [e.name as keyof TMeetup]: e.value,
    };
    dispatch(meetupActions.setMeetup(payload));
    dispatch(
      uiActions.updateFieldValidation({
        step: STEP_NUMBER,
        fields: [e.name],
        meetup: payload,
      }),
    );
  };

  return (
    <>
      <MeetupEditLocationNew
        show={showModal}
        locationName={locationInputText}
        onClose={() => toggleModal()}
        onCreate={(newLocation) => {
          toggleModal();
          dispatch(meetupActions.createLocation(newLocation, meetup));
        }}
      />
      <Group direction="column" spacing="xl" mt="xl" grow>
        <SelectField
          name={FieldsLocation.LocationId}
          label={texts[language].location.label}
          placeholder={texts[language].location.placeholder}
          description={texts[language].location.helpText}
          icon="Search"
          searchable
          loading={locationInputLoading}
          options={toSelectFieldProps(locations)}
          value={meetup.LocationId || ''}
          onChange={(value) => onSelectChange({ name: FieldsLocation.LocationId, value })}
          onSearchChange={setLocationInputText}
          creatable
          getCreateLabel={(value) => <Button text={`Opret sted: ${value}`} />}
          onCreate={() => {
            toggleModal();
            return ''; //TODO: Fix in Figurine, so no need to return empty string
          }}
          mb="xl"
          required
          variant="outline"
          maxDropdownHeight={600}
          cypress="locationId"
        />

        <TextField
          name={FieldsLocation.LocationAdditionalInfo}
          label={texts[language].locationAdditionalInfo.label}
          placeholder={texts[language].locationAdditionalInfo.placeholder}
          description={texts[language].locationAdditionalInfo.helpText}
          onBlur={onInputBlur}
          onChange={onInputChange}
          value={meetup.LocationAdditionalInfo || ''}
          error={getErrorMessage(
            fieldValidationState,
            STEP_NUMBER,
            FieldsLocation.LocationAdditionalInfo,
          )}
          maxLength={100}
          cypress="locationAdditionalInfo"
        />

        <SelectField
          name={FieldsLocation.Region}
          label={texts[language].region.label}
          placeholder={texts[language].region.placeholder}
          aria-describedby={texts[language].region.helpText}
          description={texts[language].region.helpText}
          onChange={(value) =>
            onSelectChange({ name: FieldsLocation.Region, value: parseInt(value) })
          }
          value={meetup.Region?.toString() ?? ''}
          options={toSelectFieldProps(regions)}
          error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsLocation.Region)}
          required
          cypress="region"
        />
      </Group>
    </>
  );
}
