import { useNavigate } from 'react-router-dom';
import { format, differenceInMilliseconds } from 'date-fns';
import { da } from 'date-fns/locale';
import {
  Paper,
  Heading,
  Group,
  Badge,
  useTheme,
  useMediaQuery,
} from '@ingeniorforeningen/figurine-core';
import { TMeetup } from '../../store/meetups/types';
import MeetupDate from './MeetupDate';
import MeetupMenu from './MeetupMenu';
import MeetupSeatingInfo from './MeetupSeatingInfo';
import { TinyText } from '../Common/CustomStyling';

const PublicationStatus = (publicationTime: Date | null | undefined) => {
  if (publicationTime && differenceInMilliseconds(publicationTime, new Date()) < 1) {
    return <Badge text="Publiceret" variant="filled" type="default" />;
  }
  return <Badge text="Ikke publiceret" variant="filled" type="error" />;
};

type Props = {
  meetup: Partial<TMeetup>;
};

function MeetupStatus(status: number | undefined) {
  switch (status) {
    case 0:
      return <Badge text="Afholdes" type="success" />;
    case 1:
      return <Badge text="Afholdt" />;
    case 2:
      return <Badge text="Aflyst" type="error" />;
    default:
      return <></>;
  }
}

export default function Meetup({ meetup }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.sm})`);

  const handleClick = () => {
    if (window.getSelection()?.toString()) {
      return null;
    } else {
      navigate(`/arrangementer/${meetup.MeetupNumber}`);
    }
  };

  return (
    <Paper cypress="meetup" mb="xl" style={{ cursor: 'pointer' }}>
      <Group onClick={() => handleClick()} spacing="xl" pb="sm">
        {meetup.StartTime && !isMobile && <MeetupDate date={meetup.StartTime} />}
        <Group direction="column" justify="space-between" style={{ flex: 1 }}>
          <Group noWrap justify="space-between">
            <Group direction="column" spacing={0} style={{ flex: 1 }}>
              <Group justify="space-between">
                <Group style={{ flex: 1 }} align="center" spacing={0}>
                  {meetup.StartTime && isMobile && (
                    <Group mb="xs" style={{ width: '100%' }}>
                      <TinyText>
                        {`Starttidspunkt: ${format(meetup.StartTime, 'd. MMMM yyyy  HH:mm', {
                          locale: da,
                        })}`}
                      </TinyText>
                    </Group>
                  )}
                  {meetup.MainOrganizer && (
                    <Heading type="h4" text={`${meetup.MainOrganizer}`} mr="xs" />
                  )}
                  <Heading type="h4" variant="primary" text={meetup.MeetupType?.Name || 'Ukendt'} />
                </Group>
                <MeetupMenu meetup={meetup} />
              </Group>
              <Heading type="h2" text={meetup.Title!} mb="xs" mt={{ base: 'sm', md: 0 }} />
              <MeetupSeatingInfo
                seatsAvailable={meetup.SeatsAvailable}
                seatsQuantity={meetup.SeatsQuantity}
                onWaitingList={meetup.OnWaitingList}
                participants={meetup.Participants}
              />
            </Group>
          </Group>
          <Group justify="space-between" align="center">
            <Group>
              {meetup.StartTime && !isMobile && (
                <Badge variant="filled" text={format(meetup.StartTime, 'HH:mm', { locale: da })} />
              )}

              <Badge variant="outline" text={`ID ${meetup.MeetupNumber}`} />

              {MeetupStatus(meetup.MeetupStatus)}
              {PublicationStatus(meetup.PublicationTime)}
            </Group>

            <Group>
              {meetup.RegistrationDeadline && (
                <TinyText>
                  {`Tilmeldingsfrist ${format(meetup.RegistrationDeadline, 'd. MMMM yyyy HH:mm', {
                    locale: da,
                  })}`}
                </TinyText>
              )}
              {meetup.CancellationDeadline && (
                <TinyText>
                  {`Afmeldingsfrist ${format(meetup.CancellationDeadline, 'd. MMMM yyyy HH:mm', {
                    locale: da,
                  })}`}
                </TinyText>
              )}
            </Group>
          </Group>
        </Group>
      </Group>
    </Paper>
  );
}
