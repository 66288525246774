import { UserManager, WebStorageStateStore, Log, User, SigninRequest } from 'oidc-client';
import { TLogin, TAuthResult, IAuthentication } from './types';

export default class Authentication implements IAuthentication {
  public static UserManager: UserManager;

  userManager: UserManager;

  accessToken = '';

  constructor(login: TLogin) {
    const IDENTITY_CONFIG = {
      authority: login.authority,
      client_id: login.client_id,
      redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/signin-oidc`,
      silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/silent-renew-oidc`,
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/signout-callback-oidc`,
      automaticSilentRenew: true,
      loadUserInfo: true,
      response_type: 'code',
      scope: 'openid profile roles meetup-create.user',
      filterProtocolClaims: true,
    };

    Authentication.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    this.userManager = Authentication.UserManager;

    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.userManager.events.addUserLoaded((user) => {
      console.log(user);
      this.accessToken = user.access_token;
      localStorage.setItem('access_token', user.access_token);
      localStorage.setItem('id_token', user.id_token);
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token,
      });
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });

    this.userManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      // this.signinSilent();
    });

    this.userManager.events.addUserSignedOut(() => {
      console.log('User signed out');
      this.userManager.clearStaleState();
      this.userManager.signinRedirect();
    });
  }

  signinRedirectCallback = (): void => {
    this.userManager.signinRedirectCallback().then(() => {
      ('');
    });
  };

  getUser = async (): Promise<User> => {
    let user = await this.userManager.getUser();

    if (!user) {
      user = await this.userManager.signinRedirectCallback();
    }

    return user;
  };

  parseJwt = (token: string): any => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  setUserInfo = (authResult: TAuthResult): void => {
    const data = this.parseJwt(this.accessToken);
    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = (): void => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.userManager.signinRedirect({});
  };

  setUser = (data: any): void => {
    localStorage.setItem('userId', data.sub);
  };

  navigateToScreen = (): void => {
    const uri = localStorage.getItem('redirectUri');
    const redirectUri = uri || '/';
    window.location.replace(redirectUri);
  };

  setSessionInfo = (authResult: TAuthResult) => {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
  };

  isAuthenticated = (): boolean => {
    const accessToken = localStorage.getItem('access_token');
    return accessToken !== null && this.parseJwt(accessToken).exp > Date.now() / 1000;
  };

  signinSilent = async () => {
    try {
      const user = await this.userManager.signinSilent();
      console.log('signed in', user);
    } catch (err) {
      if (err.error === 'login_required') {
        this.signinRedirect();
      }
      console.log(err);
    }
  };

  signinSilentCallback = (): void => {
    this.userManager.signinSilentCallback();
  };

  createSigninRequest = (): Promise<SigninRequest> => {
    return this.userManager.createSigninRequest();
  };

  logout = () => {
    this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.userManager.clearStaleState();
  };

  signoutRedirectCallback = async () => {
    await this.userManager.signoutRedirectCallback();
    localStorage.clear();
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`,
    );
    this.userManager.clearStaleState();
  };
}
