import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import { FieldsSpeakerEdit } from '../../helpers/fields';
import {
  RegisterSpeakerResponse,
  RegistrationStatus,
  TMeetupSpeaker,
} from '../../store/meetups/types';
import {
  Button,
  Group,
  Loader,
  Modal,
  Paragraph,
  TextField,
} from '@ingeniorforeningen/figurine-core';

function mapRegistrationStatusToText(responseStatus: number, status: RegistrationStatus | null) {
  if (responseStatus >= 500) {
    return 'Der opstod en fejl. Kontakt venligst netværkskoordinator.';
  }

  if (status == null) {
    return 'Foredragsholder med den email kunne ikke tilføjes.';
  }

  switch (status) {
    case RegistrationStatus.RegistrationDeadlineExceeded:
      return 'Seneste tilmeldingsdato er overskredet';
    case RegistrationStatus.NotAvailableForParticipantType:
      return 'Kan ikke tilmelde foredragsholder. Mangler der deltagertyper på arrangementet?';
    case RegistrationStatus.ParticipantTypeSoldOut:
      return 'Der er for mange foredragsholdere';
    case RegistrationStatus.AlreadyRegistered:
      return 'Denne foredragsholder er allerede tilmeldt';
    case RegistrationStatus.MeetupCancelled:
      return 'Mødet er aflyst';
    default:
      return 'Foredragsholder med den email kunne ikke tilføjes';
  }
}

type Props = {
  show: boolean;
  speaker?: TMeetupSpeaker;
  onClose: () => void;
  meetupNumber: number | undefined;
};

export default function MeetupEditSpeakerEdit({ show, speaker, onClose, meetupNumber }: Props) {
  const language = useSelector((state: TState) => state.ui.language);
  const [speakerEdit, setSpeakerEdit] = useState({
    [FieldsSpeakerEdit.IdaNumber]: '',
    [FieldsSpeakerEdit.Name]: '',
    [FieldsSpeakerEdit.Title]: '',
    [FieldsSpeakerEdit.Email]: '',
    [FieldsSpeakerEdit.MobilePhone]: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<number | null>();
  const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatus | null>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpeakerEdit({ ...speakerEdit, [e.target.name]: e.target.value as string });
  };

  const defaultValidation = {
    Name: { valid: false, errorMessage: '' },
    Email: { valid: false, errorMessage: '' },
  };

  const [validations, setValidations] = useState<{
    [key: string]: { valid: boolean; errorMessage: string };
  }>(defaultValidation);

  const validateField = async (fieldName: string, speakerValidate: any) => {
    const response = await axios.post(
      `${runtimeConfig.apiUrl}meetups/speakerregistrations/validate`,
      {
        RequestModel: speakerValidate,
        FieldsToValidate: [fieldName],
      },
    );

    setValidations({
      ...validations,
      [fieldName]: {
        valid: response.data.length === 0,
        errorMessage: response.data[0] ? response.data[0].ErrorMessage : '',
      },
    });
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    validateField(e.target.name, speakerEdit);
  };

  const isValid = (): boolean => {
    let valid = true;

    Object.keys(validations).forEach((key: string) => {
      if (!validations[key].valid) {
        valid = false;
      }
    });

    return valid;
  };

  const timeout = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  //To solve the issue of asynchronous Create/Delete registration of Speaker,
  //a 3 second wait has been added so that the registration can sink into the mongo database.
  //In most scenarios, when the service bus is in normal operation, 3 seconds should be enough.
  //On the other hand, waiting 3 more seconds does not have a bad effect on the user experience of the registration page.
  const executeAfterTimeout = async (callback: Function, ms = 3000) => {
    await timeout(ms);
    callback();
  };

  const createSpeaker = async (speakerNew: TMeetupSpeaker) => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await axios.post<RegisterSpeakerResponse>(
        `${runtimeConfig.apiUrl}meetups/${meetupNumber}/speakerregistrations`,
        speakerNew,
      );

      const validStatus = response.status === 200 || response.status === 201;
      const validResponse = response.data.Status === RegistrationStatus.Registered;

      if (validStatus) {
        setRegistrationStatus(response.data.Status);

        if (validResponse) {
          await executeAfterTimeout(onClose);
        } else {
          setError(response.status);
        }
      }
    } catch (err) {
      setError(err.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    setError(null);

    if (speaker) {
      setSpeakerEdit({
        [FieldsSpeakerEdit.IdaNumber]: speaker.IdaNumber,
        [FieldsSpeakerEdit.Name]: speaker.Name,
        [FieldsSpeakerEdit.Title]: speaker.Title,
        [FieldsSpeakerEdit.Email]: speaker.Email,
        [FieldsSpeakerEdit.MobilePhone]: speaker.MobilePhone,
      });
    }
  }, [show, speaker]);

  return (
    <>
      {show && (
        <Modal title={texts[language].editSpeakerModalTitle} opened={show} onClose={onClose}>
          <Group direction="column" p="xl">
            {error && (
              <Paragraph
                variant="error"
                text={mapRegistrationStatusToText(error, registrationStatus)}
              />
            )}

            <TextField
              name={FieldsSpeakerEdit.Name}
              label={texts[language].editSpeakerName.label}
              placeholder={texts[language].editSpeakerName.placeholder}
              description={texts[language].editSpeakerName.helpText}
              value={speakerEdit?.Name}
              error={validations[FieldsSpeakerEdit.Name]?.errorMessage}
              onChange={onInputChange}
              onBlur={onInputBlur}
              required
              disabled={isLoading}
              mb="md"
              variant="outline"
            />
            <TextField
              name={FieldsSpeakerEdit.Title}
              label={texts[language].editSpeakerTitle.label}
              placeholder={texts[language].editSpeakerTitle.placeholder}
              description={texts[language].editSpeakerTitle.helpText}
              value={speakerEdit?.Title}
              onChange={onInputChange}
              disabled={isLoading}
              mb="md"
              variant="outline"
            />
            <TextField
              name={FieldsSpeakerEdit.Email}
              label={texts[language].editSpeakerEmail.label}
              placeholder={texts[language].editSpeakerEmail.placeholder}
              description={texts[language].editSpeakerEmail.helpText}
              error={validations[FieldsSpeakerEdit.Email]?.errorMessage}
              value={speakerEdit?.Email}
              onChange={onInputChange}
              onBlur={onInputBlur}
              required
              disabled={isLoading}
              mb="md"
              variant="outline"
            />
            <TextField
              name={FieldsSpeakerEdit.MobilePhone}
              label={texts[language].editSpeakerMobilePhone.label}
              placeholder={texts[language].editSpeakerMobilePhone.placeholder}
              description={texts[language].editSpeakerMobilePhone.helpText}
              value={speakerEdit?.MobilePhone}
              onChange={onInputChange}
              disabled={isLoading}
              mb="md"
              variant="outline"
            />

            <Group grow>
              <Button text={texts[language].editOptionButtonClose} onClick={onClose} />
              <Button
                text={texts[language].editOptionButtonSave}
                onClick={() => createSpeaker(speakerEdit)}
                disabled={!isValid()}
                loading={isLoading}
              />
            </Group>
          </Group>
        </Modal>
      )}
    </>
  );
}
