import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { TState } from '../../store/rootReducer';
import {
  Alert,
  Caption,
  createStyles,
  Group,
  Paragraph,
  Image,
  File,
} from '@ingeniorforeningen/figurine-core';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return { border: '#0f45cc', bg: '#e9f1f7' };
  }

  if (props.isDragReject) {
    return { border: '#ff1850', bg: '#faedec' };
  }

  return { border: '#b3b3b3', bg: 'white' };
};

type StyleProps = {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
};

const styles = createStyles((theme, { isDragActive, isDragAccept, isDragReject }: StyleProps) => ({
  wrapper: {
    minHeight: '180px',
    borderWidth: '2px',
    borderRadius: '8px',
    borderColor: getColor({ isDragAccept, isDragReject }).border,
    borderStyle: 'dashed',
    backgroundColor: getColor({ isDragAccept, isDragReject }).bg,
    outline: 'none',
    cursor: 'pointer',
  },
}));

type Props = {
  onImageDrop: (image: File | null) => void;
};

const ERROR_TRANSLATE = {
  [ErrorCode.FileInvalidType]: {
    title: 'Filen er ikke af en gyldig type',
    text: 'Kun .png, .jpg og .jpeg er tilladt',
  },
  [ErrorCode.FileTooLarge]: {
    title: 'Billedet er for stor',
    text: 'Billedets størrelse må ikke overstige 10 MB, ',
  },
  [ErrorCode.FileTooSmall]: {
    title: 'Filen er for lille',
    text: '',
  },
  [ErrorCode.TooManyFiles]: {
    title: 'For mange filer',
    text: 'Det er kun tilladt at uploade 1 fil.',
  },
};

export default function MeetupEditImageDragDrop({ onImageDrop }: Props) {
  const [localImages, setLocalImages] = useState<string | undefined>();

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
      },
      maxFiles: 1,
      maxSize: 10485760,
      onDrop: (acceptedFiles) => {
        if (acceptedFiles[0]) {
          setLocalImages(URL.createObjectURL(acceptedFiles[0]));
          onImageDrop(acceptedFiles[0]);
        }
      },
    });
  const { classes } = styles({ isDragActive, isDragAccept, isDragReject });

  const image = useSelector((state: TState) => state.meetups.image);

  const lastFileRejection = fileRejections[fileRejections.length - 1];
  const lastErrorMessage = lastFileRejection
    ? lastFileRejection.errors[lastFileRejection.errors.length - 1].code
    : '';

  if (!image) {
    return (
      <div {...getRootProps()}>
        <Group
          className={classes.wrapper}
          p="xl"
          align="center"
          justify="center"
          cypress="file-uploader"
        >
          <input {...getInputProps()} />
          {!image && (
            <Group direction="column">
              <Group direction="column" align="center" spacing={0} pt="xs">
                <Paragraph text="Træk og slip eller klik for at vælge billede" />
                <Caption text="Maks. 10MB (.PNG, .JPG eller .JPEG)" />
              </Group>

              <Group direction="column">
                {lastErrorMessage && (
                  <Alert
                    type="error"
                    title={ERROR_TRANSLATE[lastErrorMessage as ErrorCode]?.title}
                    text={ERROR_TRANSLATE[lastErrorMessage as ErrorCode]?.text}
                  />
                )}
              </Group>
            </Group>
          )}
        </Group>
      </div>
    );
  }

  return (
    <div>
      {localImages && (
        <div>
          <Image
            src={localImages}
            onLoad={() => {
              URL.revokeObjectURL(localImages);
            }}
          />
        </div>
      )}
      <File
        filename={image.name}
        isFileUploaded
        mt="md"
        mb="xl"
        iconName="X"
        onClick={() => {
          onImageDrop(null);
        }}
        cypress="uploaded-file"
      />
    </div>
  );
}
