import MeetupEditImage from './MeetupEditImage';
import * as meetupActions from '../../store/meetups/actions';
import * as uiActions from '../../store/ui/actions';
import texts from '../../texts/texts';
import { useSelector, useDispatch } from 'react-redux';
import { TState } from '../../store/rootReducer';
import { FieldSustainableDevelopmentGoals } from '../../helpers/fields';
import { TMeetup } from '../../store/meetups/types';
import getErrorMessage from '../../helpers/errorMessage';
import MeetupEditSustainableDevelopmentGoals from './MeetupEditSustainableDevelopmentGoals';
import { useState } from 'react';
import { Checkbox, Group, HtmlText, Paragraph } from '@ingeniorforeningen/figurine-core';

const STEP_NUMBER = 8;

export default function MeetupEditExtras() {
  const language = useSelector((state: TState) => state.ui.language);
  const fieldValidationState = useSelector((state: TState) => state.ui.fieldValidation);
  const { meetup, sustainableDevelopmentGoals } = useSelector((state: TState) => ({
    meetup: state.meetups.meetup,
    sustainableDevelopmentGoals: state.meetups.meetup.SustainableDevelopmentGoals,
  }));
  const [showSustainableGoals, setShowSustainableGoals] = useState(
    sustainableDevelopmentGoals && sustainableDevelopmentGoals.length > 0,
  );

  const dispatch = useDispatch();

  const onSelectChange = (name: string, value: number[]) => {
    const payload = {
      ...meetup,
      [name as keyof TMeetup]: value,
    };

    dispatch(meetupActions.setMeetup(payload));

    dispatch(
      uiActions.updateFieldValidation({
        step: STEP_NUMBER,
        fields: [FieldSustainableDevelopmentGoals.SustainableDevelopmentGoals],
        meetup: payload,
        showMessage: true,
      }),
    );
  };

  const onCheckboxClick = () => {
    onSelectChange('SustainableDevelopmentGoals', []);
    setShowSustainableGoals(!showSustainableGoals);
  };

  return (
    <Group>
      <Paragraph strong text={texts[language].imageUpload.label} />
      <MeetupEditImage />

      <Paragraph strong text={texts[language].sustainableDevelopmentGoals.label} mt="xl" />
      <Checkbox
        text="Sæt et flueben, hvis arrangementet understøtter et af FN's 17 Verdensmål for bæredygtig udvikling"
        checked={showSustainableGoals}
        onChange={onCheckboxClick}
        cypress="SustainableDevelopmentGoalsCheckbox"
      />
      {showSustainableGoals && (
        <>
          <HtmlText mt="xl" html={texts[language].sustainableDevelopmentGoals.helpText} />
          <Paragraph
            variant="error"
            text={getErrorMessage(
              fieldValidationState,
              STEP_NUMBER,
              FieldSustainableDevelopmentGoals.SustainableDevelopmentGoals,
            )}
          />
          <MeetupEditSustainableDevelopmentGoals
            initialGoals={sustainableDevelopmentGoals?.map((g) => +g as number)}
            onSelect={(data: number[]) => onSelectChange('SustainableDevelopmentGoals', data)}
          />
        </>
      )}
    </Group>
  );
}
