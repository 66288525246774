import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { da } from 'date-fns/locale';
import { DatePickerField, Paragraph, Alert, Group, Grid } from '@ingeniorforeningen/figurine-core';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import * as meetupActions from '../../store/meetups/actions';
import * as uiActions from '../../store/ui/actions';
import { TMeetup } from '../../store/meetups/types';
import getErrorMessage from '../../helpers/errorMessage';
import { FieldsDates } from '../../helpers/fields';
import { TimePicker } from '../Common/TimePicker/TimePicker';

const STEP_NUMBER = 2;

export default function MeetupEditDates() {
  const dispatch = useDispatch();
  const language = useSelector((state: TState) => state.ui.language);
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const fieldValidationState = useSelector((state: TState) => state.ui.fieldValidation);
  // Store selected times from dropdowns.
  const [time, setTime] = useState<{ [key: string]: string }>({
    [FieldsDates.StartTime]: '17:00',
    [FieldsDates.EndTime]: '19:00',
    [FieldsDates.PublicationTime]: '12:00',
    [FieldsDates.RegistrationDeadline]: '23:55',
    [FieldsDates.CancellationDeadline]: '23:55',
  });
  const disableEdit = meetup.MeetupStatus === 1 || meetup.MeetupStatus === 2; //Already held or cancelled

  const getMeetupTime = (date: Date | null | undefined, defaultTime: string): string => {
    let parsedDate = date;
    // Just for safety if the date is a string with a date.
    if (typeof date === 'string') {
      try {
        parsedDate = parseISO(date);
      } catch (error) {
        return defaultTime;
      }
    }

    if (!parsedDate) {
      return defaultTime;
    }

    // If time does not conform to 5 minutes intervals default to 12:00.
    if (parsedDate.getMinutes() % 5 !== 0) {
      return '12:00';
    }

    return format(parsedDate, 'HH:mm', { locale: da });
  };

  const validateStep = (payload: Partial<TMeetup>) => {
    const fields = [
      FieldsDates.StartTime,
      FieldsDates.EndTime,
      FieldsDates.PublicationTime,
      FieldsDates.RegistrationDeadline,
      FieldsDates.CancellationDeadline,
    ];

    dispatch(
      uiActions.updateFieldValidation({
        step: STEP_NUMBER,
        fields,
        meetup: payload,
        showMessage: true,
      }),
    );
  };

  useEffect(() => {
    if (meetup) {
      setTime({
        ...time,
        StartTime: getMeetupTime(meetup.StartTime, '17:00'),
        EndTime: getMeetupTime(meetup.EndTime, '19:00'),
        CancellationDeadline: getMeetupTime(meetup.CancellationDeadline, '23:55'),
        RegistrationDeadline: getMeetupTime(meetup.RegistrationDeadline, '23:55'),
        PublicationTime: getMeetupTime(meetup.PublicationTime, '12:00'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetup]);

  const handleDateChange = (dateFieldName: string, dateValue: Date, timeValue: string) => {
    const date = new Date(dateValue);

    // Join selected date and time in a single date value.
    if (dateValue) {
      const [hours, minutes] = timeValue.split(':');
      date.setHours(Number(hours), Number(minutes), 0, 0);
    }

    const payload = {
      ...meetup,
      [dateFieldName as keyof TMeetup]: date,
    };

    // Default required dateTime values when setting startDate to prevent instant validation errors
    if (dateValue && dateFieldName === FieldsDates.StartTime && !meetup.EndTime) {
      // Set endDate to 2 hours later than default startTime (17:00) on the same date as startDate
      const endDate = new Date(date);
      endDate.setHours(19, 0);
      payload[FieldsDates.EndTime] = endDate;

      // Set Registration Deadline and Cancellation deadline to 1 day before start time
      const theDayBefore = new Date(date);
      theDayBefore.setDate(date.getDate() - 1);
      theDayBefore.setHours(23, 55);
      payload[FieldsDates.RegistrationDeadline] = theDayBefore;
      payload[FieldsDates.CancellationDeadline] = theDayBefore;
    }

    dispatch(meetupActions.setMeetup(payload));
    validateStep(payload);
  };

  // Handle date dropdown changes.
  const onDateInputChange = (fieldName: string, value: Date | null) => {
    const timeValue = time[fieldName];
    if (!value) {
      return;
    }
    handleDateChange(fieldName, value, timeValue);
  };

  // Handle time dropdown changes.
  const onTimeInputChange = (fieldName: string, value: string) => {
    // Persist to local state just for data binding.
    setTime({ ...time, [fieldName]: value });
    const dateValue = meetup[fieldName as keyof TMeetup] as Date;
    // If the date is also selected (and not only the time), treat it as if the entire date changed
    // causing redux state and validation to be updated.
    if (dateValue) {
      handleDateChange(fieldName, dateValue, value);
    }
  };

  return (
    <>
      {disableEdit && (
        <Group direction="column">
          <Alert text={texts[language].dateEditDisabledText} type="warning" />
          <Group grow justify="space-between">
            <Paragraph text={texts[language].startTime.label} />
            <Paragraph
              text={
                meetup.StartTime &&
                `${format(new Date(meetup.StartTime), 'd/M/yyyy HH:mm', {
                  locale: da,
                })}`
              }
            />
          </Group>
          <Group grow justify="space-between">
            <Paragraph text={texts[language].endTime.label} />
            <Paragraph
              text={
                meetup.EndTime &&
                `${format(new Date(meetup.EndTime), 'd/M/yyyy HH:mm', {
                  locale: da,
                })}`
              }
            />
          </Group>
          <Group grow justify="space-between">
            <Paragraph text={texts[language].registrationDeadline.label} />
            <Paragraph
              text={
                meetup.RegistrationDeadline &&
                `${format(new Date(meetup.RegistrationDeadline), 'd/M/yyyy HH:mm', {
                  locale: da,
                })}`
              }
            />
          </Group>
          <Group grow justify="space-between">
            <Paragraph text={texts[language].cancellationDeadline.label} />
            <Paragraph
              text={
                meetup.CancellationDeadline &&
                `${format(new Date(meetup.CancellationDeadline), 'd/M/yyyy HH:mm', {
                  locale: da,
                })}`
              }
            />
          </Group>

          <Group grow justify="space-between">
            <Paragraph text={texts[language].publicationTime.label} />
            <Paragraph
              text={
                meetup.PublicationTime &&
                `${format(new Date(meetup.PublicationTime), 'd/M/yyyy HH:mm', {
                  locale: da,
                })}`
              }
            />
          </Group>
        </Group>
      )}
      {!disableEdit && (
        <Group direction="column" spacing="xl">
          <Grid container columns={8}>
            <Grid item xs={8} sm={3} alignItems="center">
              <Paragraph strong text="Starttidspunkt:" style={{ marginRight: 'auto' }} />
            </Grid>
            <Grid item xs={4} sm={3}>
              <DatePickerField
                disableBeforeToday
                name={FieldsDates.StartTime}
                placeholder={texts[language].startTime.placeholder}
                label="Dato"
                value={meetup.StartTime && new Date(meetup.StartTime)}
                onChange={(value) => onDateInputChange(FieldsDates.StartTime, value)}
                error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsDates.StartTime)}
                required
                data-cypress="startTime"
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TimePicker
                name={FieldsDates.StartTime}
                value={time[FieldsDates.StartTime]}
                onChange={(value) => onTimeInputChange(FieldsDates.StartTime, value)}
                error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsDates.StartTime)}
              />
            </Grid>
          </Grid>

          <Grid container columns={8}>
            <Grid item xs={8} sm={3} alignItems="center">
              <Paragraph strong text="Sluttidspunkt:" style={{ marginRight: 'auto' }} />{' '}
            </Grid>
            <Grid item xs={4} sm={3}>
              <DatePickerField
                disableBeforeToday
                name={FieldsDates.EndTime}
                placeholder={texts[language].endTime.placeholder}
                label="Dato"
                value={meetup.EndTime && new Date(meetup.EndTime)}
                onChange={(value) => onDateInputChange(FieldsDates.EndTime, value)}
                error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsDates.EndTime)}
                required
                data-cypress="endTime"
              />{' '}
            </Grid>
            <Grid item xs={4} sm={2}>
              <TimePicker
                name={FieldsDates.EndTime}
                value={time[FieldsDates.EndTime]}
                onChange={(value) => onTimeInputChange(FieldsDates.EndTime, value)}
                error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsDates.EndTime)}
              />
            </Grid>
          </Grid>
          <Grid container columns={8}>
            <Grid item xs={8} sm={3} alignItems="center">
              <Paragraph strong text="Tilmeldingsfrist:" style={{ marginRight: 'auto' }} />
            </Grid>
            <Grid item xs={4} sm={3}>
              <DatePickerField
                disableBeforeToday
                name={FieldsDates.RegistrationDeadline}
                placeholder={texts[language].registrationDeadline.placeholder}
                label="Dato"
                value={meetup.RegistrationDeadline && new Date(meetup.RegistrationDeadline)}
                onChange={(value) => onDateInputChange(FieldsDates.RegistrationDeadline, value)}
                error={getErrorMessage(
                  fieldValidationState,
                  STEP_NUMBER,
                  FieldsDates.RegistrationDeadline,
                )}
                required
                data-cypress="registrationDeadline"
              />{' '}
            </Grid>
            <Grid item xs={4} sm={2}>
              <TimePicker
                name={FieldsDates.RegistrationDeadline}
                value={time[FieldsDates.RegistrationDeadline]}
                onChange={(value) => onTimeInputChange(FieldsDates.RegistrationDeadline, value)}
                error={getErrorMessage(
                  fieldValidationState,
                  STEP_NUMBER,
                  FieldsDates.RegistrationDeadline,
                )}
              />
            </Grid>
          </Grid>

          <Grid container columns={8}>
            <Grid item xs={8} sm={3} alignItems="center">
              <Paragraph strong text="Afmeldingstidspunkt:" style={{ marginRight: 'auto' }} />{' '}
            </Grid>
            <Grid item xs={4} sm={3}>
              <DatePickerField
                disableBeforeToday
                name={FieldsDates.CancellationDeadline}
                placeholder={texts[language].cancellationDeadline.placeholder}
                label="Dato"
                value={meetup.CancellationDeadline && new Date(meetup.CancellationDeadline)}
                onChange={(value) => onDateInputChange(FieldsDates.CancellationDeadline, value)}
                error={getErrorMessage(
                  fieldValidationState,
                  STEP_NUMBER,
                  FieldsDates.CancellationDeadline,
                )}
                required
                data-cypress="cancellationDeadline"
              />{' '}
            </Grid>
            <Grid item xs={4} sm={2}>
              <TimePicker
                name={FieldsDates.CancellationDeadline}
                value={time[FieldsDates.CancellationDeadline]}
                onChange={(value) => onTimeInputChange(FieldsDates.CancellationDeadline, value)}
                error={getErrorMessage(
                  fieldValidationState,
                  STEP_NUMBER,
                  FieldsDates.CancellationDeadline,
                )}
              />
            </Grid>
          </Grid>
          <Grid container columns={8}>
            <Grid item xs={8} sm={3} alignItems="center">
              <Paragraph strong text="Publiseringstidspunkt:" style={{ marginRight: 'auto' }} />{' '}
            </Grid>
            <Grid item xs={4} sm={3}>
              <DatePickerField
                disableBeforeToday
                name={FieldsDates.PublicationTime}
                placeholder={texts[language].publicationTime.placeholder}
                label="Dato"
                value={meetup.PublicationTime && new Date(meetup.PublicationTime)}
                onChange={(value) => onDateInputChange(FieldsDates.PublicationTime, value)}
                error={getErrorMessage(
                  fieldValidationState,
                  STEP_NUMBER,
                  FieldsDates.PublicationTime,
                )}
                data-cypress="publicationTime"
              />{' '}
            </Grid>
            <Grid item xs={4} sm={2}>
              <TimePicker
                name={FieldsDates.PublicationTime}
                value={time[FieldsDates.PublicationTime]}
                onChange={(value) => onTimeInputChange(FieldsDates.PublicationTime, value)}
                error={getErrorMessage(
                  fieldValidationState,
                  STEP_NUMBER,
                  FieldsDates.PublicationTime,
                )}
              />
            </Grid>
          </Grid>
        </Group>
      )}
    </>
  );
}
