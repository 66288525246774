import { AuthConsumer } from './AuthProvider';

export default function LogoutCallback() {
  return (
    <AuthConsumer>
      {({ signoutRedirectCallback }) => {
        signoutRedirectCallback();
        return <></>;
      }}
    </AuthConsumer>
  );
}
