import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MenuButton } from '@ingeniorforeningen/figurine-core';
import { TMeetup } from '../../store/meetups/types';
import MeetupOrganizerModal from './MeetupMenuOrganizerModal';
import MeetupUnpublishModal from './MeetupMenuUnpublishModal';
import MeetupCancelModal from './MeetupMenuCancelModal';
import * as meetupActions from '../../store/meetups/actions';

type Props = {
  meetup: Partial<TMeetup>;
};

export default function MeetupMenu({ meetup }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModalOrganizer, setShowModalOrganizer] = useState(false);
  const [showModalUnpublish, setShowModalUnpublish] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const toggleModalOrganizer = () => {
    setShowModalOrganizer(!showModalOrganizer);
  };
  const toggleModalUnpublish = () => {
    setShowModalUnpublish(!showModalUnpublish);
  };
  const toggleModalCancel = () => {
    setShowModalCancel(!showModalCancel);
  };
  const toggleWindowParticipants = (meetupNumber: number | undefined) => {
    if (meetupNumber) {
      window.open(`/arrangementer/${meetupNumber}/deltagerliste`, '_blank');
    }
  };
  const previewMeetup = (meetupNumber: number | undefined) => {
    if (meetupNumber) {
      dispatch(meetupActions.previewMeetup(meetupNumber));
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()} role="button" tabIndex={0}>
      <MeetupOrganizerModal
        meetup={meetup}
        show={showModalOrganizer}
        onClose={() => toggleModalOrganizer()}
      />

      <MeetupUnpublishModal
        meetup={meetup}
        show={showModalUnpublish}
        onClose={() => toggleModalUnpublish()}
      />

      <MeetupCancelModal
        meetup={meetup}
        show={showModalCancel}
        onClose={() => toggleModalCancel()}
      />

      <MenuButton
        items={[
          { text: 'Aflys arrangement', onClick: () => toggleModalCancel() },
          { text: 'Afpublicer arrangement', onClick: () => toggleModalUnpublish() },
          { text: 'Tilmeld som arrangør', onClick: () => toggleModalOrganizer() },
          {
            text: 'Kopiér arrangement',
            onClick: () => {
              // Create meetup copy.
              dispatch(meetupActions.getMeetup(Number(meetup.MeetupNumber), true));
              navigate(`/arrangementer/opret`, { state: { copy: true } });
            },
          },
          {
            text: 'Hent QR-koder',
            onClick: () => navigate(`/arrangementer/${meetup.MeetupNumber}/qr`),
          },
          { text: 'Vis preview', onClick: () => previewMeetup(meetup.MeetupNumber) },
          {
            text: 'Foredragsholdere',
            onClick: () => navigate(`/arrangementer/${meetup.MeetupNumber}/foredragsholdere`),
          },
          { text: 'Deltagerliste', onClick: () => toggleWindowParticipants(meetup.MeetupNumber) },
        ]}
        icon="Dots"
        circular
        variant="light"
      />
    </div>
  );
}
