import { Action } from 'redux';

export const SET_MEETUP = 'setMeetup';
export const SET_IMAGE = 'setImage';
export const SET_MEETUPS = 'setMeetups';
export const SET_LAST_MEETUP_CREATED = 'setLastMeetupCreated';
export const SET_COMMITTEES = 'setCommittees';
export const SET_MEETUP_TYPES = 'setMeetupTypes';
export const SET_SUSTAINABLE_DEVELOPMENT_GOALS = 'setSustainableDevelopmentGoals';
export const SET_LOCATIONS = 'setLocations';
export const SET_REGIONS = 'setRegions';
export const SET_PARTICIPANT_TYPES = 'setParticipantTypes';

export type TMeetupState = {
  meetup: Partial<TMeetup>;
  lastMeetupCreated: Partial<TMeetup>;
  image: File | null;
  meetups: Partial<TMeetup>[];
  committees: TKeyValue[];
  meetupTypes: TKeyValue[];
  locations: TKeyValue[];
  regions: TKeyValue[];
  sustainableDevelopmentGoals: TKeyValue[];
  participantTypes: TParticipantType[];
};

export type TMeetup = {
  Id: string;
  MeetupNumber: number;
  Title: string;
  Teaser: string;
  Description: string;
  IsRelevantForInternationals: boolean;
  Type: string;
  StartTime: Date | null;
  EndTime: Date | null;
  PublicationTime: Date | null;
  RegistrationDeadline: Date | null;
  CancellationDeadline: Date | null;
  CreatedBy: string;
  MainOrganizer: string;
  MainOrganizerId: string;
  CoOrganizerIds: string[];
  MeetupTypeId: string;
  MeetupType: TMeetupType;
  LocationId: string;
  LocationAdditionalInfo: string;
  Region: string;
  ParticipantPrices: Partial<TParticipantPrice>[];
  Options: TMeetupOption[];
  SeatsQuantity: number | null;
  SeatsRequired: number | null;
  SeatsAvailable: number | null;
  SeatsOccupied: number | null;
  OnWaitingList: number;
  Participants: number;
  MeetupImageUrl: string;
  RemoveMeetupImage: boolean;
  MeetupStatus: number;
  ParticipantQuestion: string;
  SustainableDevelopmentGoals: number[];
  Budget: Partial<TBudget>;
  CopyImageFromMeetup: number;
  // For UI internal use only.
  IsDraft?: boolean;
  // For UI internal use only.
  IsCopy?: boolean;
};

export type TBudget = {
  ParticipantPayment: number | null;
  OtherIncome: number | null;
  TravelExpenses: number | null;
  MeetingExpenses: number | null;
  Speaker: number | null;
  Catering: number | null;
  TicketExpenses: number | null;
  OtherExpenses: number | null;
  TotalIncome: number | null;
  TotalExpenses: number | null;
  TotalBudget: number | null;
};

export type TRegistration = {
  CreatedOn: Date;
  CompanyName: string;
  Contact: TRegistrationContact;
  Options: TRegistrationOption[];
  PaymentType: string;
  PaymentStatus: string;
  Registered: boolean;
  RegistrationStatus: string;
  AnswersForQuestion: string;
  NumberOfParticipants: number;
  UnregisteredAt?: Date | null;
};

export type TRegistrationContact = {
  CompanyName: string;
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Zip: string;
  City: string;
  Email: string;
  Mobile: string;
  Telephone: string;
};

export type TZip = {
  Name: string;
};

export type TCity = {
  Name: string;
};

export type TRegistrationOption = {
  Id: string;
  Name: string;
  Price: number;
  Quantity: number;
  Type: string;
};

export type TParticipantPrice = {
  ParticipantTypeId: any;
  Name: string;
  Price: number | null;
  MaxQuantity: number | null;
  ExpectedQuantity: number | null;
  Active: boolean;
};

export type TParticipantType = {
  Id: any;
  Name: string;
};

export type TCommittee = {
  Id: any;
  Name: string;
};

export type TMeetupType = {
  Id: any;
  Name: string;
};

export type TRegion = {
  Id: number;
  Name: string;
};

export type TLocation = {
  Id: any;
  Name: string;
  Address1: string;
  City: string;
};

export type TLocationNew = {
  Id?: string;
  Name: string;
  Address1: string;
  Zip: number | null;
  City: string;
};

export enum MeetupOptionType {
  Person = 0,
  Benefit = 1,
}

export enum RegistrationStatus {
  Registered = 0,
  OnWaitingList = 1,
  NotAvailableForParticipantType = 2,
  OptionSoldOut = 3,
  ParticipantTypeSoldOut = 4,
  AlreadyRegistered = 5,
  RegistrationDeadlineExceeded = 6,
  MeetupStartTimeExceeded = 7,
  MeetupCancelled = 8,
  MeetupNotFound = 9,
  Unsubscribed = 10,
  Cancelled = 11,
  NotMet = 12,
}

export type TMeetupOption = {
  Id: string;
  Type: MeetupOptionType;
  Name: string;
  Price: number;
  MaxQuantity: number | null;
  MaxForSignup: number | null;
  Used: boolean;
};

export type TMeetupSpeaker = {
  IdaNumber: string;
  Name: string;
  Title: string;
  Email: string;
  MobilePhone: string;
};

export type TMeetupSpeakerResponse = {
  ParticipantId: string;
  Contact: {
    IdaNumber: string;
    FirstName: string;
    LastName: string;
    Title: string;
    Mobile: string;
    Email: string;
  };
};

export type RegisterSpeakerResponse = {
  Status: RegistrationStatus;
  Speaker: TMeetupSpeaker;
};

export type TKeyValue = {
  label: string;
  value: string | number;
};

export interface SetMeetupAction extends Action {
  type: typeof SET_MEETUP;
  payload: Partial<TMeetup>;
}

export interface SetLastMeetupCreatedAction extends Action {
  type: typeof SET_LAST_MEETUP_CREATED;
  payload: Partial<TMeetup>;
}

export interface SetImageAction extends Action {
  type: typeof SET_IMAGE;
  payload: File | null;
}

export interface SetSustainableDevelopmentGoals extends Action {
  type: typeof SET_SUSTAINABLE_DEVELOPMENT_GOALS;
  payload: number[];
}

export interface SetMeetupsAction extends Action {
  type: typeof SET_MEETUPS;
  payload: { meetups: Partial<TMeetup>[]; concat: boolean };
}

export interface SetCommitteesAction extends Action {
  type: typeof SET_COMMITTEES;
  payload: TKeyValue[];
}

export interface SetMeetupTypesAction extends Action {
  type: typeof SET_MEETUP_TYPES;
  payload: TKeyValue[];
}

export interface SetLocationsAction extends Action {
  type: typeof SET_LOCATIONS;
  payload: TKeyValue[];
}

export interface SetRegionsAction extends Action {
  type: typeof SET_REGIONS;
  payload: TKeyValue[];
}

export interface SetParticipantTypesAction extends Action {
  type: typeof SET_PARTICIPANT_TYPES;
  payload: TParticipantType[];
}

export type MeetupAction =
  | SetMeetupAction
  | SetLastMeetupCreatedAction
  | SetImageAction
  | SetMeetupsAction
  | SetCommitteesAction
  | SetMeetupTypesAction
  | SetLocationsAction
  | SetRegionsAction
  | SetParticipantTypesAction
  | SetSustainableDevelopmentGoals;
