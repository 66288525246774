import { SelectField, createStyles } from '@ingeniorforeningen/figurine-core';
import { FocusEventHandler } from 'react';

const styles = createStyles((theme, { error }: { error?: boolean }) => ({
  select: {
    minWidth: 'unset',
    width: '4.75rem',
    '& .mantine-Input-input ': {
      textAlign: 'center',
      padding: '0rem!important',
      color: error ? theme.other.colorTokens.feedback.error : 'unset',
    },
    '& .mantine-Select-rightSection': {
      display: 'none',
    },
  },
}));

type Props = {
  name: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
  error?: boolean;
};

export const Select = ({ name, value, onChange, error, options }: Props) => {
  const { classes } = styles({ error });

  const handelOnChange = (value: string) => {
    onChange(value);
  };

  const handelOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <SelectField
      className={classes.select}
      name={name}
      options={options}
      placeholder="--"
      label=""
      value={value}
      required
      onChange={(value) => handelOnChange(value)}
      searchable
      variant="filled"
      onBlur={handelOnBlur}
    />
  );
};
