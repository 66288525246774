import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import * as uiActions from '../store/ui/actions';
import store from '../store/store';

export default function createRequestInterceptors() {
  const onRequest = (request: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    store.dispatch(uiActions.setIsLoading(true));
    store.dispatch(uiActions.setError({errorMessage: '', errorType: ''}));

    const token = localStorage.getItem('access_token');

    if (token && request.headers) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return Promise.resolve(request);
  };

  const onResponse = (response: AxiosResponse) => {
    store.dispatch(uiActions.setIsLoading(false));
    return Promise.resolve(response);
  };

  const onResponseError = (error: any) => {
    store.dispatch(uiActions.setIsLoading(false));
    if (error.response?.status === 403) {
      window.location.href = '/Forbidden';
    }
    return Promise.reject(error);
  };

  axios.interceptors.response.use(onResponse, onResponseError);
  axios.interceptors.request.use(onRequest);
}
