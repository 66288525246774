import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import { FieldsLocationNew } from '../../helpers/fields';
import { TLocationNew } from '../../store/meetups/types';
import { Button, Group, Heading, Modal, TextField } from '@ingeniorforeningen/figurine-core';

const defaultLocation = {
  [FieldsLocationNew.Name]: '',
  [FieldsLocationNew.Address1]: '',
  [FieldsLocationNew.Zip]: null,
  [FieldsLocationNew.City]: '',
};

const defaultValidation = {
  Name: { valid: false, errorMessage: '' },
  Address1: { valid: false, errorMessage: '' },
  Zip: { valid: false, errorMessage: '' },
  City: { valid: false, errorMessage: '' },
};

type Props = {
  show: boolean;
  locationName?: string;
  onClose: () => void;
  onCreate: (newLocation: TLocationNew) => void;
};

export default function MeetupEditLocationNew({ show, locationName, onClose, onCreate }: Props) {
  const language = useSelector((state: TState) => state.ui.language);
  const [location, setLocation] = useState<TLocationNew>(defaultLocation);
  const [validations, setValidations] = useState<{
    [key: string]: { valid: boolean; errorMessage: string };
  }>(defaultValidation);

  const isValid = (): boolean => {
    let valid = true;

    Object.keys(validations).forEach((key: string) => {
      if (!validations[key].valid) {
        valid = false;
      }
    });

    return valid;
  };

  const validateField = async (fieldName: string, updatedLocation: any) => {
    const response = await axios.post(`${runtimeConfig.apiUrl}meetuplocations/validate`, {
      RequestModel: updatedLocation,
      FieldsToValidate: [fieldName],
    });

    setValidations({
      ...validations,
      [fieldName]: {
        valid: response.data.length === 0,
        errorMessage: response.data[0] ? response.data[0].ErrorMessage : '',
      },
    });
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setLocation({ ...location, [e.currentTarget.name]: e.currentTarget.value as string });
  };

  const onInputBlur = (
    e:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    validateField(e.currentTarget.name, location);
  };

  useEffect(() => {
    setLocation({ ...defaultLocation, [FieldsLocationNew.Name]: locationName || '' });
    setValidations(defaultValidation);
  }, [show]);

  return (
    <>
      {show && (
        <Modal opened={show} onClose={onClose}>
          <Group direction="column">
            <Heading
              type="h3"
              text={texts[language].newLocationModalTitle}
              align="center"
              mb="lg"
            />
            <TextField
              name={FieldsLocationNew.Name}
              label={texts[language].newLocationName.label}
              placeholder={texts[language].newLocationName.placeholder}
              description={texts[language].newLocationName.helpText}
              error={validations[FieldsLocationNew.Name]?.errorMessage}
              value={location.Name}
              onChange={onInputChange}
              onBlur={onInputBlur}
              required
              variant="outline"
              autoFocus={Boolean(locationName)}
            />
            <TextField
              name={FieldsLocationNew.Address1}
              label={texts[language].newLocationAddress1.label}
              error={validations[FieldsLocationNew.Address1]?.errorMessage}
              value={location.Address1}
              onChange={onInputChange}
              onBlur={onInputBlur}
              placeholder=""
              required
              variant="outline"
            />
            <TextField
              type="number"
              placeholder=""
              name={FieldsLocationNew.Zip}
              label={texts[language].newLocationZip.label}
              error={validations[FieldsLocationNew.Zip]?.errorMessage}
              value={location.Zip?.toString()}
              onChange={onInputChange}
              onBlur={onInputBlur}
              min={0}
              required
              variant="outline"
            />
            <TextField
              name={FieldsLocationNew.City}
              label={texts[language].newLocationCity.label}
              error={validations[FieldsLocationNew.City]?.errorMessage}
              value={location.City}
              placeholder=""
              onChange={(e) => {
                onInputChange(e);
                validateField(e.currentTarget.name, {
                  ...location,
                  [FieldsLocationNew.City]: e.currentTarget.value,
                });
              }}
              onBlur={onInputBlur}
              required
              variant="outline"
            />
            <Group justify="flex-end">
              <Button
                onClick={onClose}
                text={texts[language].newLocationButtonClose}
                variant="outline"
              />
              <Button
                onClick={() => onCreate(location)}
                text={texts[language].newLocationButtonAction}
                disabled={!isValid()}
              />
            </Group>
          </Group>
        </Modal>
      )}
    </>
  );
}
