import { Container, ErrorCodeDisplay, Group, Paragraph } from '@ingeniorforeningen/figurine-core';

export default function Forbidden() {
  return (
    <Container>
      <Group direction="column">
        <ErrorCodeDisplay code="403" />
        <Paragraph
          text="Du forsøger at tilgå en side som kun er for IDAs aktive, og den bruger som du er logget
          ind med nu, står ikke registreret som aktiv i vores system."
        />
        <Paragraph
          text="Hvis du mener at det er en fejl, bedes du kontakte din netværkskoordinator, så vi kan få
          dig registreret som aktiv."
        />
      </Group>
    </Container>
  );
}
