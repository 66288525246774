import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Group,
  Heading,
  HtmlText,
  Link,
  Loader,
  Paragraph,
} from '@ingeniorforeningen/figurine-core';

import ProgressIndicatorWrapper from '../Common/ProgressIndicatorWrapper';
import NavigationButtons from '../Common/NavigationButtons';
import { TState } from '../../store/rootReducer';
import MeetupEditDescription from './MeetupEditDescription';
import MeetupEditDates from './MeetupEditDates';
import MeetupEditLocation from './MeetupEditLocation';
import * as meetupActions from '../../store/meetups/actions';
import * as uiActions from '../../store/ui/actions';
import MeetupEditParticipantPrices from './MeetupEditParticipantPrices';
import MeetupEditParticipants from './MeetupEditParticipants';
import MeetupEditOrganizers from './MeetupEditOrganizers';
import useStepValidation from '../../hooks/useStepValidation';
import MeetupEditOptions from './MeetupEditOptions';
import useDraft from '../../hooks/useDraft';
import usePrevious from '../../hooks/usePrevious';
import MeetupEditExtras from './MeetupEditExtras';
import MeetupOrganizerModal from './MeetupMenuOrganizerModal';
import { TStepNumber } from '../../store/ui/types';

export default function MeetupEdit() {
  const dispatch = useDispatch();

  const { meetupNumber } = useParams<{ meetupNumber: string }>();
  const currentStep = useSelector((state: TState) => state.ui.currentStep);
  const meetupSaved = useSelector((state: TState) => state.ui.meetupSaved);
  const { errorMessage } = useSelector((state: TState) => state.ui.error);
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const prevMeetup = usePrevious(meetup);
  const image = useSelector((state: TState) => state.meetups.image);
  const fieldValidation = useSelector((state: TState) => state.ui.fieldValidation);
  const [allStepsValid, setAllStepsValid] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const { validateAllSteps, isStepValid, isAllStepsValid, validateStep } = useStepValidation();
  const { saveDraft, removeDraft } = useDraft();
  const navigate = useNavigate();

  const [showModalOrganizer, setShowModalOrganizer] = useState(false);
  const lastMeetupCreated = useSelector((state: TState) => state.meetups.lastMeetupCreated);
  const toggleModalOrganizer = () => {
    setShowModalOrganizer(!showModalOrganizer);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentStep, isDone]);

  // Get existing meetup by meetup number in url (if provided).
  useEffect(() => {
    if (meetupNumber) {
      dispatch(meetupActions.getMeetup(Number(meetupNumber)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupNumber]);

  useEffect(() => {
    // Only validate first time meetup changes from {} to {...}.
    if ((!prevMeetup || Object.keys(prevMeetup).length === 0) && Object.keys(meetup).length > 0) {
      // Validate all steps for existing meetups and resumed drafts, but not
      // when creating new meetups.
      if (meetup.MeetupNumber || meetup.IsDraft || meetup.IsCopy) {
        validateAllSteps(meetup);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetup]);

  // Update local component state values when validation or step change.
  useEffect(() => {
    dispatch(
      uiActions.setCurrentStepValid(
        isStepValid(
          Object.keys(fieldValidation[currentStep]).map(
            (field) => fieldValidation[currentStep][field],
          ),
        ),
      ),
    );
    setAllStepsValid(isAllStepsValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValidation, currentStep]);

  // Discard any draft saved during creating the meetup.
  useEffect(() => {
    if (meetupSaved) {
      removeDraft();
    }
    if (meetupSaved && meetup.MeetupNumber) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupSaved]);

  const saveMeetup = async () => {
    setIsDone(true);
    dispatch(uiActions.setMeetupSaved(false));
    // upsertMeetup will set meetupSaved to true when it's done.
    dispatch(meetupActions.upsertMeetup(meetup, image));
  };

  const onStepChanged = (step: number, previousStep: number) => {
    // Save to local storage (only when creating new meetup).
    if (!meetup.MeetupNumber) {
      saveDraft();
    }
    validateStep(previousStep as TStepNumber, meetup);

    const previousStepFieldValidations = fieldValidation[previousStep];

    // Going back from an invalid step, should keep showing the step as invalid in the
    // progress indicator.
    Object.keys(previousStepFieldValidations).forEach((field: string) => {
      if (
        !previousStepFieldValidations[field].excludeInValidation &&
        !previousStepFieldValidations[field].valid
      ) {
        dispatch(uiActions.setStep({ key: previousStep, state: 'invalid' }));
      }
    });
  };

  return (
    <Container my="xl" p={0}>
      {!isDone && (
        <>
          <ProgressIndicatorWrapper onStepChanged={onStepChanged} />
          <Container
            mt={{ base: 0, md: 'xl' }}
            pt="xl"
            style={{
              maxWidth: '50rem',
            }}
          >
            {currentStep === 1 && <MeetupEditDescription />}
            {currentStep === 2 && <MeetupEditDates />}
            {currentStep === 3 && <MeetupEditLocation />}
            {currentStep === 4 && <MeetupEditOrganizers />}
            {currentStep === 5 && <MeetupEditParticipants />}
            {currentStep === 6 && <MeetupEditParticipantPrices />}
            {currentStep === 7 && <MeetupEditOptions />}
            {currentStep === 8 && <MeetupEditExtras />}

            <NavigationButtons
              onStepChanged={onStepChanged}
              onEnd={saveMeetup}
              allStepsValid={allStepsValid}
              isLoading={false}
            />
          </Container>
        </>
      )}

      {isDone && (
        <>
          {!meetupSaved && !errorMessage && (
            <Group direction="column" justify="center" align="center">
              <Heading text={`${meetup.MeetupNumber ? 'Gemmer' : 'Opretter'} arrangement...`} />

              <Loader size="md" />
            </Group>
          )}
          {errorMessage && (
            <Group direction="column" justify="center" align="center">
              <Heading text="Der skete en fejl" />
              <Paragraph text={errorMessage} />
              <Button id="meetupError" component="a" href="/" text="Gå tilbage" />
            </Group>
          )}
          {meetupSaved && (
            <>
              <MeetupOrganizerModal
                meetup={lastMeetupCreated}
                show={showModalOrganizer}
                onClose={toggleModalOrganizer}
              />
              <Group direction="column">
                <Heading text="Hurra! &#128079;" />
                <Paragraph text="Dit arrangement er gemt, og vi er i gang med at få de sidste detaljer på plads." />
                <HtmlText
                  html={`Hvis dit arrangement er publiceret, vil det dukke op på <a href="https://ida.dk/arrangementer" text="Magnus">ida.dk's arrangementsside</a> i løbet af 5 minutter.`}
                />

                <Paragraph text="Tak fordi du laver arrangementer sammen med os!" />
                <Group direction="column" justify="center" mt="xl">
                  <Button
                    text="Tilmeld mig som arrangør"
                    onClick={toggleModalOrganizer}
                    variant="subtle"
                    cypress="MeetupEditOrganizerModalButton"
                  />

                  <Button
                    text="Tilmeld foredragsholder"
                    onClick={() =>
                      navigate(
                        `/arrangementer/${
                          lastMeetupCreated.MeetupNumber || meetupNumber
                        }/foredragsholdere`,
                      )
                    }
                    variant="subtle"
                    cypress="MeetupEditSpeakerButton"
                  />

                  <Button
                    id="meetupEnd"
                    component="a"
                    href="/"
                    text="Tilbage til arrangementer"
                    cypress="MeetupEditBackToMeetupsButton"
                  />
                  <Group>
                    <Paragraph text="Hvis du er tilmeldt som arrangør, vil du kunne redigere dine tilvalg gratis. Du kan finde dine tilmeldinger på " />
                    <Link
                      href={runtimeConfig.meetupRegistrationUrl}
                      text="ida.dk's tilmeldings overblik"
                      target="_blank"
                    />
                  </Group>
                </Group>
              </Group>
            </>
          )}
        </>
      )}
    </Container>
  );
}
