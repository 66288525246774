import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Paragraph, Loader, Button, Group } from '@ingeniorforeningen/figurine-core';
import { TMeetup } from '../../store/meetups/types';
import { TState } from '../../store/rootReducer';
import texts from '../../texts/texts';
import * as meetupActions from '../../store/meetups/actions';

type Props = {
  meetup: Partial<TMeetup>;
  show: boolean;
  onClose: () => void;
};

export default function MeetupUnpublishModal({ meetup, show, onClose }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const filterCreatedBy = useSelector((state: TState) => state.ui.filterCreatedBy);
  const [showSecondButton, setShowSecondButton] = useState(false);
  const [bodyText, setBodyText] = useState('');

  const language = useSelector((state: TState) => state.ui.language);

  const unpublishMeetup = async () => {
    setBodyText('');

    setIsLoading(true);

    let success = false;

    try {
      const response = await axios.delete(
        `${runtimeConfig.apiUrl}meetups/published/${meetup.MeetupNumber}`,
        { validateStatus: (status: any) => status < 1000 },
      );
      success = response.status === 200;

      if (success) {
        dispatch(meetupActions.getMeetups(filterCreatedBy));
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    setBodyText(
      success ? texts[language].unpublishModalSuccess : texts[language].unpublishModalFailure,
    );
    setShowSecondButton(!success);
  };

  useEffect(() => {
    setBodyText('');

    setIsLoading(true);

    if (show) {
      let meetupCanGetUnpublished = false;

      const getMeetupValid = async () => {
        try {
          const response = await axios.get<TMeetup>(
            `${runtimeConfig.apiUrl}meetups/${meetup.MeetupNumber}`,
            { validateStatus: (status) => status < 1000 },
          );
          meetupCanGetUnpublished =
            response.status === 200 && response.data.PublicationTime !== null;
        } catch (error) {
          console.error(error);
        }

        setBodyText(
          meetupCanGetUnpublished
            ? texts[language].unpublishModalUserCanRegisterText
            : texts[language].unpublishModalUserIsRegisteredText,
        );
        setShowSecondButton(meetupCanGetUnpublished);
        setIsLoading(false);
      };

      getMeetupValid();
    }
  }, [show]);

  return !show ? (
    <></>
  ) : (
    <Modal title={meetup.Title} opened={show} onClose={onClose}>
      {isLoading && <Loader size="lg" />}
      {!isLoading && (
        <Group direction="column" p="xl">
          <Paragraph text={bodyText} />
          <Group grow mt="xl">
            <Button
              text={texts[language].unpublishModalButtonAbort}
              onClick={() => onClose()}
              variant="outline"
            />
            {showSecondButton && (
              <Button
                text={texts[language].unpublishModalButtonAction}
                onClick={() => unpublishMeetup()}
              />
            )}
          </Group>
        </Group>
      )}
    </Modal>
  );
}
