import { AuthConsumer } from './AuthProvider';

export default function LoginCallback() {
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback();
        return <></>;
      }}
    </AuthConsumer>
  );
}
