import { useDispatch, useSelector } from 'react-redux';
import { Alert, Grid, HtmlText } from '@ingeniorforeningen/figurine-core';
import * as meetupActions from '../../store/meetups/actions';
import { TState } from '../../store/rootReducer';
import MeetupEditImageDragDrop from './MeetupEditImageDragDrop';
import MeetupEditImageView from './MeetupEditImageView';
import texts from '../../texts/texts';

export default function MeetupEditImage() {
  const dispatch = useDispatch();
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const language = useSelector((state: TState) => state.ui.language);

  const handleImageDrop = (image: File | null) => {
    dispatch(meetupActions.setImage(image));
    dispatch(
      meetupActions.setMeetup({
        ...meetup,
        RemoveMeetupImage: false,
      }),
    );
  };

  const handleImageDelete = () => {
    dispatch(meetupActions.setImage(null));
    dispatch(
      meetupActions.setMeetup({
        ...meetup,
        MeetupImageUrl: '',
        RemoveMeetupImage: true,
      }),
    );
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        {!meetup.MeetupImageUrl && <MeetupEditImageDragDrop onImageDrop={handleImageDrop} />}
        {meetup.MeetupImageUrl && <MeetupEditImageView onImageDelete={handleImageDelete} />}
        <Alert
          type="warning"
          mt="xl"
          text={<HtmlText html={texts[language].imageUpload.helpText} />}
          style={{ maxWidth: 'unset' }}
        />
      </Grid>
    </Grid>
  );
}
