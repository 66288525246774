import { THeaderProps } from '@ingeniorforeningen/figurine-core';
import { AppShell } from '@ingeniorforeningen/figurine-cms';
import AuthService from '../../authentication/authentication';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { trackingManager } from '../../tracking';

type AppWrapperProps = {
  clean?: boolean;
};

export default function AppWrapper({ clean = false }: AppWrapperProps) {
  const [user, setUser] = useState<THeaderProps['user']>({
    username: 'Mit Ida',
    role: [],
    links: [
      {
        text: 'Log out',
        href: `/logout`,
      },
    ],
  });

  const getUser = async () => {
    const user = await AuthService.UserManager.getUser();
    if (user?.profile) {
      setUser((prevUser) => ({
        ...prevUser,
        ...(user?.profile['name'] && { username: user?.profile['name'] }),
        ...(user?.profile['role'] && { role: user?.profile['role'] }),
      }));
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AppShell
      domain="MitIda"
      headerData={{
        user: user,
      }}
      environmentVariables={{ env: runtimeConfig.umbracoEnv }}
      trackingManager={trackingManager}
    >
      <Outlet />
    </AppShell>
  );
}
