import { useSelector, useDispatch } from 'react-redux';
import * as uiActions from '../store/ui/actions';
import { TState } from '../store/rootReducer';
import { TStepNumber, TFieldValidation } from '../store/ui/types';
import { TMeetup } from '../store/meetups/types';

export default function useStepValidation(): {
  validateStep: (step: TStepNumber, data: Partial<TMeetup>) => Promise<any>;
  validateAllSteps: (data: any) => Promise<void>;
  isStepValid: (validations: any) => boolean;
  isAllStepsValid: () => boolean;
} {
  const dispatch = useDispatch();
  const fieldValidation = useSelector((state: TState) => state.ui.fieldValidation);

  const isStepValid = (validations: any): boolean => {
    return !validations.some(
      (validation: Partial<TFieldValidation>) =>
        !validation.excludeInValidation && !validation.valid,
    );
  };

  const isAllStepsValid = (): boolean => {
    let valid = true;

    Object.keys(fieldValidation).forEach((value) => {
      const step = Number(value) as TStepNumber;
      const stepIsValid = isStepValid(
        Object.keys(fieldValidation[step]).map((field) => fieldValidation[step][field]),
      );

      if (!stepIsValid) {
        valid = false;
      }
    });

    return valid;
  };

  const validateStep = async (step: TStepNumber, meetup: Partial<TMeetup>) => {
    // Find list of fields (not excluded) to validate.
    const stepFields = Object.keys(fieldValidation[step])
      .map((field) => (!fieldValidation[step][field].excludeInValidation ? field : ''))
      .filter((field) => field);

    let response;

    if (stepFields.length > 0) {
      // Validate all the fields in the step in the same request.
      response = await dispatch(
        uiActions.updateFieldValidation({
          step,
          fields: stepFields,
          meetup,
          showMessage: true,
        }),
      );

      // Make the progress indicator signal an invalid step.
      if (!isStepValid(response)) {
        dispatch(uiActions.setStep({ key: step, state: 'invalid' }));
      } else {
        dispatch(uiActions.setStep({ key: step, state: 'completed' }));
      }
    } else {
      dispatch(uiActions.setStep({ key: step, state: 'completed' }));
    }
    return response;
  };

  // Check all steps using server-side checks.
  const validateAllSteps = async (meetup: Partial<TMeetup>) => {
    Object.keys(fieldValidation).forEach(async (value) => {
      const step = Number(value) as TStepNumber;
      await validateStep(step, meetup);
    });
  };

  return { validateStep, validateAllSteps, isStepValid, isAllStepsValid };
}
