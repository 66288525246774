import { AuthConsumer } from './AuthProvider';

type PrivateRouteProps = {
  children: JSX.Element;
};

export default function PrivateRoute({ children }: PrivateRouteProps) {
  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }): JSX.Element => {
        if (!!children && isAuthenticated()) {
          return children;
        }
        signinRedirect();
        return <></>;
      }}
    </AuthConsumer>
  );
}
