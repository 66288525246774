import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Modal, Paragraph, Loader, Group, Button } from '@ingeniorforeningen/figurine-core';
import { TMeetup } from '../../store/meetups/types';
import { TState } from '../../store/rootReducer';
import texts from '../../texts/texts';
import * as meetupActions from '../../store/meetups/actions';

type Props = {
  meetup: Partial<TMeetup>;
  show: boolean;
  onClose: () => void;
};

export default function MeetupCancelModal({ meetup, show, onClose }: Props) {
  const dispatch = useDispatch();
  const filterCreatedBy = useSelector((state: TState) => state.ui.filterCreatedBy);
  const [isLoading, setIsLoading] = useState(true);
  const [showSecondButton, setShowSecondButton] = useState(false);
  const [bodyText, setBodyText] = useState('');
  const language = useSelector((state: TState) => state.ui.language);

  const cancelMeetup = async () => {
    setBodyText('');
    setIsLoading(true);

    let success = false;

    try {
      const response = await axios.post(
        `${runtimeConfig.apiUrl}meetups/${meetup.MeetupNumber}/cancel/`,
        { validateStatus: (status: any) => status < 1000 },
      );

      const validResponse = response.status === 200;
      const isCancelled = response.data === 0;

      success = validResponse && isCancelled;

      if (success) {
        dispatch(meetupActions.getMeetups(filterCreatedBy));
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    setBodyText(success ? texts[language].cancelModalSuccess : texts[language].cancelModalFailure);
    setShowSecondButton(!success);
  };

  useEffect(() => {
    setBodyText('');
    setIsLoading(true);

    if (show) {
      let meetupCanGetCancelled = false;

      const getMeetupValid = async () => {
        try {
          const response = await axios.get<TMeetup>(
            `${runtimeConfig.apiUrl}meetups/${meetup.MeetupNumber}`,
            { validateStatus: (status) => status < 1000 },
          );

          const validResponse = response.status === 200;
          const canGetCancelled = response.data.MeetupStatus === 0;

          meetupCanGetCancelled = validResponse && canGetCancelled;
        } catch (error) {
          console.error(error);
        }

        setBodyText(
          meetupCanGetCancelled
            ? texts[language].cancelModalCanCancelText
            : texts[language].cancelModalIsCancelledText,
        );
        setShowSecondButton(meetupCanGetCancelled);
        setIsLoading(false);
      };

      getMeetupValid();
    }
  }, [show]);

  return show ? (
    <Modal title={meetup.Title} opened={show} onClose={onClose}>
      {isLoading && <Loader size="lg" />}
      {!isLoading && (
        <Group direction="column" p="xl">
          <Paragraph text={bodyText} />
          <Group grow mt="xl">
            <Button
              text={texts[language].cancelModalButtonAbort}
              onClick={() => onClose()}
              variant="outline"
            />
            {showSecondButton && (
              <Button
                text={texts[language].cancelModalButtonAction}
                onClick={() => cancelMeetup()}
              />
            )}
          </Group>
        </Group>
      )}
    </Modal>
  ) : (
    <></>
  );
}
