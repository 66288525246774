import { useSelector } from 'react-redux';
import { TState } from '../../store/rootReducer';
import { Image, File } from '@ingeniorforeningen/figurine-core';

type Props = {
  onImageDelete: () => void;
};

export default function MeetupEditImageView({ onImageDelete }: Props) {
  const meetup = useSelector((state: TState) => state.meetups.meetup);

  return (
    <>
      <Image src={meetup.MeetupImageUrl} alt={meetup.MeetupImageUrl} />
      <File
        filename={'Billede'}
        isFileUploaded
        mt="md"
        mb="xl"
        iconName="X"
        onClick={onImageDelete}
      />
    </>
  );
}
