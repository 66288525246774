import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import createRequestInterceptors from './interceptors/interceptors';

const container = document.getElementById('root');
const root = createRoot(container!);

createRequestInterceptors();

root.render(
  //   <React.StrictMode>
  <App />,
  //   </React.StrictMode>,
);
