import produce from 'immer';
import {
  UiAction,
  TUiState,
  SET_IS_LOADING,
  SET_FILTER_CREATED_BY,
  SET_CURRENT_STEP,
  SET_CURRENT_STEP_VALID,
  SET_STEP,
  SET_STEPS,
  SET_MEETUP_SAVED,
  SET_LANGUAGE,
  SET_FIELD_VALIDATION,
  SET_FIELD_VALIDATIONS,
  SET_ERROR,
  SET_SHOW_HEADER,
  SET_SHOW_FOOTER,
  TFieldValidation,
  TStepsState,
  TFieldValidationState,
} from './types';

export const initialSteps: TStepsState = {
  1: {
    state: 'active',
  },
  2: {
    state: 'unreached',
  },
  3: {
    state: 'unreached',
  },
  4: {
    state: 'unreached',
  },
  5: {
    state: 'unreached',
  },
  6: {
    state: 'unreached',
  },
  7: {
    state: 'unreached',
  },
  8: {
    state: 'unreached',
  },
};

export const initialFieldValidations: TFieldValidationState = {
  1: {
    Title: {},
    Teaser: { excludeInValidation: true },
    Description: { valid: true },
    IsRelevantForInternationals: { excludeInValidation: true },
  },
  2: {
    StartTime: {},
    EndTime: {},
    RegistrationDeadline: { valid: true },
    CancellationDeadline: { valid: true },
    PublicationTime: { valid: true },
  },
  3: {
    LocationId: {},
    LocationAdditionalInfo: { excludeInValidation: true },
    Region: {},
  },
  4: {
    MainOrganizerId: {},
    CoOrganizerIds: { excludeInValidation: true },
    MeetupTypeId: {},
  },
  5: {
    SeatsQuantity: { valid: true },
    SeatsRequired: { valid: true },
    ParticipantQuestion: { excludeInValidation: true },
  },
  6: {},
  7: {},
  8: {
    SustainableDevelopmentGoals: { valid: true },
  },
};

const initialState: TUiState = {
  isLoading: false,
  filterCreatedBy: 'CreatedByMe',
  currentStep: 1,
  currentStepValid: false,
  steps: initialSteps,
  meetupSaved: false,
  language: 'da',
  // All fields must be here for step validation to work unless excludeInValidation is true.
  // Default values set.
  fieldValidation: initialFieldValidations,
  error: { errorMessage: '', errorType: '' },
  showHeader: true,
  showFooter: true,
};

const uiReducer = (state = initialState, action: UiAction): TUiState => {
  switch (action.type) {
    case SET_IS_LOADING:
      return produce(state, (draft: TUiState) => {
        draft.isLoading = action.payload;
      });
    case SET_FILTER_CREATED_BY:
      return produce(state, (draft: TUiState) => {
        draft.filterCreatedBy = action.payload;
      });
    case SET_CURRENT_STEP:
      return produce(state, (draft: TUiState) => {
        draft.currentStep = action.payload;
      });
    case SET_CURRENT_STEP_VALID:
      return produce(state, (draft: TUiState) => {
        draft.currentStepValid = action.payload;
      });
    case SET_STEP:
      return produce(state, (draft: TUiState) => {
        draft.steps[action.payload.key] = {
          state: action.payload.state,
        };
      });
    case SET_STEPS:
      return produce(state, (draft: TUiState) => {
        draft.steps = action.payload;
      });
    case SET_MEETUP_SAVED:
      return produce(state, (draft: TUiState) => {
        draft.meetupSaved = action.payload;
        draft.currentStep = 1;
        draft.steps = initialSteps;
      });
    case SET_LANGUAGE:
      return produce(state, (draft: TUiState) => {
        draft.language = action.payload;
      });
    case SET_FIELD_VALIDATION:
      // Set validation for a single field in a specific step.
      // Only update the values actually provided.
      return produce(state, (draft: TUiState) => {
        const fieldValidation: Partial<TFieldValidation> = {};

        if (action.payload.valid !== undefined) {
          fieldValidation.valid = action.payload.valid;
        }

        if (action.payload.message !== undefined) {
          fieldValidation.message = action.payload.message;
        }

        if (action.payload.showMessage !== undefined) {
          fieldValidation.showMessage = action.payload.showMessage;
        }

        if (action.payload.excludeInValidation !== undefined) {
          fieldValidation.excludeInValidation = action.payload.excludeInValidation;
        }

        if (action.payload.step && action.payload.field) {
          draft.fieldValidation[action.payload.step][action.payload.field] = {
            ...draft.fieldValidation[action.payload.step][action.payload.field],
            ...fieldValidation,
          };
        }
      });
    case SET_FIELD_VALIDATIONS:
      return produce(state, (draft) => {
        draft.fieldValidation = action.payload;
      });
    case SET_ERROR:
      return produce(state, (draft) => {
        draft.error = action.payload;
      });
    case SET_SHOW_HEADER:
      return produce(state, (draft) => {
        draft.showHeader = action.payload;
      });
    case SET_SHOW_FOOTER:
      return produce(state, (draft) => {
        draft.showFooter = action.payload;
      });
    default:
      return state;
  }
};

export default uiReducer;
