import { useSelector, useDispatch } from 'react-redux';
import { TState } from '../store/rootReducer';
import { TMeetup } from '../store/meetups/types';
import { TStepNumber } from '../store/ui/types';
import * as meetupActions from '../store/meetups/actions';
import * as uiActions from '../store/ui/actions';

export default function useFieldEvent<T extends TStepNumber>(
  STEP_NUMBER: T,
): {
  onInputChange: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  onInputBlur: (
    e:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => void;
} {
  const dispatch = useDispatch();
  const meetup = useSelector((state: TState) => state.meetups.meetup);

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> 
  ) => {
    let value: string | boolean = e.currentTarget.value;
    if(e.target.type === "checkbox" && 'checked' in e.target ) {
       value = Boolean(e.target.checked);
    }
    dispatch(
      meetupActions.setMeetup({
        ...meetup,
        [e.currentTarget.name as keyof TMeetup]: value,
      }),
    );
  };

  const onInputBlur = (
    e:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      uiActions.updateFieldValidation({
        step: STEP_NUMBER,
        fields: [e.currentTarget.name],
        meetup: {
          ...meetup,
          [e.currentTarget.name as keyof TMeetup]: e.currentTarget.value,
        },
        showMessage: true,
      }),
    );
  };

  return { onInputChange, onInputBlur };
}
