import { Dispatch } from 'redux';
import axios from 'axios';
import {
  TMeetup,
  SetMeetupAction,
  SetLastMeetupCreatedAction,
  SetImageAction,
  SetMeetupsAction,
  SetCommitteesAction,
  SetMeetupTypesAction,
  SetLocationsAction,
  SetRegionsAction,
  SetParticipantTypesAction,
  SetSustainableDevelopmentGoals,
  SET_MEETUP,
  SET_IMAGE,
  SET_LAST_MEETUP_CREATED,
  SET_MEETUPS,
  SET_COMMITTEES,
  SET_MEETUP_TYPES,
  SET_LOCATIONS,
  SET_REGIONS,
  SET_PARTICIPANT_TYPES,
  SET_SUSTAINABLE_DEVELOPMENT_GOALS,
  TRegion,
  TLocation,
  TParticipantType,
  TKeyValue,
  TCommittee,
  TMeetupType,
  TLocationNew,
} from './types';
import { setError } from '../ui/actions';
import * as uiActions from '../ui/actions';
import store from '../store';
import { mapMeetupRequest, mapMeetupResponse } from './mappings';
import { TFilterCreatedBy } from '../ui/types';

export const setMeetup = (meetup: Partial<TMeetup>): SetMeetupAction => ({
  type: SET_MEETUP,
  payload: meetup,
});

export const setLastMeetupCreated = (meetup: Partial<TMeetup>): SetLastMeetupCreatedAction => ({
  type: SET_LAST_MEETUP_CREATED,
  payload: meetup,
});

export const setImage = (image: File | null): SetImageAction => ({
  type: SET_IMAGE,
  payload: image,
});

export const setMeetups = (meetups: Partial<TMeetup>[], concat = false): SetMeetupsAction => ({
  type: SET_MEETUPS,
  payload: { meetups, concat },
});

export const setCommittees = (values: TKeyValue[]): SetCommitteesAction => ({
  type: SET_COMMITTEES,
  payload: values,
});

export const setMeetupTypes = (values: TKeyValue[]): SetMeetupTypesAction => ({
  type: SET_MEETUP_TYPES,
  payload: values,
});

export const setSustainableDevelopmentGoals = (
  values: number[],
): SetSustainableDevelopmentGoals => ({
  type: SET_SUSTAINABLE_DEVELOPMENT_GOALS,
  payload: values,
});

export const setLocations = (values: TKeyValue[]): SetLocationsAction => ({
  type: SET_LOCATIONS,
  payload: values,
});

export const setRegions = (values: TKeyValue[]): SetRegionsAction => ({
  type: SET_REGIONS,
  payload: values,
});

export const setParticipantTypes = (values: TParticipantType[]): SetParticipantTypesAction => ({
  type: SET_PARTICIPANT_TYPES,
  payload: values,
});

export const upsertMeetup = (meetup: Partial<TMeetup>, image: File | null) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const meetupRequest = mapMeetupRequest(meetup);

      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let payload: Partial<TMeetup> | FormData = meetupRequest;

      options.headers = { 'Content-Type': 'multipart/form-data' };
      payload = new FormData();
      payload.append('json', JSON.stringify(meetupRequest));

      if (image) {
        payload.append('MeetupImage', image, image.name);
      }

      if (meetup.IsCopy && !meetup.RemoveMeetupImage && meetup.MeetupImageUrl != null && !image) {
        const response = await fetch(meetup.MeetupImageUrl)
          .then((o) => o)
          .catch((e) => {
            console.log(e);
          });
        if (response && response.ok) {
          const contentType = response.headers.get('Content-Type');
          const contentDisposition = response.headers.get('Content-Disposition');
          const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1]
            : 'original';
          const blob: Blob = await response.blob();
          let imageFile = new File([blob], fileName, { type: contentType || undefined });
          payload.append('MeetupImage', imageFile, fileName);
        }
      }
      let response;
      if (meetup.MeetupNumber) {
        response = await axios.put(
          `${runtimeConfig.apiUrl}meetups/${meetup.MeetupNumber}`,
          payload,
          options,
        );
      } else {
        response = await axios.post(`${runtimeConfig.apiUrl}meetups`, payload, options);
      }
      dispatch(uiActions.setMeetupSaved(true));
      dispatch(setLastMeetupCreated(response.data || meetup));
      dispatch(setMeetup({}));
      dispatch(setImage(null));
    } catch (e) {
      const errorMessage = e.response.data.Message || e.message;
      const errorType = e.response.data.Error || 'GenericError';

      const error = {
        errorMessage,
        errorType,
      };
      dispatch(setError(error));
    }
  };
};

export const getMeetup = (meetupNumber: number, copy = false) => {
  return async (dispatch: Dispatch): Promise<Partial<TMeetup> | undefined> => {
    let response = null;

    try {
      response = await axios.get<Partial<TMeetup>>(
        `${runtimeConfig.apiUrl}meetups/${meetupNumber}?copy=${copy}`,
      );

      const meetup = mapMeetupResponse(response.data);

      if (copy) {
        meetup.IsCopy = true;
        meetup.CopyImageFromMeetup = meetupNumber;
      }

      dispatch(setMeetup(meetup));
    } catch (e) {
      dispatch(setError(e.message));
    }

    return response?.data;
  };
};

export const getMeetups = (
  filter: TFilterCreatedBy = 'CreatedByMe',
  filterYear: number | undefined = undefined,
) => {
  return async (dispatch: Dispatch): Promise<Partial<TMeetup>[] | undefined> => {
    let response;

    try {
      response = await axios.get<Partial<TMeetup>[]>(
        `${runtimeConfig.apiUrl}meetups?filter=${filter}&filterYear=${filterYear}`,
      );

      dispatch(
        setMeetups(
          response.data
            ? response.data.map((meetup: Partial<TMeetup>) => mapMeetupResponse(meetup))
            : [],
        ),
      );
    } catch (e) {
      dispatch(setError(e.message));
    }

    return response?.data;
  };
};

export const previewMeetup = (meetupNumber: number) => {
  return async (dispatch: Dispatch): Promise<string> => {
    let response = null;

    try {
      response = await axios.get(`${runtimeConfig.apiUrl}meetups/${meetupNumber}/preview`);

      if (response.status === 200) {
        window.open(response.data, '_blank');
      }
    } catch (e) {
      dispatch(setError(e.message));
    }

    return response?.data as string;
  };
};

export const getCommittees = () => {
  return async (dispatch: Dispatch): Promise<TKeyValue[]> => {
    let options: TKeyValue[] = [];

    try {
      const response = await axios.get<TLocation[]>(`${runtimeConfig.apiUrl}committees`);
      options = response.data.map((item: TCommittee) => {
        return {
          value: item.Id,
          label: item.Name,
        };
      });
      dispatch(setCommittees(options));
    } catch (e) {
      dispatch(setError(e.message));
    }

    return options;
  };
};

export const getMeetupTypes = (committeeId: string) => {
  return async (dispatch: Dispatch): Promise<TKeyValue[]> => {
    let options: TKeyValue[] = [];

    try {
      const response = await axios.get<TMeetupType[]>(
        `${runtimeConfig.apiUrl}meetuptypes?committeeId=${committeeId}`,
      );
      options = response.data.map((item: TMeetupType) => {
        return {
          value: item.Id,
          label: item.Name,
        };
      });
      dispatch(setMeetupTypes(options));
    } catch (e) {
      dispatch(setError(e.message));
    }

    return options;
  };
};

export const getLocation = (locationId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await axios.get<TLocation>(
        `${runtimeConfig.apiUrl}meetuplocations/${locationId}`,
      );

      if (response.data) {
        dispatch(
          setLocations([
            {
              value: response.data.Id,
              label: `${response.data.Name}, ${response.data.Address1}, ${response.data.City}`,
            },
          ]),
        );
      }
    } catch (e) {
      dispatch(setError(e.message));
    }
  };
};

export const createLocation = (newLocation: TLocationNew, meetup?: Partial<TMeetup>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await axios.post(`${runtimeConfig.apiUrl}meetuplocations`, newLocation);

      if (response.data) {
        const state = store.getState();
        dispatch(
          setLocations([
            ...state.meetups.locations,
            { value: response.data, label: newLocation.Name },
          ]),
        );

        if (meetup) {
          dispatch(setMeetup({ ...state.meetups.meetup, LocationId: response.data }));
        }
      }
    } catch (e) {
      dispatch(setError(e.message));
    }
  };
};

export const getLocations = (search: string) => {
  return async (dispatch: Dispatch): Promise<TKeyValue[]> => {
    let options: TKeyValue[] = [];

    try {
      const response = await axios.get<TLocation[]>(
        `${runtimeConfig.apiUrl}meetuplocations?q=${search}`,
      );
      options = response.data.map((item: TLocation) => {
        return {
          value: item.Id,
          label: `${item.Name}${item.Address1 ? `, ${item.Address1}` : ''}${
            item.City ? `, ${item.City}` : ''
          }`,
        };
      });
      dispatch(setLocations(options));
    } catch (e) {
      dispatch(setError(e.message));
    }

    return options;
  };
};

export const getRegions = () => {
  return async (dispatch: Dispatch): Promise<TKeyValue[]> => {
    let options: TKeyValue[] = [];

    try {
      const response = await axios.get<TRegion[]>(`${runtimeConfig.apiUrl}regions`);
      options = response.data.map((item: TRegion) => {
        return {
          value: item.Id,
          label: item.Name,
        };
      });
      dispatch(setRegions(options));
    } catch (e) {
      dispatch(setError(e.message));
    }

    return options;
  };
};

export const getParticipantTypes = () => {
  return async (dispatch: Dispatch): Promise<TParticipantType[] | undefined> => {
    let response;

    try {
      response = await axios.get<TParticipantType[]>(`${runtimeConfig.apiUrl}participanttypes`);
      dispatch(setParticipantTypes(response.data));
    } catch (e) {
      dispatch(setError(e.message));
    }

    return response?.data;
  };
};
