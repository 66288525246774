import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  Grid,
  Heading,
  Button,
  Loader,
  Container,
  Paragraph,
  Group,
  Paper,
  Caption,
} from '@ingeniorforeningen/figurine-core';
import { TMeetupSpeaker, TMeetupSpeakerResponse } from '../../store/meetups/types';
import { TState } from '../../store/rootReducer';
import * as meetupActions from '../../store/meetups/actions';
import MeetupEditSpeakerEdit from './MeetupEditSpeakerEdit';
import texts from '../../texts/texts';

type SpeakerProps = {
  speaker: TMeetupSpeaker;
  onDelete: () => void;
  isLoading: boolean;
};

function Speaker({ speaker, isLoading, onDelete }: SpeakerProps) {
  const language = useSelector((state: TState) => state.ui.language);
  return (
    <Paper p="xl">
      <Heading type="h2" text={speaker.Name} />

      <Group grow my="md">
        <Group direction="column" spacing={0}>
          <Caption text={texts[language].editSpeakerTitle.label} />
          <Paragraph text={speaker.Title ?? ''} />
        </Group>

        <Group direction="column" spacing={0}>
          <Caption text={texts[language].editSpeakerEmail.label} />
          <Paragraph text={speaker.Email ?? ''} />
        </Group>

        <Group direction="column" spacing={0}>
          <Caption text={texts[language].editSpeakerMobilePhone.label} />
          <Paragraph text={speaker.MobilePhone ?? ''} />
        </Group>
      </Group>

      <Group justify="flex-end">
        <Button text="Slet" variant="subtle" onClick={onDelete} disabled={isLoading} />
      </Group>
    </Paper>
  );
}

export default function MeetupEditSpeakers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { meetupNumber } = useParams<{ meetupNumber: string }>();
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const language = useSelector((state: TState) => state.ui.language);
  const [showModal, setShowModal] = useState(false);
  const [speakers, setSpeakers] = useState<TMeetupSpeaker[]>([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState<TMeetupSpeaker>();
  const [isLoading, setIsLoading] = useState(false);

  const getSpeakers = async () => {
    const response = await axios.get<TMeetupSpeakerResponse[]>(
      `${runtimeConfig.apiUrl}meetups/${meetupNumber}/speakerregistrations`,
    );
    setSpeakers(
      response.data.map((speaker: TMeetupSpeakerResponse) => {
        return {
          IdaNumber: speaker.Contact?.IdaNumber,
          Name: `${speaker.Contact?.FirstName} ${speaker.Contact?.LastName}`,
          Title: speaker.Contact?.Title || '',
          Email: speaker.Contact?.Email || '',
          MobilePhone: speaker.Contact?.Mobile || '',
        };
      }),
    );
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    if (showModal) {
      getSpeakers();
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (meetupNumber) {
      const getMeetup = async () => {
        await dispatch(meetupActions.getMeetup(Number(meetupNumber)));
      };
      getMeetup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupNumber]);

  useEffect(() => {
    if (meetup.MeetupNumber) {
      getSpeakers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetup]);

  const deleteSpeaker = async (speakerDelete: TMeetupSpeaker) => {
    try {
      setIsLoading(true);

      await axios.delete(
        `${runtimeConfig.apiUrl}meetups/${meetupNumber}/speakerregistrations/${speakerDelete.IdaNumber}`,
      );
      const updatedSpeakers = speakers.filter(
        (speaker) => speaker.IdaNumber !== speakerDelete.IdaNumber,
      );
      setSpeakers(updatedSpeakers);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      mt={{ base: 0, sm: 'xl' }}
      style={{
        maxWidth: '50rem',
      }}
    >
      <MeetupEditSpeakerEdit
        show={showModal}
        speaker={selectedSpeaker}
        onClose={() => toggleModal()}
        meetupNumber={meetup.MeetupNumber}
      />

      <Heading type="h2" text="Foredragsholdere" />
      {meetup.MeetupNumber ? <Paragraph strong text={meetup.Title!} /> : <Loader size="lg" />}

      <Grid container mt="xl">
        <Grid item sm={8}>
          {speakers &&
            speakers.map((speaker, index) => (
              <Speaker
                key={`speaker-${index}`}
                isLoading={isLoading}
                speaker={speaker}
                onDelete={() => {
                  deleteSpeaker(speaker);
                }}
              />
            ))}
        </Grid>
      </Grid>

      <Group justify="center" mt="xl">
        <Button
          onClick={() => {
            setSelectedSpeaker({
              IdaNumber: '',
              Name: '',
              Title: '',
              Email: '',
              MobilePhone: '',
            });

            toggleModal();
          }}
          text={texts[language].editSpeakerModalTitle}
          icon="Plus"
        />
      </Group>

      <Group mt="xl">
        <Button
          onClick={() => navigate('/')}
          text={texts[language].buttonSpeakersBack}
          variant="outline"
          mb="xl"
        />
      </Group>
    </Container>
  );
}
