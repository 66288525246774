import { AuthConsumer } from './AuthProvider';

export default function SilentRenew() {
  return (
    <AuthConsumer>
      {({ signinSilentCallback }) => {
        signinSilentCallback();
        return <></>;
      }}
    </AuthConsumer>
  );
}
