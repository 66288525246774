import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: runtimeConfig.appInsightsKey,
    disableTelemetry: runtimeConfig.appInsightsEnabled === false,
    enableUnhandledPromiseRejectionTracking: true,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();
// eslint-disable-next-line
const telemetryInitializer = (envelope: any) => {
  // eslint-disable-next-line
  envelope.tags['ai.cloud.role'] = 'MeetupCreateUi.Web';
};
appInsights.addTelemetryInitializer(telemetryInitializer);
export { reactPlugin, appInsights };
