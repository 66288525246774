import { useDispatch, useSelector } from 'react-redux';
import {
  Group,
  Heading,
  Paragraph,
  RingProgress,
  Stepper,
  createStyles,
  rem,
  useMediaQuery,
  useTheme,
} from '@ingeniorforeningen/figurine-core';
import { TState } from '../../store/rootReducer';
import { TStepNumber } from '../../store/ui/types';
import * as uiActions from '../../store/ui/actions';

type Props = {
  onStepChanged: (currentStep: number, previousStep: number) => void;
};

export default function ProgressIndicatorWrapper({ onStepChanged }: Props) {
  const currentStep = useSelector((state: TState) => state.ui.currentStep);
  const stepsState = useSelector((state: TState) => state.ui.steps);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.sm})`);
  const dispatch = useDispatch();

  const styles = createStyles((theme) => ({
    root: {},
    step: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    separator: {
      marginLeft: theme.spacing.xs,
      marginRight: theme.spacing.xs,
    },
    stepBody: {
      marginTop: theme.spacing.xs,
      marginLeft: 0,
    },
    stepIcon: {
      fontSize: rem(12),
      height: rem(32),
      width: rem(32),
      minWidth: rem(32),
    },
    stepLabel: {
      fontSize: rem(12),
    },
  }));

  const { classes } = styles();

  const STEPS = [
    {
      label: 'Beskrivelse',
    },
    {
      label: 'Datoer',
    },
    {
      label: 'Arrangementssted',
    },
    {
      label: 'Arrangører',
    },
    {
      label: 'Deltagere',
    },
    {
      label: 'Deltagertyper og priser',
    },
    {
      label: 'Tilvalg',
    },
    {
      label: 'Ekstra',
    },
  ];

  if (isMobile) {
    return (
      <Group mx="md" align="center" noWrap>
        <Group align="center">
          <RingProgress
            label={
              <Group justify="center" spacing={0}>
                <Paragraph strong text={currentStep.toString()} />
                <Paragraph
                  text={`af ${STEPS.length}`}
                  variant="neutral"
                  style={{ marginLeft: 6 }}
                />
              </Group>
            }
            progress={(currentStep / (STEPS.length - 1)) * 100}
            size={90}
          />
        </Group>
        <Group direction="column" spacing={0}>
          <Heading type="h2" text={STEPS[currentStep - 1]?.label ?? ''} />
          <Paragraph text={`Næste: ${STEPS[currentStep]?.label ?? ''}`} />
        </Group>
      </Group>
    );
  }

  const onStepClick = (stepIndex: number) => {
    dispatch(uiActions.setStep({ key: stepIndex + 1, state: 'active' }));
    dispatch(uiActions.setCurrentStep((stepIndex + 1) as TStepNumber));
    onStepChanged(stepIndex, currentStep);
  };

  const createSteps = () => {
    return STEPS.map((step, index) => {
      return {
        label: step.label,
        error: stepsState[index + 1]?.state === 'invalid',
        completed: stepsState[index + 1]?.state === 'completed',
      };
    });
  };

  return (
    <>
      <Stepper
        styles={classes}
        m="xl"
        active={currentStep - 1}
        items={createSteps()}
        onStepClick={onStepClick}
        allowJumpForward
      />
    </>
  );
}
