import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Group, SelectField, MultiSelectField } from '@ingeniorforeningen/figurine-core';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import * as meetupActions from '../../store/meetups/actions';
import * as uiActions from '../../store/ui/actions';
import getErrorMessage from '../../helpers/errorMessage';
import { FieldsOrganizers } from '../../helpers/fields';
import { TMeetup } from '../../store/meetups/types';
import { toSelectFieldProps } from '../../helpers/utils';

const STEP_NUMBER = 4;

export default function MeetupEditOrganizers() {
  const dispatch = useDispatch();
  const language = useSelector((state: TState) => state.ui.language);
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const fieldValidationState = useSelector((state: TState) => state.ui.fieldValidation);
  const committees = useSelector((state: TState) => state.meetups.committees);
  const meetupTypes = useSelector((state: TState) => state.meetups.meetupTypes);

  useEffect(() => {
    dispatch(meetupActions.getCommittees());
  }, [dispatch]);

  useEffect(() => {
    if (meetup.MainOrganizerId) {
      dispatch(meetupActions.getMeetupTypes(meetup.MainOrganizerId));
    }
  }, [dispatch, meetup]);

  const onOrganizerChange = (value: string) => {
    const payload = {
      ...meetup,
      [FieldsOrganizers.MainOrganizerId]: value,
    };
    delete payload[FieldsOrganizers.MeetupTypeId];
    updateStore(
      [FieldsOrganizers.MainOrganizerId, FieldsOrganizers.MeetupTypeId],
      payload as TMeetup,
    );

    dispatch(meetupActions.getMeetupTypes(value as string));
  };

  const onSelectChange = (e: { value: string | string[]; name: string }) => {
    const payload = {
      ...meetup,
      [e.name as keyof TMeetup]: e.value,
    };
    updateStore([e.name], payload as TMeetup);
  };

  const updateStore = (name: string[], payload: TMeetup) => {
    dispatch(meetupActions.setMeetup(payload));
    dispatch(
      uiActions.updateFieldValidation({
        step: STEP_NUMBER,
        fields: name,
        meetup: payload,
      }),
    );
  };

  return (
    <Group direction="column" spacing="xl" mt="xl">
      <SelectField
        name={FieldsOrganizers.MainOrganizerId}
        label={texts[language].mainOrganizer.label}
        placeholder={texts[language].mainOrganizer.placeholder}
        description={texts[language].mainOrganizer.helpText}
        onChange={(value) => onOrganizerChange(value)}
        value={meetup.MainOrganizerId || ''}
        options={toSelectFieldProps(committees)}
        error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsOrganizers.MainOrganizerId)}
        required
        cypress="mainOrganizerId"
      />
      {/* Render the MultiSelectField component only when committees have values, otherwise the internal state setting default value(s) look-up in an emtpy array */}
      {committees && committees.length > 0 && (
        <MultiSelectField
          name={FieldsOrganizers.CoOrganizerIds}
          label={texts[language].coOrganizers.label}
          placeholder={texts[language].coOrganizers.placeholder}
          description={texts[language].coOrganizers.helpText}
          onChange={(value) => onSelectChange({ name: FieldsOrganizers.CoOrganizerIds, value })}
          options={toSelectFieldProps(committees)}
          error={getErrorMessage(
            fieldValidationState,
            STEP_NUMBER,
            FieldsOrganizers.CoOrganizerIds,
          )}
          cypress="coOrganizerIds"
          defaultValue={meetup.CoOrganizerIds || []}
        />
      )}
      <SelectField
        name={FieldsOrganizers.MeetupTypeId}
        label={texts[language].meetupType.label}
        placeholder={texts[language].meetupType.placeholder}
        description={texts[language].meetupType.helpText}
        onChange={(value) => onSelectChange({ name: FieldsOrganizers.MeetupTypeId, value })}
        value={meetup.MeetupTypeId || ''}
        options={toSelectFieldProps(meetupTypes)}
        error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsOrganizers.MeetupTypeId)}
        required
        cypress="meetupTypeId"
      />
    </Group>
  );
}
