import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MeetupEditOptionEdit from './MeetupEditOptionEdit';
import { TMeetupOption, MeetupOptionType } from '../../store/meetups/types';
import { TState } from '../../store/rootReducer';
import * as meetupActions from '../../store/meetups/actions';
import {
  Button,
  Caption,
  Grid,
  Group,
  Heading,
  Paper,
  Paragraph,
} from '@ingeniorforeningen/figurine-core';
import texts from '../../texts/texts';

type OptionProps = {
  option: TMeetupOption;
  cypress: string;
  onDelete: () => void;
  onEdit: () => void;
};

function Option({ option, cypress, onDelete, onEdit }: OptionProps) {
  const language = useSelector((state: TState) => state.ui.language);
  return (
    <Paper p="xl" cypress={cypress}>
      <Grid container columns={2}>
        <Grid item xs={2}>
          <Heading type="h2" text={option.Name} />
        </Grid>
        <Grid item xs={2} sm={1}>
          <Group grow>
            <Group direction="column" spacing={0}>
              <Caption text={texts[language].editOptionType.label} />
              <Paragraph text={option.Type === MeetupOptionType.Person ? 'Person' : 'Ydelse'} />
            </Group>

            <Group direction="column" spacing={0}>
              <Caption text={texts[language].editOptionPrice.label} />
              <Paragraph text={`${Number(option.Price).toLocaleString('da')} kr`} />
            </Group>
          </Group>
        </Grid>
        <Grid item xs={2} sm={1}>
          <Group grow>
            <Group direction="column" spacing={0}>
              <Caption text={texts[language].editOptionMaxQuantity.label} />
              <Paragraph text={option.MaxQuantity ?? ''} />
            </Group>

            <Group direction="column" spacing={0}>
              <Caption text={texts[language].editOptionMaxForSignup.label} />
              <Paragraph text={option.MaxForSignup ?? ''} />
            </Group>
          </Group>
        </Grid>
        <Grid item xs={2}>
          <Group justify="flex-end">
            <Button text="Slet" variant="subtle" onClick={onDelete} />
            <Button text="Rediger" icon="Edit" onClick={onEdit} />
          </Group>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function MeetupEditOptions() {
  const dispatch = useDispatch();
  const meetup = useSelector((state: TState) => state.meetups.meetup);

  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Partial<TMeetupOption>>();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onDelete = (index: number) => {
    let updatedOptions = [...(meetup.Options || [])];
    updatedOptions.splice(index, 1);

    dispatch(
      meetupActions.setMeetup({
        ...meetup,
        Options: updatedOptions,
      }),
    );
  };

  return (
    <>
      <MeetupEditOptionEdit
        show={showModal}
        option={selectedOption}
        onClose={() => toggleModal()}
        onSave={(option: TMeetupOption) => {
          toggleModal();

          let updatedOptions = [...(meetup.Options || [])];
          if (selectedIndex === updatedOptions.length) {
            updatedOptions = [...updatedOptions, option];
          } else if (selectedIndex !== undefined) {
            updatedOptions[selectedIndex] = option;
          }
          dispatch(
            meetupActions.setMeetup({
              ...meetup,
              Options: updatedOptions,
            }),
          );
        }}
      />

      <Group direction="column">
        {meetup?.Options &&
          meetup.Options.map((option, index) => (
            <Option
              key={`${option.Name}`}
              cypress={`Option${index}`}
              option={option}
              onDelete={() => onDelete(index)}
              onEdit={() => {
                setSelectedOption(option);
                setSelectedIndex(index);
                toggleModal();
              }}
            />
          ))}

        <Group justify="center" mt="xl">
          <Button
            text="Tilføj tilvalg"
            icon="Plus"
            onClick={() => {
              setSelectedOption({});
              setSelectedIndex(meetup?.Options?.length ?? 0);
              toggleModal();
            }}
            cypress="AddOptionButton"
          />
        </Group>
      </Group>
    </>
  );
}
