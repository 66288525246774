import { TFieldValidationState } from '../store/ui/types';

export default function getErrorMessage(
  fieldValidationState: TFieldValidationState,
  step: number,
  field: string,
): string | undefined {
  return fieldValidationState[step][field].showMessage
    ? fieldValidationState[step][field].message
    : '';
}
