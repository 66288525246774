import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TState } from '../../store/rootReducer';
import { TFilterCreatedBy } from '../../store/ui/types';
import { TMeetup } from '../../store/meetups/types';
import {
  Heading,
  Button,
  Loader,
  SegmentedControl,
  TextField,
  SelectField,
  Group,
  Container,
  TSelectFieldItem,
} from '@ingeniorforeningen/figurine-core';
import * as meetupActions from '../../store/meetups/actions';
import * as uiActions from '../../store/ui/actions';
import useDraft from '../../hooks/useDraft';
import Meetup from './Meetup';

export default function Meetups() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const meetups = useSelector((state: TState) => state.meetups.meetups);
  const isLoading = useSelector((state: TState) => state.ui.isLoading);
  const filterCreatedBy = useSelector((state: TState) => state.ui.filterCreatedBy);
  const { draft, resumeDraft, removeDraft } = useDraft();
  const [query, setQuery] = useState('');
  const [filterYear, setFilterYear] = useState(new Date().getFullYear());

  useEffect(() => {
    dispatch(uiActions.resetSteps());
    dispatch(uiActions.resetFieldValidations());
    dispatch(meetupActions.setMeetup({}));
    dispatch(meetupActions.getMeetups(filterCreatedBy, filterYear));
  }, []);

  const onChangeFilter = (filter: string) => {
    dispatch(meetupActions.setMeetups([], false));
    dispatch(uiActions.setFilterCreatedBy(filter as TFilterCreatedBy));
    dispatch(meetupActions.getMeetups(filter as TFilterCreatedBy, filterYear));
  };

  const onYearFilter = (year: number) => {
    setFilterYear(year);
    dispatch(meetupActions.setMeetups([], false));
    dispatch(meetupActions.getMeetups(filterCreatedBy, year));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value.toLowerCase());
  };

  const yearOptions = useMemo((): TSelectFieldItem[] => {
    const startYear = 2 + new Date().getFullYear();
    const endYear = 2000;
    const years: TSelectFieldItem[] = [];
    for (let year = startYear; year >= endYear; year--) {
      years.push({
        label: year.toString(),
        value: year.toString(),
      });
    }
    return years;
  }, []);

  const filteredMeetups = useMemo(() => {
    if (!query) {
      return meetups;
    }

    return meetups.filter((meetup) => {
      const { Title, MeetupNumber, StartTime, MainOrganizer, MeetupType } = meetup;
      const lowercaseQuery = query.toLowerCase();

      return (
        (MeetupNumber && MeetupNumber.toString().includes(query)) ||
        (Title && Title.toLowerCase().includes(lowercaseQuery)) ||
        (StartTime && StartTime.toString().includes(query)) ||
        (MainOrganizer && MainOrganizer.includes(query)) ||
        (MeetupType?.Name && MeetupType.Name.includes(query))
      );
    });
  }, [meetups, query]);

  const groupMeetupsByMonthAndYear = (
    meetups: Partial<TMeetup>[],
  ): { key: string; meetups: TMeetup[]; formattedDate: string }[] => {
    if (!meetups || meetups.length === 0) {
      return [];
    }

    const grouped: { [key: string]: TMeetup[] } = {};
    meetups.forEach((meetup) => {
      if (!meetup?.StartTime) {
        return;
      }
      const monthYearKey = new Date(meetup.StartTime).toISOString().substring(0, 7);
      if (!grouped[monthYearKey]) {
        grouped[monthYearKey] = [];
      }
      grouped[monthYearKey].push(meetup as TMeetup);
    });

    const result = Object.entries(grouped)
      .sort(([a], [b]) => b.localeCompare(a))
      .map(([key, meetups]) => {
        const firstMeetup = meetups[0];
        let firstMeetupStartTime = new Date(firstMeetup.StartTime!);
        const meetupMonth = firstMeetupStartTime.toLocaleString('da-DK', { month: 'long' });
        const capitalizedMonth = meetupMonth.charAt(0).toUpperCase() + meetupMonth.slice(1);

        return {
          key,
          meetups,
          formattedDate: `${capitalizedMonth} ${firstMeetupStartTime.getFullYear()}`,
        };
      });

    return result;
  };

  const meetupsToRender = groupMeetupsByMonthAndYear(query ? filteredMeetups : meetups);
  const hasMeetups = meetups.length > 0;

  return (
    <Container mt="xl" pt="xl">
      <Heading align="center" text="Arrangementer" my="xl" />

      <Group justify="space-between" my="xl" py="xl">
        <Button
          id="meetupCreate"
          variant="cta"
          onClick={() => navigate('/arrangementer/opret')}
          cypress="meetupCreate"
          text="Opret arrangement"
        />

        {draft && (
          <Group>
            <Button
              id="meetupResumeDraft"
              component="a"
              variant="subtle"
              onClick={() => {
                resumeDraft();
                navigate('/arrangementer/opret');
              }}
              text="Kladde"
            />

            <Button
              id="meetupRemoveDraft"
              component="a"
              variant="outline"
              onClick={() => removeDraft()}
              text="Slet kladde"
            />
          </Group>
        )}
      </Group>

      <Group justify="space-between" my="xl" py="xl">
        <SelectField
          name="FilterYear"
          label="Afviklingsår"
          placeholder="Vælg år"
          aria-describedby=""
          description=""
          onChange={(value) => ({ name: onYearFilter(parseInt(value)) })}
          value={String(filterYear)}
          options={yearOptions}
          error=""
          required
          cypress="filterYear"
        />
        <SegmentedControl
          name="filterCreatedBy"
          data={[
            {
              label: 'Mine arrangementer',
              value: 'CreatedByMe',
            },
            {
              label: 'Mine enheders arrangementer',
              value: 'CreatedByCommittee',
            },
          ]}
          value={filterCreatedBy}
          onChange={onChangeFilter}
          fullWidth
        />
      </Group>

      {hasMeetups && (
        <>
          <TextField
            name="Searchfield"
            label="Filtrer på nummer, titel, starttidspunkt, hovedarrangør og type"
            placeholder="Filtrer på nummer, titel, starttidspunkt, hovedarrangør og type"
            value={query}
            onChange={handleSearch}
            style={{ width: '100%' }}
            type="search"
            icon="Search"
            my="xl"
            required
          />
          {meetupsToRender.map((group) => (
            <Group key={group.key} direction="column">
              <Heading type="h2" text={group.formattedDate} pt="xl" mt="xl" />
              {group.meetups.map((meetup) => (
                <div key={meetup.MeetupNumber}>
                  <Meetup meetup={meetup} />
                </div>
              ))}
            </Group>
          ))}
        </>
      )}
      {isLoading && (
        <Group justify="center">
          <Loader size="lg" />
        </Group>
      )}
    </Container>
  );
}
