import { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import { useParams } from 'react-router-dom';
import { Loader, Button } from '@ingeniorforeningen/figurine-core';
import { format, parseISO } from 'date-fns';
import { da } from 'date-fns/locale';
import {
  TMeetup,
  TRegistration,
  TRegistrationOption,
  TRegistrationContact,
} from '../../store/meetups/types';
import { encode as iconvencode } from 'iconv-lite';

function formatName(contact: TRegistrationContact): string {
  return `${contact.FirstName} ${contact.MiddleName ?? ''} ${contact.LastName}`.replace('  ', ' ');
}

function formatFirstName(contact: TRegistrationContact): string {
  return `${contact.FirstName} ${contact.MiddleName ?? ''}`.replace('  ', ' ');
}

function formatLastName(contact: TRegistrationContact): string {
  return `${contact.LastName}`.replace('  ', ' ');
}

function formatOption(option: TRegistrationOption): string {
  return `${option.Name}, ${option.Quantity} stk á kr ${option.Price}`;
}

function formatRegistrationStatus(status: string): string {
  let label = '';
  switch (status) {
    case 'Registered':
      label = 'Tilmeldt';
      break;
    case 'OnWaitingList':
      label = 'Venteliste';
      break;
    case 'Unsubscribed':
      label = 'Afmeldt';
      break;
    default:
      label = '';
  }
  return label;
}

function formatPhone(phone: string): string {
  if (phone == null) return '';

  return phone
    .replace(/\s/g, '')
    .replace(/(\+?.{2})/g, '$1 ')
    .trim();
}

// A basic, general format function for dates
function formatDatetime(date: Date, format: string) {
  const _padStart = (value: number): string => value.toString().padStart(2, '0');
  return format
    .replace(/yyyy/g, _padStart(date.getFullYear()))
    .replace(/dd/g, _padStart(date.getDate()))
    .replace(/MM/g, _padStart(date.getMonth() + 1))
    .replace(/HH/g, _padStart(date.getHours()))
    .replace(/mm/g, _padStart(date.getMinutes()))
    .replace(/ss/g, _padStart(date.getSeconds()));
}

function formatDate(date: Date | null | undefined): string {
  if (date == null) return '';

  return formatDatetime(new Date(date), 'yyyy-MM-dd HH:mm:ss');
}

function exportToCsv(
  filename: string,
  meetup: TMeetup | undefined,
  registrations: TRegistration[],
): void {
  if (!registrations || !registrations.length || meetup == null) {
    return;
  }
  const separator: string = ';';

  let columHeaders: string[] = [
    'Fornavn',
    'Efternavn',
    'Titel',
    'Postnummer',
    'By',
    'Firma',
    'Telefon',
    'Mobil',
    'Email',
    'Status',
    'Antal',
    'OPTIONS',
    'Spørgsmål',
    'Tilmeldingstidspunkt',
    'Afmeldinstidspunkt',
  ];

  let columnHeadersWithOptions: string[] = [];

  columHeaders.forEach((header) => {
    if (header === 'OPTIONS') {
      meetup.Options.forEach((meetupOption) =>
        columnHeadersWithOptions.push(`${meetupOption.Name}, kr. ${meetupOption.Price}`),
      );
    } else {
      columnHeadersWithOptions.push(header);
    }
  });

  const exportRegistration = (registration: TRegistration): string => {
    const quote = (data: string): string => {
      if (data == null) return '""';

      if (data.search(/("|;|\n)/g) >= 0) {
        data = `"${data.replace('"', '""')}"`;
      }
      return data;
    };

    const exportOptions = (options: TRegistrationOption[], columns: string[]): void => {
      meetup.Options.forEach((meetupOption) => {
        const option = options.find((o) => o.Name === meetupOption.Name);
        if (option != null) {
          columns.push(quote(option.Quantity.toString()));
        } else {
          columns.push(quote(''));
        }
      });
    };

    let columns: string[] = [];

    columns.push(quote(formatFirstName(registration.Contact)));
    columns.push(quote(formatLastName(registration.Contact)));
    columns.push(quote(registration.Contact.Title));
    columns.push(quote(registration.Contact.Zip));
    columns.push(quote(registration.Contact.City));
    columns.push(quote(registration.CompanyName));
    columns.push(quote(formatPhone(registration.Contact.Telephone)));
    columns.push(quote(formatPhone(registration.Contact.Mobile)));
    columns.push(quote(registration.Contact.Email));
    columns.push(quote(formatRegistrationStatus(registration.RegistrationStatus)));
    columns.push(quote(registration.NumberOfParticipants.toString()));
    exportOptions(registration.Options, columns);
    columns.push(quote(registration.AnswersForQuestion));
    columns.push(quote(formatDate(registration.CreatedOn)));
    columns.push(quote(formatDate(registration.UnregisteredAt)));

    return columns.join(separator);
  };

  const csvContent =
    `sep=${separator}` +
    '\n' +
    columnHeadersWithOptions.join(separator) +
    '\n' +
    registrations
      .map((registration) => {
        return exportRegistration(registration);
      })
      .join('\n');

  const encodedCsvContent = iconvencode(csvContent, 'win1252');
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    var blob = new Blob([encodedCsvContent], { type: 'data:text/csv;charset=win1252;' });
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
const GlobalStyle = createGlobalStyle`
  body {
    background: white!important;
  }
`;

const ContentDiv = styled.div`
  background-color: #fff;
  padding: 0 20px 10px 20px;

  @media print {
    padding: 0;
    font-family: georgia, times, serif;
  }
`;

const TheadTable = styled.thead`
  display: table-header-group;
`;

const InfoTextDiv = styled.div`
  font-size: 14px;
  padding: 0px 0px 15px 0px;

  @media print {
    font-size: 9pt;
    font-family: georgia, times, serif;
  }
`;

const MeetupTitle = styled.div`
  font-size: 35px;
  padding: 0px 0px 20px 0px;

  @media print {
    font-family: georgia, times, serif;
  }
`;

const MeetupTableCenter = styled.div`
  text-align: left;
  margin: 0 auto;
  padding-bottom: 20px;
  background-color: #fff;

  @media print {
    font-family: georgia, times, serif;
  }
`;

const MeetupTable = styled.table`
  text-align: left;
  width: 100%;
`;

const MeetupHeader = styled.th`
  font-size: 14px;
  font-weight: bold;
  width: 33%;

  @media print {
    font-size: 12px;
    font-family: georgia, times, serif;
  }
`;

const MeetupTextCell = styled.td`
  font-size: 14px;
  padding-bottom: 25px;

  @media print {
    font-size: 10px;
    font-family: georgia, times, serif;
  }
`;

const RegistrationTable = styled.table`
  width: 100%;
  background-color: #fff;
  text-align: left;
  border-collapse: collapse;
`;

const RegistrationHeader = styled.th`
  font-size: 14px;
  font-weight: bold;
  padding: 20px 5px 20px 5px;
  background-color: #fff;
  border-top: solid 1px #bbb;
  border-bottom: solid 1px #bbb;

  @media print {
    font-family: georgia, times, serif;
    font-size: 10px;
  }
`;

const RegistrationCell = styled.td`
  font-size: 14px;
  padding: 10px 6px;
  background-color: #fff;
  border-bottom: solid 1px #bbb;

  @media print {
    font-family: georgia, times, serif;
    font-size: 10px;
  }
`;

const RegistrationCellNoWrap = styled.td`
  font-size: 14px;
  padding: 10px 6px;
  background-color: #fff;
  border-bottom: solid 1px #bbb;
  white-space: nowrap;

  @media print {
    font-family: georgia, times, serif;
    font-size: 10px;
  }
`;

const DelimiterCell = styled.td`
  font-size: 14px;
  font-weight: bold;
  padding: 20px 5px 20px 5px;
  background-color: #fff;
  border-bottom: solid 1px #bbb;

  @media print {
    font-family: georgia, times, serif;
    font-size: 10px;
  }
`;

type MeetupRegistrations = {
  Meetup: TMeetup;
  MeetupRegistrations: TRegistration[];
};

// const ParticipantsPrint: React.FC = () => {
export default function ParticipantsPrint() {
  const { meetupNumber: meetupNumberParam } = useParams<{ meetupNumber: string }>();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [error, setError] = useState<number | undefined>();
  const [meetup, setMeetup] = useState<TMeetup | undefined>();

  const [registrations, setRegistrations] = useState<TRegistration[] | undefined>();
  const [waiting, setWaiting] = useState<TRegistration[] | undefined>();
  const [cancelled, setCancelled] = useState<TRegistration[] | undefined>();

  const [organizer, setOrganizer] = useState<string | null>('');
  const [coOrganizers, setCoOrganizers] = useState<string | null>('');
  const [location, setLocation] = useState<string | null>('');
  const [participantCount, setParticipantCount] = useState<number | undefined>(0);

  async function getData<T>(url: string) {
    const response = await axios.get<T>(`${runtimeConfig.apiUrl}${url}`, {
      validateStatus: (status) => status < 1000,
    });

    return {
      status: response.status,
      data: response.status === 200 && response.data ? response.data : null,
    };
  }

  function nameComparer(a: TRegistration, b: TRegistration) {
    const FirstFullName = `${a.Contact.FirstName}${a.Contact.MiddleName}${a.Contact.LastName}`;
    const SecondFullName = `${b.Contact.FirstName}${b.Contact.MiddleName}${b.Contact.LastName}`;
    return FirstFullName.localeCompare(SecondFullName);
  }

  function exportCsv(): void {
    if (registrations != null && waiting != null && cancelled != null) {
      let allRegistrations: TRegistration[] = registrations;
      allRegistrations = allRegistrations.concat(waiting);
      allRegistrations = allRegistrations.concat(cancelled);
      exportToCsv('Deltagere - ' + meetupNumberParam + '.csv', meetup, allRegistrations);
    }
  }

  function createdOnComparer(a: TRegistration, b: TRegistration) {
    // compare dates
    if (a.CreatedOn && b.CreatedOn) {
      if (a.CreatedOn < b.CreatedOn) {
        return -1;
      }
      if (a.CreatedOn > b.CreatedOn) {
        return 1;
      }
      return 0;
    }
    return 0;
  }

  async function populateMeetupInfo(meetupNumber: string) {
    // fetch meetup with registrations
    const response = await getData<Partial<MeetupRegistrations>>(
      `meetups/${meetupNumber}/participants`,
    );

    if (response.status === 200 && response.data != null) {
      const meetup = response.data?.Meetup;
      const meetupRegistrations = response.data?.MeetupRegistrations;
      // store meetup
      setMeetup(meetup);

      // sort, count and store participants
      if (meetupRegistrations && meetupRegistrations?.length > 0) {
        // registered
        const registered = meetupRegistrations
          .filter((value) => value.RegistrationStatus === 'Registered')
          .sort(nameComparer);
        setRegistrations(registered);

        // cancelled
        setCancelled(
          meetupRegistrations
            .filter((value) => value.RegistrationStatus === 'Unsubscribed')
            .sort(nameComparer),
        );

        // waiting
        setWaiting(
          meetupRegistrations
            .filter((value) => value.RegistrationStatus === 'OnWaitingList')
            .sort(createdOnComparer),
        );

        // count registered participants
        let totalNumberOfParticipants = 0;
        if (registered) {
          totalNumberOfParticipants = registered.reduce(
            (sum, current) => sum + current.NumberOfParticipants,
            0,
          );
        }
        setParticipantCount(totalNumberOfParticipants);
      }

      // lookup organizer
      const organizerData = await getData<{ Name: string }>(
        `committees/${meetup?.MainOrganizerId}`,
      );
      setOrganizer(organizerData?.data?.Name ?? '');

      // lookup coorganizers
      const coOrganizerIds = meetup?.CoOrganizerIds;
      if (coOrganizerIds && coOrganizerIds.length > 0) {
        let coOrgs = '';
        for (let i = 0; i < coOrganizerIds.length; i += 1) {
          const coOrganizerId = coOrganizerIds[i];
          const coOrg = await getData<{ Name: string }>(`committees/${coOrganizerId}`);

          if (coOrg?.data?.Name != null && coOrg?.data?.Name !== '') {
            coOrgs += coOrg?.data?.Name;
            if (i < coOrganizerIds.length - 1) {
              coOrgs += ', ';
            }
          }
        }
        setCoOrganizers(coOrgs);
      }

      // lookup location
      const locationId = meetup?.LocationId;
      const locationData = await getData<{ Name: string }>(`meetuplocations/${locationId}`);
      setLocation(locationData?.data?.Name ?? '');

      // show page
      setPageLoaded(true);
    } else {
      setError(response.status);
    }
  }

  function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  const DateTimeFormat = (dtm: string | null | undefined) => {
    if (!dtm) return <div>-</div>;
    let s = '';
    try {
      s = capitalize(format(parseISO(dtm), 'eeee, d. MMMM yyyy - HH:mm', { locale: da }));
    } catch (error) {
      return <div>Ugyldig dato</div>;
    }
    return <div>{s}</div>;
  };

  const RegistrationStatus = (status: string) => {
    let label = formatRegistrationStatus(status);
    return <div>{label}</div>;
  };

  const RegistrationRowHeader = () => {
    return (
      <tr>
        <RegistrationHeader>Navn</RegistrationHeader>
        <RegistrationHeader>Titel</RegistrationHeader>
        <RegistrationHeader>Postnummer</RegistrationHeader>
        <RegistrationHeader>By</RegistrationHeader>
        <RegistrationHeader>Firma</RegistrationHeader>
        <RegistrationHeader>Telefon</RegistrationHeader>
        <RegistrationHeader>Mobil</RegistrationHeader>
        <RegistrationHeader>Email</RegistrationHeader>
        <RegistrationHeader>Status</RegistrationHeader>
        <RegistrationHeader>#</RegistrationHeader>
        <RegistrationHeader>Tilvalg</RegistrationHeader>
        <RegistrationHeader>Spørgsmål</RegistrationHeader>
        <RegistrationHeader>Tilmeldingstidspunkt</RegistrationHeader>
        <RegistrationHeader>Afmeldinstidspunkt</RegistrationHeader>
      </tr>
    );
  };

  const RegistrationOptions = (option: TRegistrationOption) => <div>{formatOption(option)}</div>;

  const ParticipantRowCell = (registration: TRegistration) => (
    <tr>
      <RegistrationCell>{formatName(registration.Contact)}</RegistrationCell>
      <RegistrationCell>{registration.Contact.Title}</RegistrationCell>
      <RegistrationCell>{registration.Contact.Zip}</RegistrationCell>
      <RegistrationCell>{registration.Contact.City}</RegistrationCell>
      <RegistrationCell>{registration.CompanyName}</RegistrationCell>
      <RegistrationCellNoWrap>{formatPhone(registration.Contact.Telephone)}</RegistrationCellNoWrap>
      <RegistrationCellNoWrap>{formatPhone(registration.Contact.Mobile)}</RegistrationCellNoWrap>
      <RegistrationCell>{registration.Contact.Email}</RegistrationCell>
      <RegistrationCell>{RegistrationStatus(registration.RegistrationStatus)}</RegistrationCell>
      <RegistrationCell>{registration.NumberOfParticipants}</RegistrationCell>
      <RegistrationCell>
        {registration.Options && registration.Options.map(RegistrationOptions)}
      </RegistrationCell>
      <RegistrationCell>{registration.AnswersForQuestion}</RegistrationCell>
      <RegistrationCell>{formatDate(registration.CreatedOn)}</RegistrationCell>
      <RegistrationCell>
        {registration.UnregisteredAt ? formatDate(registration.UnregisteredAt) : '-'}
      </RegistrationCell>
    </tr>
  );

  useEffect(() => {
    if (meetupNumberParam) {
      populateMeetupInfo(meetupNumberParam);
    }
  }, [meetupNumberParam]);

  return (
    <>
      <GlobalStyle />
      {!pageLoaded && !error && <Loader />}
      {error &&
        (error === 404 ? (
          <ContentDiv>
            <MeetupTitle>Deltagerliste</MeetupTitle>
            <InfoTextDiv>
              Deltagerlisten til arrangementet kan ikke længere tilgås, da der er gået mere end 30
              dage siden arrangementet blev afholdt. Du kan henvende dig til en koordinator, hvis du
              har behov for at se listen.
            </InfoTextDiv>
          </ContentDiv>
        ) : error === 403 ? (
          <ContentDiv>
            <MeetupTitle>Deltagerliste</MeetupTitle>
            <InfoTextDiv>
              Du har ikke adgang til deltagerlisten for dette arrangementet. Hvis du mener det er en
              fejl kan du henvende dig til en koordinator.
            </InfoTextDiv>
          </ContentDiv>
        ) : (
          <ContentDiv>
            <MeetupTitle>Deltagerliste</MeetupTitle>
            <InfoTextDiv>
              Der skete en ukendt fejl ({error}). Prøv igen senere eller kontakt en koordinator.
            </InfoTextDiv>
          </ContentDiv>
        ))}
      {pageLoaded && (
        <ContentDiv>
          <MeetupTableCenter>
            <MeetupTable>
              <TheadTable>
                <tr>
                  <td colSpan={3}>
                    <MeetupTitle>{meetup?.Title}</MeetupTitle>
                  </td>
                </tr>
                <tr>
                  <MeetupHeader>Arrangementsnummer</MeetupHeader>
                  <MeetupHeader>Arrangør</MeetupHeader>
                  <MeetupHeader>Deltagerantal</MeetupHeader>
                </tr>
                <tr>
                  <MeetupTextCell>{meetup?.MeetupNumber}</MeetupTextCell>
                  <MeetupTextCell>{organizer}</MeetupTextCell>
                  <MeetupTextCell>{participantCount}</MeetupTextCell>
                </tr>
                <tr>
                  <MeetupHeader>Arrangementssted</MeetupHeader>
                  <MeetupHeader>Medarrangører</MeetupHeader>
                  <MeetupHeader>Afholdes</MeetupHeader>
                </tr>
                <tr>
                  <MeetupTextCell>{location}</MeetupTextCell>
                  <MeetupTextCell>{coOrganizers}</MeetupTextCell>
                  <MeetupTextCell>{DateTimeFormat(meetup?.StartTime?.toString())}</MeetupTextCell>
                </tr>
                <tr>
                  <MeetupHeader>Supplerende information</MeetupHeader>
                  <MeetupHeader />
                  <MeetupHeader>Udskrevet</MeetupHeader>
                </tr>
                <tr>
                  <MeetupTextCell>{meetup?.LocationAdditionalInfo}</MeetupTextCell>
                  <td />
                  <MeetupTextCell>{DateTimeFormat(new Date().toISOString())}</MeetupTextCell>
                </tr>
              </TheadTable>
            </MeetupTable>
            <Button
              id="btnUpdate"
              onClick={() => exportCsv()}
              disabled={false}
              cypress="navigateUpdate"
              text="Eksporter"
            />
          </MeetupTableCenter>
          <InfoTextDiv>
            Den interne deltagerliste må som udgangspunkt ikke deles med andre, ligesom det frarådes
            at den udskrives. Beskyt gerne listen med en kode, hvis den mailes. Deltagerlisten skal
            slettes efter brug, såfremt den gemmes. Hvis det oplyses specifikt ved tilmeldingen, at
            kontaktoplysninger som mail, mobil og lignende videregives til navngivne
            samarbejdspartnere eller arrangøren, må deltagerlisten deles med dem.
          </InfoTextDiv>
          <RegistrationTable>
            <TheadTable>
              <RegistrationRowHeader />
            </TheadTable>
            <tbody>
              {registrations && registrations.map(ParticipantRowCell)}
              {cancelled && cancelled.length > 0 && (
                <>
                  <tr>
                    <DelimiterCell colSpan={14}>Afmeldte</DelimiterCell>
                  </tr>
                  {cancelled && cancelled.map(ParticipantRowCell)}
                </>
              )}
              {waiting && waiting.length > 0 && (
                <>
                  <tr>
                    <DelimiterCell colSpan={14}>
                      Venteliste (sorteret efter tilmeldingstidspunkt)
                    </DelimiterCell>
                  </tr>
                  {waiting && waiting.map(ParticipantRowCell)}
                </>
              )}
            </tbody>
          </RegistrationTable>
        </ContentDiv>
      )}
    </>
  );
}
