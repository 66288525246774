import { Action } from 'redux';

export const SET_IS_LOADING = 'setIsLoading';
export const SET_FILTER_CREATED_BY = 'setFilterCreatedBy';
export const SET_CURRENT_STEP = 'setCurrentStep';
export const SET_CURRENT_STEP_VALID = 'setCurrentStepValid';
export const SET_STEP = 'setStep';
export const SET_STEPS = 'setSteps';
export const SET_MEETUP_SAVED = 'setMeetupSaved';
export const SET_LANGUAGE = 'setLanguage';
export const SET_NO_CPR_CHECKED = 'setNoCprChecked';
export const SET_FIELD_VALIDATION = 'setFieldValidation';
export const SET_FIELD_VALIDATIONS = 'setFieldValidations';
export const SET_ERROR = 'setError';
export const SET_SHOW_HEADER = 'setShowHeader';
export const SET_SHOW_FOOTER = 'setShowFooter';

export type TUiState = {
  isLoading: boolean;
  filterCreatedBy: TFilterCreatedBy;
  currentStep: TStepNumber;
  currentStepValid: boolean;
  steps: TStepsState;
  meetupSaved: boolean;
  language: 'da' | 'en';
  // Result of every field validation including any error message and which step number the field
  // is used in. Validation of entire step can be done by checking all valid states for a
  // particular step unless excludeInValidation is true.
  fieldValidation: TFieldValidationState;
  // Represents a general application error.
  error: TError;
  showHeader: boolean;
  showFooter: boolean;
};

export type TStepsState = {
  [key: number]: {
    state: TStepState;
  };
};

export type TFieldValidationState = {
  [step: number]: {
    [field: string]: Partial<{
      valid: boolean;
      message: string;
      showMessage: boolean;
      excludeInValidation: boolean;
    }>;
  };
};

export type TFieldValidation = {
  step: TStepNumber;
  field: string;
  valid: boolean;
  message: string;
  showMessage: boolean;
  excludeInValidation: boolean;
};

export type TFieldValidationResponse = {
  ErrorMessage: string;
  PropertyName: string;
};

export type TLanguage = 'da' | 'en';

export type TFilterCreatedBy = 'CreatedByMe' | 'CreatedByCommittee';

export type TStepState = 'completed' | 'active' | 'unreached' | 'invalid';

export type TStepNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type TStep = {
  key: number;
  state: TStepState;
};

export type TError = {
  errorMessage: string,
  errorType: 'MeetupLocationDoesNotExist' | 'GenericError' | string
}

export interface SetIsLoadingAction extends Action {
  type: typeof SET_IS_LOADING;
  payload: boolean;
}

export interface SetFilterCreatedByAction extends Action {
  type: typeof SET_FILTER_CREATED_BY;
  payload: TFilterCreatedBy;
}

export interface SetCurrentStepAction extends Action {
  type: typeof SET_CURRENT_STEP;
  payload: TStepNumber;
}

export interface SetCurrentStepValidAction extends Action {
  type: typeof SET_CURRENT_STEP_VALID;
  payload: boolean;
}

export interface SetStepAction extends Action {
  type: typeof SET_STEP;
  payload: TStep;
}

export interface SetStepsAction extends Action {
  type: typeof SET_STEPS;
  payload: TStepsState;
}

export interface SetMeetupSavedAction extends Action {
  type: typeof SET_MEETUP_SAVED;
  payload: boolean;
}

export interface SetLanguageAction extends Action {
  type: typeof SET_LANGUAGE;
  payload: TLanguage;
}

export interface SetFieldValidationAction extends Action {
  type: typeof SET_FIELD_VALIDATION;
  payload: Partial<TFieldValidation>;
}

export interface SetFieldValidationsAction extends Action {
  type: typeof SET_FIELD_VALIDATIONS;
  payload: TFieldValidationState;
}

export interface SetErrorAction extends Action {
  type: typeof SET_ERROR;
  payload: TError;
}

export interface SetShowHeaderAction extends Action {
  type: typeof SET_SHOW_HEADER;
  payload: boolean;
}

export interface SetShowFooterAction extends Action {
  type: typeof SET_SHOW_FOOTER;
  payload: boolean;
}

export type UiAction =
  | SetIsLoadingAction
  | SetFilterCreatedByAction
  | SetCurrentStepAction
  | SetCurrentStepValidAction
  | SetStepAction
  | SetStepsAction
  | SetMeetupSavedAction
  | SetLanguageAction
  | SetFieldValidationAction
  | SetFieldValidationsAction
  | SetErrorAction
  | SetShowHeaderAction
  | SetShowFooterAction;
