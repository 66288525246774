import { parseISO } from 'date-fns';
import * as R from 'ramda';
import { TParticipantPrice, TMeetup } from './types';

export const mapMeetupResponse = (meetup: Partial<TMeetup>): Partial<TMeetup> => {
  const meetupClone = R.clone(meetup);

  meetupClone.StartTime = meetup.StartTime ? parseISO(meetup.StartTime.toString()) : null;
  meetupClone.EndTime = meetup.EndTime ? parseISO(meetup.EndTime.toString()) : null;
  meetupClone.RegistrationDeadline = meetup.RegistrationDeadline
    ? parseISO(meetup.RegistrationDeadline.toString())
    : null;
  meetupClone.CancellationDeadline = meetup.CancellationDeadline
    ? parseISO(meetup.CancellationDeadline.toString())
    : null;
  meetupClone.PublicationTime = meetup.PublicationTime
    ? parseISO(meetup.PublicationTime.toString())
    : null;
  meetupClone.ParticipantPrices = meetup.ParticipantPrices?.map(
    (participantPrice: Partial<TParticipantPrice>) => {
      return { ...participantPrice, Active: true };
    },
  );
  meetupClone.SeatsOccupied = meetup.SeatsAvailable ? (meetup.SeatsQuantity ?? 0) - meetup.SeatsAvailable : 0;
  return meetupClone;
};

export const mapMeetupRequest = (meetup: Partial<TMeetup>): Partial<TMeetup> => {
  const meetupClone = R.clone(meetup);

  // Delete internal props before sending request.
  delete meetupClone.IsDraft;
  delete meetupClone.IsCopy;

  // Post only the participant prices user has selected.
  meetupClone.ParticipantPrices = meetup.ParticipantPrices?.map(
    (participantPrice: Partial<TParticipantPrice>) => {
      return participantPrice.Active
        ? {
            ParticipantTypeId: participantPrice.ParticipantTypeId,
            Price: participantPrice.Price,
            MaxQuantity: participantPrice.MaxQuantity,
            ExpectedQuantity: participantPrice.ExpectedQuantity,
          }
        : {};
    },
  ).filter((value: Partial<TParticipantPrice>) => value.ParticipantTypeId);

  return meetupClone;
};
