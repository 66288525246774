import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Group, Heading, Paper, Paragraph, TextField } from '@ingeniorforeningen/figurine-core';
import { TState } from '../../store/rootReducer';
import { FieldsBudget } from '../../helpers/fields';
import * as meetupActions from '../../store/meetups/actions';
import { TBudget, TParticipantPrice } from '../../store/meetups/types';

const LinesIncome = [
  { text: 'Deltagerbetaling', fieldName: FieldsBudget.ParticipantPayment },
  { text: 'Andre indtægter', fieldName: FieldsBudget.OtherIncome },
];
const LinesExpense = [
  { text: 'Rejseudgifter', fieldName: FieldsBudget.TravelExpenses },
  { text: 'Mødeudgifter', fieldName: FieldsBudget.MeetingExpenses },
  { text: 'Foredragsholder', fieldName: FieldsBudget.Speaker },
  { text: 'Forplejning', fieldName: FieldsBudget.Catering },
  { text: 'Billetudgifter', fieldName: FieldsBudget.TicketExpenses },
  { text: 'Andre udgifter', fieldName: FieldsBudget.OtherExpenses },
];

type Props = {
  participantTypesChanges: Partial<TParticipantPrice>[] | undefined;
};

export default function MeetupEditBudget({ participantTypesChanges }: Props) {
  const dispatch = useDispatch();
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const [totalBudget, setTotalBudget] = useState<number>();
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [expensePerParticipant, setExpensePerParticipant] = useState<{ [key: string]: string }>({});

  // Recalculate grand totals.
  const calculateTotals = (
    budget: Partial<TBudget>,
  ): { totalIncome: number; totalExpenses: number; totalBudget: number } => {
    const totalIncome = Number(budget.ParticipantPayment) + Number(budget.OtherIncome);
    const totalExpenses =
      Number(budget.Catering) +
      Number(budget.MeetingExpenses) +
      Number(budget.OtherExpenses) +
      Number(budget.Speaker) +
      Number(budget.TicketExpenses) +
      Number(budget.TravelExpenses);

    return { totalIncome, totalExpenses, totalBudget: totalIncome - totalExpenses };
  };

  const calculateParticipantIncome = (participantPrices: Partial<TParticipantPrice>[]): number => {
    let totalParticipantIncome = 0;

    participantPrices.forEach((participantPrice: Partial<TParticipantPrice>) => {
      if (participantPrice.Active && participantPrice.Price && participantPrice.ExpectedQuantity) {
        totalParticipantIncome += participantPrice.Price * participantPrice.ExpectedQuantity;
      }
    });

    return totalParticipantIncome;
  };

  const calculateParticipantCount = (participantPrices: Partial<TParticipantPrice>[]): number =>
    participantPrices
      ?.map((value: Partial<TParticipantPrice>) => value.ExpectedQuantity || 0)
      .reduce((prevValue: number, currentValue: number) => prevValue + currentValue, 0) || 0;

  // When user change numbers => save and recalculate totals.
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? Number(e.target.value) : null;
    if (value === null) {
      return;
    }
    updateBudget(e.target.name, value);
  };

  const updateBudget = (name: string, value: number) => {
    let budget: Partial<TBudget> = { ...meetup.Budget, [name]: value };
    // Update totals.
    const { totalIncome, totalExpenses, totalBudget: totalBudgetCalc } = calculateTotals(budget);
    budget = { ...budget, TotalIncome: totalIncome, TotalExpenses: totalExpenses };
    setTotalBudget(totalBudgetCalc);

    dispatch(meetupActions.setMeetup({ ...meetup, Budget: budget }));
  };

  // When user changes expense per participant, calculate expense by multiplying with
  // total number of participants.
  const onInputChangePerParticipant = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpensePerParticipant({
      ...expensePerParticipant,
      [e.target.name]: e.target.value as string,
    });
    const value = e.target.value ? Number(e.target.value) : null;
    if (value === null) {
      return;
    }
    updateBudget(e.target.name, value * totalParticipants);
  };

  useEffect(() => {
    if (meetup?.Budget) {
      const { totalBudget: totalBudgetCalc } = calculateTotals(meetup.Budget);
      setTotalBudget(totalBudgetCalc);
      setTotalParticipants(calculateParticipantCount(meetup.ParticipantPrices || []));
    }
  }, [meetup]);

  // When user changes participant types/prices outside this component recalculate
  // the budget income and expenses automatically.
  useEffect(() => {
    if (participantTypesChanges) {
      let budget = { ...meetup.Budget };

      // Recount number of total participants.
      const participantCount = calculateParticipantCount(participantTypesChanges || []);
      setTotalParticipants(participantCount);

      // Recalculate each expense if user has entered an expense per participant.
      Object.keys(expensePerParticipant).forEach((key: string) => {
        if (Number(expensePerParticipant[key]) > 0) {
          budget = { ...budget, [key]: participantCount * Number(expensePerParticipant[key]) };
        }
      });

      // Update participant income.
      const participantPayment = calculateParticipantIncome(participantTypesChanges || []);
      budget = {
        ...budget,
        ParticipantPayment: participantPayment === 0 ? null : participantPayment,
      };

      // Update totals.
      const { totalIncome, totalExpenses, totalBudget: totalBudgetCalc } = calculateTotals(budget);
      budget = { ...budget, TotalIncome: totalIncome, TotalExpenses: totalExpenses };
      setTotalBudget(totalBudgetCalc);

      dispatch(meetupActions.setMeetup({ ...meetup, Budget: budget }));
    }
  }, [participantTypesChanges]);

  return (
    <Paper p="xl">
      <Heading type="h2" text="Budget" mb="xl" />

      <Paper style={{ background: '#f7f7f7', boxShadow: 'none' }} p="xl">
        <Paragraph strong text="Indtægter" />

        <Group direction="column">
          {LinesIncome.map((income: { text: string; fieldName: FieldsBudget }) => (
            <Group key={`LineIncome${income.fieldName}`} grow align="center">
              <Paragraph text={income.text} />

              <TextField
                type="number"
                name={income.fieldName}
                label={'I alt kr.'}
                placeholder=""
                onChange={onInputChange}
                value={
                  meetup.Budget?.[income.fieldName] || meetup.Budget?.[income.fieldName] === 0
                    ? meetup.Budget?.[income.fieldName]?.toString()
                    : ''
                }
                showOptionalInLabel={false}
                variant="outline"
                cypress={income.fieldName}
              />
            </Group>
          ))}
        </Group>
        <Group grow justify="space-between" mt="md">
          <Paragraph strong text="Indtægter i alt" />
          <Paragraph
            strong
            text={`${meetup.Budget?.TotalIncome || '0'} kr.`}
            align="right"
            cypress="TotalIncome"
          />
        </Group>
      </Paper>

      {/* Expense lines */}
      <Paper style={{ background: '#f7f7f7', boxShadow: 'none' }} p="xl" mt="xl">
        <Paragraph strong text="Udgifter" />

        <Group direction="column">
          {LinesExpense.map((expense: { text: string; fieldName: FieldsBudget }) => (
            <Group key={expense.text} grow align="center">
              <Paragraph text={expense.text} />

              <TextField
                type="number"
                name={expense.fieldName}
                label="Per deltager"
                placeholder=""
                onChange={onInputChangePerParticipant}
                value={expensePerParticipant[expense.fieldName]}
                variant="outline"
                cypress={expense.fieldName}
              />

              <TextField
                type="number"
                name={expense.fieldName}
                label="I alt kr."
                placeholder=""
                onChange={onInputChange}
                value={meetup.Budget?.[expense.fieldName]?.toString()}
                showOptionalInLabel={false}
                variant="outline"
                cypress={`Total${expense.fieldName}`}
              />
            </Group>
          ))}
        </Group>
        <Group grow justify="space-between" mt="md">
          <Paragraph strong text="Indtægter i alt" />
          <Paragraph
            strong
            text={`${meetup.Budget?.TotalExpenses || '0'} kr.`}
            align="right"
            cypress="TotalExpenses"
          />
        </Group>
      </Paper>

      <Group grow p="xl">
        <Paragraph strong text="Samlet budget" />
        <Paragraph
          strong
          align="right"
          text={`${totalBudget || 0} kr.`}
          variant={(totalBudget || 0) < 0 ? 'error' : 'dark'}
          cypress="TotalBudget"
        />
      </Group>
    </Paper>
  );
}
