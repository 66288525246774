import { Footnote, Group, Paper, Paragraph, createStyles } from '@ingeniorforeningen/figurine-core';
import { useEffect, useState } from 'react';
import { Select } from './Select';

// prettier-ignore
const HOURS = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

const toTwoDigit = (value: string) => {
  return value.length === 1 ? `0${value}` : value;
};

const checkIfValid = (value: string, arrary: string[]) => {
  return arrary.includes(value);
};
const roundToNearestFive = (value: string) => {
  const num = parseInt(value);
  const rounded = Math.round(num / 5) * 5;
  return Math.min(rounded, 55).toString();
};

const styles = createStyles((theme, { error }: { error: boolean }) => ({
  wrapper: {
    paddingTop: '5px!important',
    paddingBottom: '5px!important',
    flex: 0,
    maxHeight: 55,
    border: error ? `1px solid ${theme.other.colorTokens.feedback.error}` : 'unset',
  },
}));

type Props = {
  name: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

export const TimePicker = ({ name, value, onChange, error }: Props) => {
  const [time, setTime] = useState<string>('');

  const onInputChange = ({ hours, minutes }: { hours?: string; minutes?: string }) => {
    let newTime = time;
    let isValid = false;
    const [hh, mm] = time.split(':');
    if (minutes) {
      const roundedValue = roundToNearestFive(minutes);
      const newMinutes = toTwoDigit(roundedValue);
      isValid = checkIfValid(newMinutes, MINUTES);
      newTime = `${hh}:${newMinutes}`;
    }
    if (hours) {
      const newHours = Number(hours) < 1 || Number(hours) > 23 ? '00' : toTwoDigit(hours);
      isValid = checkIfValid(newHours, HOURS);
      newTime = `${newHours}:${mm}`;
    }
    if (isValid) {
      setTime(newTime);
      onChange(newTime);
    }
  };

  useEffect(() => {
    setTime(value);
  }, [value]);

  const { classes } = styles({ error: !!error });
  const [hh, mm] = time.split(':');
  return (
    <Paper className={classes.wrapper}>
      <Footnote text="Tidspunkt" />
      <Group noWrap align="center">
        <Select
          name={`${name}-hours`}
          error={!!error}
          options={HOURS}
          value={hh}
          onChange={(value) => onInputChange({ hours: value })}
        />
        <Paragraph text=":" />
        <Select
          name={`${name}-minutes`}
          error={!!error}
          options={MINUTES}
          value={mm}
          onChange={(value) => onInputChange({ minutes: value })}
        />
      </Group>
    </Paper>
  );
};
