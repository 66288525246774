import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Meetups from './components/Meetups/Meetups';
import MeetupEdit from './components/Meetups/MeetupEdit';
import ParticipantsPrint from './components/Meetups/ParticipantsPrint';
import { AuthProvider } from './components/Authentication/AuthProvider';
import PrivateRoute from './components/Authentication/PrivateRoute';
import LoginCallback from './components/Authentication/LoginCallback';
import LogoutCallback from './components/Authentication/LogoutCallback';
import SilentRenew from './components/Authentication/SilentRenew';
import Logout from './components/Authentication/Logout';
import MeetupEditSpeakers from './components/Meetups/MeetupEditSpeakers';
import NotFound from './NotFound';
import Forbidden from './components/Authentication/Forbidden';
import IdaMeetupRedirect from './IdaMeetupRedirect';
import MyIdaRedirect from './MyIdaRedirect';
import MeetupQrCodes from './components/Meetups/MeetupQrCodes';
import store from './store/store';
import AppWrapper from './components/Common/AppWrapper';

export default function App() {
  return (
    <Router>
      <Provider store={store}>
        <AuthProvider
          login={{
            client_id: runtimeConfig.clientId || '',
            authority: runtimeConfig.authority || '',
          }}
        >
          <Routes>
            <Route element={<AppWrapper />}>
              <Route path="/redirect/event/:id" element={<IdaMeetupRedirect />} />
              <Route path="/redirect/my-ida" element={<MyIdaRedirect />} />
              <Route path="/" element={<PrivateRoute children={<Meetups />} />} />
              <Route
                path="/arrangementer/opret"
                element={<PrivateRoute children={<MeetupEdit />} />}
              />

              <Route
                path="/arrangementer/:meetupNumber/foredragsholdere"
                element={<PrivateRoute children={<MeetupEditSpeakers />} />}
              />
              <Route
                path="/arrangementer/:meetupNumber/qr"
                element={<PrivateRoute children={<MeetupQrCodes />} />}
              />
              <Route
                path="/arrangementer/:meetupNumber"
                element={<PrivateRoute children={<MeetupEdit />} />}
              />

              <Route path="/Forbidden" element={<Forbidden />} />
              <Route path="*" element={<NotFound />} />

              {/* Login related pages */}
              <Route path="/signin-oidc" element={<LoginCallback />} />
              <Route path="/signout-callback-oidc" element={<LogoutCallback />} />
              <Route path="/silent-renew-oidc" element={<SilentRenew />} />
              <Route path="/logout" element={<PrivateRoute children={<Logout />} />} />
            </Route>
            <Route element={<AppWrapper clean />}>
              <Route
                path="/arrangementer/:meetupNumber/deltagerliste"
                element={<PrivateRoute children={<ParticipantsPrint />} />}
              />
            </Route>
          </Routes>
        </AuthProvider>
      </Provider>
    </Router>
  );
}
