import { Grid, Group } from '@ingeniorforeningen/figurine-core';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const sustainableDevelopmentGoals = [
  {
    label: 'Afskaf Fattigdom',
    value: 1,
  },
  {
    label: 'Stop Sult',
    value: 2,
  },
  {
    label: 'Sundhed og Trivsel',
    value: 3,
  },
  {
    label: 'Kvalitetsuddannelse',
    value: 4,
  },
  {
    label: 'Ligestilling Mellem Kønnene',
    value: 5,
  },
  {
    label: 'Rent Vand og Sanitet',
    value: 6,
  },
  {
    label: 'Bæredygtig Energi',
    value: 7,
  },
  {
    label: 'Anstændige Jobs og Økonomisk Vækst',
    value: 8,
  },
  {
    label: 'Industri, Innovation og Infrastruktur',
    value: 9,
  },
  {
    label: 'Mindre Ulighed',
    value: 10,
  },
  {
    label: 'Bæredygtige Byer og Lokalsamfund',
    value: 11,
  },
  {
    label: 'Ansvarligt Forbrug og Produktion',
    value: 12,
  },
  {
    label: 'Klimaindsats',
    value: 13,
  },
  {
    label: 'Livet i Havet',
    value: 14,
  },
  {
    label: 'Livet på Land',
    value: 15,
  },
  {
    label: 'Fred, Retfærdighed og Stærke Institutioner',
    value: 16,
  },
  {
    label: 'Partnerskaber for Handling',
    value: 17,
  },
];

const StyledGoal = styled.button<{ isSelected: boolean; disabled: boolean }>`
  width: 15%;
  position: relative;
  padding: 2px;
  :hover {
    transform: scale(1.05);
    transition-duration: 0.1s;
  }

  input[type='image'] {
    width: 100%;
    height: 100%;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
  background-color: transparent;
  border-color: ${(props) => (props.isSelected ? 'hsl(223,86%,43%)' : 'transparent')};
  border-radius: 5px;
  border-width: 4px;
  border-style: dashed;
`;

type Props = {
  onSelect: (selected: number[]) => void;
  initialGoals?: number[];
};

const MAX_COUNT = 3;

export default function MeetupEditSustainableDevelopmentGoals({ onSelect, initialGoals }: Props) {
  const [selectedGoals, setSelectedGoals] = useState<number[]>(initialGoals || []);
  useEffect(() => {
    onSelect(selectedGoals);
  }, [selectedGoals]);

  const setGoals = (val: number) => {
    if (selectedGoals.includes(val)) {
      setSelectedGoals(selectedGoals.filter((goal) => goal !== val));
    } else {
      setSelectedGoals([...selectedGoals, val]);
    }
  };

  const isDisabled = (goal: number) => {
    return !selectedGoals.includes(goal) && selectedGoals.length >= MAX_COUNT;
  };

  return (
    <Group>
      {sustainableDevelopmentGoals?.map((goal) => {
        return (
          <StyledGoal
            key={`goal-${goal.value}`}
            isSelected={selectedGoals.includes(goal.value)}
            disabled={isDisabled(goal.value)}
            onClick={() => setGoals(goal.value)}
            data-cypress={`SustainableDevelopmentGoal${goal.value}`}
          >
            <input
              type="image"
              alt={goal.label}
              src={`https://www.verdensmaalene.dk/sites/all/themes/gavias_educar/images/${goal.value}.gif`}
            />
          </StyledGoal>
        );
      })}
    </Group>
  );
}
