export enum FieldsDescription {
  Title = 'Title',
  Teaser = 'Teaser',
  Description = 'Description',
  IsRelevantForInternationals = 'IsRelevantForInternationals',
}

export enum FieldsDates {
  StartTime = 'StartTime',
  EndTime = 'EndTime',
  PublicationTime = 'PublicationTime',
  RegistrationDeadline = 'RegistrationDeadline',
  CancellationDeadline = 'CancellationDeadline',
}

export enum FieldsLocation {
  LocationId = 'LocationId',
  LocationAdditionalInfo = 'LocationAdditionalInfo',
  Region = 'Region',
}

export enum FieldSustainableDevelopmentGoals {
  SustainableDevelopmentGoals = 'SustainableDevelopmentGoals',
}

export enum FieldsOrganizers {
  MainOrganizerId = 'MainOrganizerId',
  CoOrganizerIds = 'CoOrganizerIds',
  MeetupTypeId = 'MeetupTypeId',
}

export enum FieldsLocationNew {
  Id = 'Id',
  Name = 'Name',
  Address1 = 'Address1',
  Zip = 'Zip',
  City = 'City',
}

export enum FieldsOptionEdit {
  Type = 'Type',
  Name = 'Name',
  Price = 'Price',
  MaxQuantity = 'MaxQuantity',
  MaxForSignup = 'MaxForSignup',
}

export enum FieldsSpeakerEdit {
  IdaNumber = 'IdaNumber',
  Name = 'Name',
  Title = 'Title',
  Email = 'Email',
  MobilePhone = 'MobilePhone',
}

export enum FieldsParticipantPrices {
  Active = 'Active',
  Price = 'Price',
  MaxQuantity = 'MaxQuantity',
  ExpectedQuantity = 'ExpectedQuantity',
}

export enum FieldsParticipants {
  SeatsRequired = 'SeatsRequired',
  SeatsQuantity = 'SeatsQuantity',
  ParticipantQuestion = 'ParticipantQuestion',
}

export enum FieldsBudget {
  ParticipantPayment = 'ParticipantPayment',
  OtherIncome = 'OtherIncome',
  TravelExpenses = 'TravelExpenses',
  MeetingExpenses = 'MeetingExpenses',
  Speaker = 'Speaker',
  Catering = 'Catering',
  TicketExpenses = 'TicketExpenses',
  OtherExpenses = 'OtherExpenses',
  TotalIncome = 'TotalIncome',
  TotalExpenses = 'TotalExpenses',
  TotalBudget = 'TotalBudget',
}
