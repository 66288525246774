import axios from 'axios';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function IdaMeetupRedirect() {
  const { id } = useParams<{ id: string }>();

  const getMeetup = async (guid: string) => {
    const response = await axios.get(`${runtimeConfig.apiUrl}meetups/${guid}`);

    if (response.data) {
      window.location.href = `${runtimeConfig.meetupRedirectUrl}${response.data}`;
    }
  };

  useEffect(() => {
    if (id) {
      getMeetup(id);
    }
  }, [id]);

  return <></>;
}
