import { TinyText } from '../Common/CustomStyling';

type SeatingInfoProps = {
  seatsAvailable?: number | null;
  seatsQuantity?: number | null;
  onWaitingList?: number;
  participants?: number;
};

const MeetupSeatingInfo: React.FC<SeatingInfoProps> = ({
  seatsAvailable,
  seatsQuantity,
  onWaitingList,
  participants,
}) => {
  const fullDescription = (
    <>
      <strong>{participants}</strong> tilmeldte ud af <strong>{seatsQuantity ?? 0}</strong> pladser,{' '}
      <strong>{onWaitingList ?? 0}</strong> på venteliste
    </>
  );
  const shortDescription = (
    <>
      <strong>{participants}</strong> tilmeldte ud af <strong>{seatsQuantity ?? 0}</strong> pladser
    </>
  );

  if (seatsAvailable == null || seatsQuantity === 0) {
    return <TinyText>Udfyld "maksimum antal deltagere" for at få vist tilmeldingsstatus.</TinyText>;
  }

  return (
    <TinyText>{onWaitingList && onWaitingList > 0 ? fullDescription : shortDescription}</TinyText>
  );
};

export default MeetupSeatingInfo;
