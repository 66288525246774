import { useDispatch, useSelector } from 'react-redux';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import getErrorMessage from '../../helpers/errorMessage';
import { FieldsParticipants } from '../../helpers/fields';
import useFieldEvent from '../../hooks/useFieldEvent';
import { Group, TextField } from '@ingeniorforeningen/figurine-core';
import { TMeetup } from '../../store/meetups/types';
import * as uiActions from '../../store/ui/actions';

const STEP_NUMBER = 5;

export default function MeetupEditParticipants() {
  const language = useSelector((state: TState) => state.ui.language);
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const fieldValidationState = useSelector((state: TState) => state.ui.fieldValidation);
  const { onInputChange, onInputBlur } = useFieldEvent(STEP_NUMBER);
  const dispatch = useDispatch();

  // Add additional onBlur logic because the two number fields are dependant on each other.
  const handleInputBlur = (
    e:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      e.currentTarget.name === FieldsParticipants.SeatsRequired ||
      e.currentTarget.name === FieldsParticipants.SeatsQuantity
    ) {
      dispatch(
        uiActions.updateFieldValidation({
          step: STEP_NUMBER,
          fields: [FieldsParticipants.SeatsRequired],
          meetup: {
            ...meetup,
            [FieldsParticipants.SeatsRequired as keyof TMeetup]: e.currentTarget.value,
          },
          showMessage: true,
        }),
      );
      dispatch(
        uiActions.updateFieldValidation({
          step: STEP_NUMBER,
          fields: [FieldsParticipants.SeatsQuantity],
          meetup: {
            ...meetup,
            [FieldsParticipants.SeatsQuantity as keyof TMeetup]: e.currentTarget.value,
          },
          showMessage: true,
        }),
      );
    } else {
      onInputBlur(e);
    }
  };

  return (
    <Group direction="column" spacing="xl" mt="xl">
      <TextField
        type="number"
        name={FieldsParticipants.SeatsRequired}
        label={texts[language].seatsRequired.label}
        placeholder={texts[language].seatsRequired.placeholder}
        description={texts[language].seatsRequired.helpText}
        onChange={onInputChange}
        onBlur={handleInputBlur}
        value={meetup.SeatsRequired ? meetup.SeatsRequired.toString() : ''}
        error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsParticipants.SeatsRequired)}
        min={0}
        maxLength={9}
        cypress="seatsRequired"
      />

      <TextField
        type="number"
        name={FieldsParticipants.SeatsQuantity}
        label={texts[language].seatsQuantity.label}
        placeholder={texts[language].seatsQuantity.placeholder}
        description={texts[language].seatsQuantity.helpText}
        onChange={onInputChange}
        onBlur={handleInputBlur}
        value={meetup.SeatsQuantity ? meetup.SeatsQuantity.toString() : ''}
        error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsParticipants.SeatsQuantity)}
        min={1}
        maxLength={9}
        cypress="seatsQuantity"
        required
      />

      <TextField
        name={FieldsParticipants.ParticipantQuestion}
        label={texts[language].participantQuestion.label}
        placeholder={texts[language].participantQuestion.placeholder}
        description={texts[language].participantQuestion.helpText}
        onChange={onInputChange}
        onBlur={onInputBlur}
        value={meetup.ParticipantQuestion}
        error={getErrorMessage(
          fieldValidationState,
          STEP_NUMBER,
          FieldsParticipants.ParticipantQuestion,
        )}
        cypress="participantQuestion"
      />
    </Group>
  );
}
