import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import { FieldsOptionEdit } from '../../helpers/fields';
import { MeetupOptionType, TMeetupOption } from '../../store/meetups/types';
import { TFieldValidationResponse } from '../../store/ui/types';
import {
  Alert,
  Button,
  Group,
  Modal,
  SegmentedControl,
  TextField,
} from '@ingeniorforeningen/figurine-core';

type TMeetupOptionEdit = {
  Id: string;
  Type: string;
  Name: string;
  Price: string;
  MaxQuantity: string | null;
  MaxForSignup: string | null;
};

const defaultValidation = {
  Name: { valid: false, errorMessage: '' },
  Price: { valid: false, errorMessage: '' },
  MaxQuantity: { valid: false, errorMessage: '' },
  MaxForSignup: { valid: false, errorMessage: '' },
};

type Props = {
  show: boolean;
  option?: Partial<TMeetupOption>;
  onClose: () => void;
  onSave: (option: TMeetupOption) => void;
};

const optionsThatCanBeChangedAfterUse = [
  FieldsOptionEdit.MaxForSignup,
  FieldsOptionEdit.MaxQuantity,
];

export default function MeetupEditOptionEdit({ show, option, onClose, onSave }: Props) {
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const language = useSelector((state: TState) => state.ui.language);
  // Internal state for editing option. Everything is string for easier data
  // binding to <TextField />.

  const [optionEdit, setOptionEdit] = useState({
    Id: '',
    [FieldsOptionEdit.Type]: '',
    [FieldsOptionEdit.Name]: '',
    [FieldsOptionEdit.Price]: '',
    [FieldsOptionEdit.MaxQuantity]: '',
    [FieldsOptionEdit.MaxForSignup]: '',
  });
  const [validations, setValidations] = useState<{
    [key: string]: { valid: boolean; errorMessage: string };
  }>(defaultValidation);

  const { Used: optionIsUsed } = option ?? {};

  // Map from internal edit type to actual type on Meetup.
  const mapMeetupOption = (meetupOptionEdit: TMeetupOptionEdit): TMeetupOption => {
    return {
      Id: meetupOptionEdit.Id,
      Type: Number(meetupOptionEdit.Type),
      Name: meetupOptionEdit.Name,
      Price: Number(meetupOptionEdit.Price),
      MaxQuantity:
        meetupOptionEdit.MaxQuantity && meetupOptionEdit.MaxQuantity !== ''
          ? Number(meetupOptionEdit.MaxQuantity)
          : null,
      MaxForSignup:
        meetupOptionEdit.MaxForSignup && meetupOptionEdit.MaxForSignup !== ''
          ? Number(meetupOptionEdit.MaxForSignup)
          : null,
      Used: !!optionIsUsed, // Always send the same value back to API
    };
  };

  // Input can be changed if option is not used OR if the field is either: MaxQuantity or MaxForSignup
  const canInputBeChanged = (fieldName: string) => {
    return !optionIsUsed || optionsThatCanBeChangedAfterUse.includes(fieldName as FieldsOptionEdit);
  };

  const isValid = (): boolean => {
    let valid = true;

    Object.keys(validations).forEach((key: string) => {
      if (!validations[key].valid) {
        valid = false;
      }
    });

    return valid;
  };

  const validate = async (updatedOption: TMeetupOptionEdit) => {
    const response = await axios.post<TFieldValidationResponse[]>(
      `${runtimeConfig.apiUrl}meetups/validate`,
      {
        RequestModel: { ...meetup, Options: [mapMeetupOption(updatedOption)] },
        FieldsToValidate: ['Options'],
      },
    );

    const updatedValidations: {
      [key: string]: { valid: boolean; errorMessage: string };
    } = {
      Name: { valid: true, errorMessage: '' },
      Price: { valid: true, errorMessage: '' },
      MaxQuantity: { valid: true, errorMessage: '' },
      MaxForSignup: { valid: true, errorMessage: '' },
    };

    // The response contains ALL invalid Option fields.
    response.data.forEach((validationResponse: TFieldValidationResponse) => {
      const fieldName = validationResponse.PropertyName.substr(11);
      updatedValidations[fieldName].valid = false;
      updatedValidations[fieldName].errorMessage = validationResponse.ErrorMessage;
    });

    setValidations(updatedValidations);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (canInputBeChanged(e.target.name)) {
      setOptionEdit({ ...optionEdit, [e.target.name]: e.target.value as string });
    }
  };

  const onToggleChange = (newValue: string | number) => {
    if (canInputBeChanged(FieldsOptionEdit.Type)) {
      setOptionEdit({ ...optionEdit, Type: String(newValue) });
    }
  };

  const onInputBlur = () => {
    validate(optionEdit);
  };

  useEffect(() => {
    setValidations(defaultValidation);

    if (option) {
      const optionMapped = {
        Id: option.Id || '',
        [FieldsOptionEdit.Type]: (option.Type || MeetupOptionType.Person).toString(),
        [FieldsOptionEdit.Name]: option.Name || '',
        [FieldsOptionEdit.Price]: option.Price?.toString() || '',
        [FieldsOptionEdit.MaxQuantity]: option.MaxQuantity?.toString() || '',
        [FieldsOptionEdit.MaxForSignup]: option.MaxForSignup?.toString() || '',
      };
      setOptionEdit(optionMapped);

      // if (!isNew) { // TODO:  med?
      //   validate(optionMapped);
      // }
    }
  }, [show, option]);

  return (
    <Modal title={texts[language].editOptionModalTitle} opened={show} onClose={onClose}>
      <Group p="xl" direction="column">
        {optionIsUsed && (
          <Alert
            mb="xl"
            text={`
              OBS. Tilvalget er i brug og du kan KUN ændre "Antal til rådighed" og "Antal pr.
              tilmelding".`}
          />
        )}
        <SegmentedControl
          name={FieldsOptionEdit.Type}
          data={[
            {
              label: 'Person',
              value: MeetupOptionType.Person.toString(),
            },
            {
              label: 'Ydelse',
              value: MeetupOptionType.Benefit.toString(),
            },
          ]}
          value={optionEdit?.Type}
          onChange={onToggleChange}
          disabled={optionIsUsed}
          fullWidth
          mb="md"
        />
        <TextField
          name={FieldsOptionEdit.Name}
          label={texts[language].editOptionName.label}
          description={texts[language].editOptionName.helpText}
          placeholder={texts[language].editOptionName.helpText}
          error={validations[FieldsOptionEdit.Name]?.errorMessage}
          value={optionEdit?.Name}
          onChange={onInputChange}
          onBlur={onInputBlur}
          required
          disabled={optionIsUsed}
          variant="outline"
          mb="md"
          cypress="OptionEditName"
        />
        <TextField
          type="number"
          name={FieldsOptionEdit.Price}
          label={texts[language].editOptionPrice.label}
          description={texts[language].editOptionPrice.helpText}
          placeholder={texts[language].editOptionPrice.helpText}
          error={validations[FieldsOptionEdit.Price]?.errorMessage}
          value={optionEdit?.Price}
          onChange={onInputChange}
          onBlur={onInputBlur}
          min={0}
          disabled={optionIsUsed}
          variant="outline"
          mb="md"
          cypress="OptionEditPrice"
        />
        <TextField
          type="number"
          name={FieldsOptionEdit.MaxQuantity}
          label={texts[language].editOptionMaxQuantity.label}
          description={texts[language].editOptionMaxQuantity.helpText}
          placeholder={texts[language].editOptionMaxQuantity.helpText}
          error={validations[FieldsOptionEdit.MaxQuantity]?.errorMessage}
          value={optionEdit?.MaxQuantity}
          onChange={onInputChange}
          onBlur={onInputBlur}
          min={1}
          variant="outline"
          mb="md"
          cypress="OptionEditMaxQuantity"
        />
        <TextField
          type="number"
          name={FieldsOptionEdit.MaxForSignup}
          label={texts[language].editOptionMaxForSignup.label}
          description={texts[language].editOptionMaxForSignup.helpText}
          placeholder={texts[language].editOptionMaxForSignup.helpText}
          error={validations[FieldsOptionEdit.MaxForSignup]?.errorMessage}
          value={optionEdit?.MaxForSignup}
          min={1}
          onChange={onInputChange}
          onBlur={onInputBlur}
          required
          variant="outline"
          mb="md"
          cypress="OptionEditMaxForSignup"
        />
        <Group grow>
          <Button text={texts[language].editOptionButtonClose} onClick={onClose} />
          <Button
            text={texts[language].editOptionButtonSave}
            onClick={() => {
              if (isValid()) {
                onSave(mapMeetupOption(optionEdit));
              }
            }}
            disabled={!isValid()}
            cypress="OptionEditSave"
          />
        </Group>
      </Group>
    </Modal>
  );
}
