import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Modal, Paragraph, Loader, Group, Button } from '@ingeniorforeningen/figurine-core';
import { TMeetup } from '../../store/meetups/types';
import { TState } from '../../store/rootReducer';
import texts from '../../texts/texts';

type Props = {
  meetup: Partial<TMeetup>;
  show: boolean;
  onClose: () => void;
};

export default function MeetupOrganizerModal({ meetup, show, onClose }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [showSecondButton, setShowSecondButton] = useState(false);
  const [bodyText, setBodyText] = useState('');
  const language = useSelector((state: TState) => state.ui.language);

  const signupOrganizer = async () => {
    setBodyText('');

    setIsLoading(true);

    let success = false;

    try {
      const response = await axios.post(
        `${runtimeConfig.apiUrl}meetups/${meetup.MeetupNumber}/organizerregistrations`,
        { validateStatus: (status: any) => status < 1000 },
      );
      success = response.status === 200;
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    setBodyText(
      success
        ? texts[language].organizerModalRegisterSuccess
        : texts[language].organizerModalRegisterFailure,
    );
    setShowSecondButton(!success);
  };

  useEffect(() => {
    setBodyText('');
    setIsLoading(true);

    if (show) {
      let userCanRegisterAsOrganizer = false;

      const getOrganizerRegistrationValid = async () => {
        try {
          const response = await axios.get(
            `${runtimeConfig.apiUrl}meetups/${meetup.MeetupNumber}/organizerregistrations`,
            { validateStatus: (status) => status < 1000 },
          );
          userCanRegisterAsOrganizer = response.status === 200;
        } catch (error) {
          console.error(error);
        }

        setBodyText(
          userCanRegisterAsOrganizer
            ? texts[language].organizerModalUserCanRegisterText
            : texts[language].organizerModalUserIsRegisteredText,
        );
        setShowSecondButton(userCanRegisterAsOrganizer);
        setIsLoading(false);
      };

      getOrganizerRegistrationValid();
    }
  }, [show]);

  return (
    <>
      {show && (
        <Modal title={meetup.Title} opened={show} onClose={onClose}>
          <Group direction="column" p="xl">
            {isLoading && <Loader />}
            {!isLoading && <Paragraph text={bodyText} />}
            <Group direction="column" grow mt="xl">
              <Button
                text={texts[language].organizerModalRegisterButtonAbort}
                onClick={() => onClose()}
                variant="outline"
              />
              {showSecondButton && (
                <Button
                  text={texts[language].organizerModalRegisterButtonAction}
                  onClick={() => signupOrganizer()}
                />
              )}
            </Group>
          </Group>
        </Modal>
      )}
    </>
  );
}
