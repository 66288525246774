import { Dispatch } from 'redux';
import axios from 'axios';
import {
  SetIsLoadingAction,
  SetFilterCreatedByAction,
  SetCurrentStepAction,
  SetCurrentStepValidAction,
  SetStepAction,
  SetStepsAction,
  SetMeetupSavedAction,
  SetLanguageAction,
  SetFieldValidationAction,
  SetFieldValidationsAction,
  SetErrorAction,
  SetShowHeaderAction,
  SetShowFooterAction,
  SET_IS_LOADING,
  SET_FILTER_CREATED_BY,
  SET_CURRENT_STEP,
  SET_CURRENT_STEP_VALID,
  SET_STEP,
  SET_STEPS,
  SET_MEETUP_SAVED,
  SET_FIELD_VALIDATION,
  SET_FIELD_VALIDATIONS,
  SET_LANGUAGE,
  SET_ERROR,
  SET_SHOW_HEADER,
  SET_SHOW_FOOTER,
  TLanguage,
  TStep,
  TStepNumber,
  TFieldValidation,
  TFilterCreatedBy,
  TStepsState,
  TFieldValidationState,
  TFieldValidationResponse,
  TError,
} from './types';
import { TMeetup } from '../meetups/types';
import { mapMeetupRequest } from '../meetups/mappings';
import { initialFieldValidations, initialSteps } from './reducers';

export const setIsLoading = (loading: boolean): SetIsLoadingAction => ({
  type: SET_IS_LOADING,
  payload: loading,
});

export const setFilterCreatedBy = (filter: TFilterCreatedBy): SetFilterCreatedByAction => ({
  type: SET_FILTER_CREATED_BY,
  payload: filter,
});

export const setCurrentStep = (currentStep: TStepNumber): SetCurrentStepAction => ({
  type: SET_CURRENT_STEP,
  payload: currentStep,
});

export const setCurrentStepValid = (valid: boolean): SetCurrentStepValidAction => ({
  type: SET_CURRENT_STEP_VALID,
  payload: valid,
});

export const setStep = (step: TStep): SetStepAction => ({
  type: SET_STEP,
  payload: step,
});

export const setSteps = (steps: TStepsState): SetStepsAction => ({
  type: SET_STEPS,
  payload: steps,
});

export const resetSteps =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setSteps(initialSteps));
    dispatch(setCurrentStep(1));
  };

export const setMeetupSaved = (meetupSaved: boolean): SetMeetupSavedAction => ({
  type: SET_MEETUP_SAVED,
  payload: meetupSaved,
});

export const setFieldValidation = (
  fieldValidation: Partial<TFieldValidation>,
): SetFieldValidationAction => ({
  type: SET_FIELD_VALIDATION,
  payload: fieldValidation,
});

export const setFieldValidations = (
  fieldValidations: TFieldValidationState,
): SetFieldValidationsAction => ({
  type: SET_FIELD_VALIDATIONS,
  payload: fieldValidations,
});

export const setLanguage = (language: TLanguage): SetLanguageAction => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const setError = (error: TError): SetErrorAction => ({
  type: SET_ERROR,
  payload: error,
});

export const setShowHeader = (show: boolean): SetShowHeaderAction => ({
  type: SET_SHOW_HEADER,
  payload: show,
});

export const setShowFooter = (show: boolean): SetShowFooterAction => ({
  type: SET_SHOW_FOOTER,
  payload: show,
});

export const updateFieldValidation =
  (args: {
    step: TStepNumber;
    fields: string[];
    meetup: Partial<TMeetup>;
    showMessage?: boolean;
    excludeInValidation?: boolean;
  }) =>
  async (dispatch: Dispatch): Promise<Partial<TFieldValidation>[]> => {
    const payload = mapMeetupRequest(args.meetup);
    const response = await axios.post<TFieldValidationResponse[]>(
      `${runtimeConfig.apiUrl}meetups/validate`,
      { RequestModel: payload, FieldsToValidate: args.fields },
    );
    const fieldValidations: Partial<TFieldValidation>[] = [];

    args.fields.forEach((field: string) => {
      const validation = response?.data.find(
        (value: TFieldValidationResponse) => value.PropertyName === field,
      );
      const fieldValidation: Partial<TFieldValidation> = {
        field,
        valid: !validation,
        message: validation ? validation.ErrorMessage : '',
        step: args.step,
        showMessage: args.showMessage,
        excludeInValidation: args.excludeInValidation,
      };
      fieldValidations.push(fieldValidation);

      dispatch(setFieldValidation(fieldValidation));
    });

    return fieldValidations;
  };

export const resetFieldValidations =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setFieldValidations(initialFieldValidations));
  };
