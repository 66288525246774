import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Group,
  Heading,
  Loader,
  Paper,
  Paragraph,
} from '@ingeniorforeningen/figurine-core';
import { TState } from '../../store/rootReducer';
import { useEffect, useState } from 'react';
import texts from '../../texts/texts';
import * as meetupActions from '../../store/meetups/actions';
import QrCodeGenerator from '../Common/QrCodeGenerator';

const appendUtmCodes = (url: string) => {
  return url.concat('utm_source=qrkode&utm_medium=offline&utm_campaign=frivillig-meetup-promotion');
};

export default function MeetupQrCodes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector((state: TState) => state.ui.language);
  const { meetupNumber } = useParams<{ meetupNumber: string }>();
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const { Id: meetupId } = meetup;
  const [meetupUrl, setMeetupUrl] = useState<string | null>(null);
  const [participantListUrl, setParticipantListUrl] = useState<string | null>(null);

  useEffect(() => {
    if (meetupNumber) {
      const getMeetup = async () => {
        await dispatch(meetupActions.getMeetup(Number(meetupNumber)));
      };
      getMeetup();
    }
  }, [meetupNumber]);

  useEffect(() => {
    const generateUrls = () => {
      setMeetupUrl(appendUtmCodes(`${runtimeConfig.meetupRedirectUrl}${meetupNumber}?`));

      setParticipantListUrl(
        appendUtmCodes(
          `${runtimeConfig.mitIdaRedirectUrl}tilmeldte-til-arrangement?meetupId=${meetupId}&`,
        ),
      );
    };

    if (meetupId) {
      generateUrls();
    }
  }, [meetupId]);

  return (
    <Container>
      <Paper my="xl" p="xl">
        <Group direction="column" p="xl">
          <Heading text="QR Koder" mb="xl" />
          {meetup.MeetupNumber ? <Heading type="h2" text={meetup.Title!} /> : <Loader size="md" />}

          <Paragraph
            text="
            Her kan du hente QR-koder til arrangementet. Du kan bruge QR koden til arrangementssiden
            til at promovere dit arrangement. Du kan bruge QR koden til deltagerlisten til at gøre
            det nemt for deltagerne at se, hvem der kommer til arrangementet.
            
            For at downloade koden, skal du højre-klikke på den og vælge “Kopier billede” eller “Gem
            billede som...”.
            "
          />

          <Grid container columns={2}>
            <Grid item xs={2} sm={1}>
              <Group direction="column" spacing="xl">
                <Heading type="h2" text="Arrangementssiden" />
                {meetupUrl && (
                  <>
                    <QrCodeGenerator text={meetupUrl} alt="Arrangementssiden" />
                    <Paragraph text={`Koden går til dette link: ${meetupUrl}`} />
                  </>
                )}
              </Group>
            </Grid>
            <Grid item xs={2} sm={1}>
              <Group direction="column" spacing="xl">
                <Heading type="h2" text="Deltagerlisten" />
                {participantListUrl && (
                  <>
                    <QrCodeGenerator text={participantListUrl} alt="Deltagerlisten" />
                    <Paragraph text={`Koden går til dette link: ${participantListUrl}`} />
                  </>
                )}{' '}
              </Group>
            </Grid>
          </Grid>

          <Button onClick={() => navigate('/')} text={texts[language].buttonSpeakersBack} mt="xl" />
        </Group>
      </Paper>
    </Container>
  );
}
