import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { CalendarDate, Group } from '@ingeniorforeningen/figurine-core';

type Props = {
  date: Date;
};

export default function MeetupDate({ date }: Props) {
  return (
    <Group align="start" justify="center" mt="md">
      <CalendarDate
        day={format(date, 'd', { locale: da })}
        month={format(date, 'MMMM', { locale: da })}
        year={format(date, 'yyyy', { locale: da })}
      />
    </Group>
  );
}
