import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Group } from '@ingeniorforeningen/figurine-core';
import { TState } from '../../store/rootReducer';
import * as uiActions from '../../store/ui/actions';
import { TStepNumber } from '../../store/ui/types';
import texts from '../../texts/texts';

type Props = {
  onStepChanged: (currentStep: number, previousStep: number) => void;
  onEnd: () => void;
  allStepsValid: boolean;
  isLoading: boolean;
};

export default function NavigationButtons({
  onStepChanged,
  onEnd,
  allStepsValid,
  isLoading,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStep = useSelector((state: TState) => state.ui.currentStep);

  const currentStepValid = useSelector((state: TState) => state.ui.currentStepValid);
  const language = useSelector((state: TState) => state.ui.language);
  const meetup = useSelector((state: TState) => state.meetups.meetup);

  const previousStep = () => {
    if (currentStep === 1) {
      navigate('/');
    } else {
      dispatch(uiActions.setStep({ key: currentStep, state: 'unreached' }));
      dispatch(uiActions.setStep({ key: currentStep - 1, state: 'active' }));
      dispatch(uiActions.setCurrentStep((currentStep - 1) as TStepNumber));
      onStepChanged(currentStep - 1, currentStep);
    }
  };

  const nextStep = () => {
    dispatch(uiActions.setStep({ key: currentStep, state: 'completed' }));
    dispatch(uiActions.setStep({ key: currentStep + 1, state: 'active' }));
    dispatch(uiActions.setCurrentStep((currentStep + 1) as TStepNumber));
    onStepChanged(currentStep + 1, currentStep);
  };

  return (
    <Group justify="flex-end" mt="xl">
      <Button onClick={(): void => previousStep()} text="Tilbage" variant="outline" />

      {currentStep < 8 && (
        <Button
          text="Næste"
          disabled={!currentStepValid}
          icon="ChevronRight"
          onClick={() => {
            if (currentStepValid) {
              nextStep();
            }
          }}
          cypress="navigateNext"
        />
      )}
      {currentStep === 8 && !meetup.MeetupNumber && (
        <Button
          id="btnCreate"
          onClick={() => onEnd()}
          disabled={!allStepsValid || !currentStepValid}
          cypress="navigateDone"
          loading={isLoading}
          text={texts[language].buttonCreate}
          variant="cta"
        />
      )}
      {meetup.MeetupNumber !== undefined && meetup.MeetupNumber > 0 && (
        <Button
          id="btnUpdate"
          onClick={() => onEnd()}
          disabled={!allStepsValid || !currentStepValid}
          cypress="navigateUpdate"
          loading={isLoading}
          text={texts[language].buttonUpdate}
          ml="xl"
          variant="cta"
        />
      )}
    </Group>
  );
}
