import { TTexts } from './types';

const texts: TTexts = {
  da: {
    buttonNext: 'Næste',
    buttonPrev: 'Tilbage',
    buttonCreate: 'Opret',
    buttonUpdate: 'Gem og afslut',
    buttonSpeakersBack: 'Til forsiden',
    title: {
      label: 'Titel',
      placeholder: '',
      helpText: '',
    },
    teaser: {
      label: 'Teaser',
      placeholder: '',
      helpText:
        'Skriv, hvad der er mest interessant ved dit arrangement. Teksten benyttes som underoverskrift på dit arrangement og bruges ofte i nyhedsmails.',
    },
    description: {
      label: 'Beskrivelse',
      placeholder: '',
      helpText:
        'Skriv den fulde arrangementstekst. Hvis der er billetter til dit arrangement, så skriv, hvordan disse bliver udleveret. Husk også at skrive om der er forplejning eller andre vigtige oplysninger til deltagerne.',
    },
    imageUpload: {
      label: 'Billede',
      placeholder: '',
      helpText:
        'Du må kun benytte billeder, som du har rettigheder til.<br />IDA oplever jævnligt at skulle betale godtgørelse til rettighedshavere af billeder, som bliver brugt uden tilladelse.<br />Du kan læse mere om brug af billeder i <a href="https://ida.dk/om-ida/aktive/aktivguiden/regler-for-brug-af-fotos-i-dokumenter-praesentationer-og-paa-web" target="_blank">vores Aktiveguide</a>.',
    },
    isRelevantForInternationals: {
      label: 'Sæt et flueben, hvis arrangementet er relevant for engelsktalende medlemmer',
      placeholder: '',
      helpText:
        'Hvis du sætter flueben her, bliver arrangementet automatisk vist på IDAs engelske sider, og kan fremsøges på arrangementssøgningen. Vær opmærksom på, at ved flueben bliver tekster og overskrifter ikke automatisk oversat til engelsk. Husk at skrive teksten på engelsk, både i titlen, teaseren og beskrivelsen. Hvis du sætter den danske tekst øverst, så husk at skriv ENGLISH SEE BELOW.',
    },
    startTime: {
      label: 'Fra',
      placeholder: 'Vælg en dato',
      helpText: '',
    },
    endTime: {
      label: 'Til',
      placeholder: 'Vælg en dato',
      helpText: '',
    },
    registrationDeadline: {
      label: 'Tilmeldingsfrist',
      placeholder: 'Vælg en dato',
      helpText: '',
    },
    cancellationDeadline: {
      label: 'Afmeldingsfrist',
      placeholder: 'Vælg en dato',
      helpText: '',
    },
    publicationTime: {
      label: 'Publiceringstidspunkt',
      placeholder: 'Vælg en dato',
      helpText: '',
    },
    mainOrganizer: {
      label: 'Arrangør',
      placeholder: '',
      helpText: 'Vælg hovedarrangør på listen.',
    },
    coOrganizers: {
      label: 'Medarrangører',
      placeholder: '',
      helpText:
        'Vælg evt. medarrangører på listen. Du kan vælge de medarrangører hvor du er medlem af bestyrelsen.',
    },
    meetupType: {
      label: 'Type',
      placeholder: '',
      helpText: 'Vælg arrangementstypen.',
    },
    location: {
      label: 'Arrangementssted',
      placeholder: 'Begynd indtastning for at søge',
      helpText:
        'Begynd indtastning og få valgmuligheder frem. Arrangementsstedet er der, hvor du mødes med dine deltagere ved arrangementets opstart.',
    },
    locationAdditionalInfo: {
      label: 'Supplerende information',
      placeholder: '',
      helpText:
        'Det kan være information om lokale, alternativt mødested eller andet (fx info om billetter). maks. 100 tegn.',
    },
    region: {
      label: 'Geografisk område',
      placeholder: 'Vælg region',
      helpText:
        'Vælg i hvilket geografisk område arrangementet afholdes. Dette har betydning for medlemmerne, når de søger geografisk efter arrangementer.',
    },
    newLocationModalTitle: 'Opret arrangementssted',
    newLocationButtonClose: 'Luk',
    newLocationButtonAction: 'Opret',
    newLocationName: {
      label: 'Navn',
      placeholder: '',
      helpText: 'Skriv arrangementsstedets navn',
    },
    newLocationAddress1: {
      label: 'Adresse',
      placeholder: '',
      helpText: '',
    },
    newLocationZip: {
      label: 'Postnummer',
      placeholder: '',
      helpText: '',
    },
    newLocationCity: {
      label: 'By',
      placeholder: '',
      helpText: '',
    },
    participantTypePrice: {
      label: 'Pris*',
      placeholder: '',
      helpText: '',
      errorText: 'Pris skal udfyldes',
    },
    participantTypeMax: {
      label: 'Maks. antal',
      placeholder: '',
      helpText: '',
    },
    participantTypeExpected: {
      label: 'Forventet antal deltagere',
      placeholder: '',
      helpText: '',
    },
    seatsQuantity: {
      label: 'Maksimum antal deltagere',
      placeholder: '',
      helpText:
        'Skriv maksimum antal deltagere, der kan tilmelde sig arrangementet. Når maksimum er nået, kommer deltagerne på venteliste, såfremt tilmeldingsfristen ikke er overskredet.',
    },
    seatsRequired: {
      label: 'Minimum antal deltagere',
      placeholder: '',
      helpText:
        'Skriv minimum antal deltagere der skal tilmelde sig arrangementet, for at det gennemføres. Et tomt felt betyder ingen minimumsgrænse.',
    },
    editOptionModalTitle: 'Opret tilvalg',
    editOptionButtonDelete: 'Slet',
    editOptionButtonClose: 'Annuller',
    editOptionButtonSave: 'Gem',
    editOptionType: {
      label: 'Type',
      placeholder: '',
      helpText:
        'Vælg om type er en person (tæller med i deltagerantal) eller en ydelse (tæller ikke med i deltagerantal).',
    },
    editOptionName: {
      label: 'Navn',
      placeholder: '',
      helpText: 'Skriv navn på tilvalg, fx ledsager, middag, bøger, ol.',
    },
    editOptionPrice: {
      label: 'Pris',
      placeholder: '',
      helpText: 'Pris pr. stk. i dkk.',
    },
    editOptionMaxQuantity: {
      label: 'Antal til rådighed',
      placeholder: '',
      helpText: 'Her skriver du det samlede antal, der er til rådighed.',
    },
    editOptionMaxForSignup: {
      label: 'Antal pr. tilmelding',
      placeholder: '',
      helpText: 'Skriv det antal, som den enkelte deltager maks. kan bestille.',
    },
    editSpeakerModalTitle: 'Tilføj foredragsholder',
    editSpeakerButtonDelete: 'Slet',
    editSpeakerButtonClose: 'Luk',
    editSpeakerButtonSave: 'Gem',
    editSpeakerName: {
      label: 'Navn',
      placeholder: '',
      helpText: 'Angiv fuldt navn på foredragsholder.',
    },
    editSpeakerTitle: {
      label: 'Titel',
      placeholder: '',
      helpText: 'Angiv foredragsholderens titel (max 40 karakterer med mellemrum).',
    },
    editSpeakerEmail: {
      label: 'Email',
      placeholder: '',
      helpText: 'Angiv e-mail på foredragsholder.',
    },
    editSpeakerMobilePhone: {
      label: 'Mobilnummer',
      placeholder: '',
      helpText: 'Angiv mobilnummer på foredragsholder.',
    },
    participantQuestion: {
      label: 'Spørgsmål',
      placeholder: '',
      helpText:
        "Angiv det spørgsmål, deltageren skal stilles ved tilmelding, fx. 'Oplys ledsagers fulde navn', 'Ønskes frokostretten med fisk eller kød?' eller 'Ønsker du bustransport tur/ retur ? Besvares med ja eller nej.' Vær opmærksom på, at spørgsmålet er obligatorisk for deltageren.",
    },
    sustainableDevelopmentGoals: {
      label: 'Verdensmål',
      placeholder: '',
      helpText:
        'IDA ønsker at synliggøre indsatsen for FN’s 17 verdensmål for bæredygtighed. Hvis arrangementet direkte formidler viden om et eller flere verdensmål, så marker op til tre relevante mål.<br>Er der ikke en oplagt forbindelse til verdensmålene, så undlad markering. <a href="https://ida.dk/om-ida/aktive/aktivguiden/saadan-markerer-du-verdensmaal-paa-arrangementer" target="_blank">Læs mere om markering af verdensmål i Aktivguiden</a><br><br>Marker relevante verdensmål (max 3).',
    },
    organizerModalRegisterSuccess:
      'Du er nu tilmeldt som arrangør. Kontakt en netværkskoordinator hvis tilmeldingen skal ændres.',
    organizerModalRegisterFailure:
      'Du kunne ikke tilmeldes som arrangør. Kontakt en netværkskoordinator.',
    organizerModalRegisterButtonAction: 'Tilmeld som arrangør',
    organizerModalRegisterButtonAbort: 'Tilbage',
    organizerModalUserCanRegisterText:
      "Klik på 'Tilmeld som arrangør' for gratis at blive tilmeldt som arrangør.",
    organizerModalUserIsRegisteredText:
      'Du er tilmeldt arrangementet eller der er allerede en arrangør tilknyttet. Kontakt en netværkskoordinator hvis dette skal ændres.',
    unpublishModalSuccess: 'Arrangementet er nu afpubliceret.',
    unpublishModalFailure: 'Arrangementet kunne ikke afpubliceres',
    unpublishModalButtonAction: 'Afpublicer',
    unpublishModalButtonAbort: 'Tilbage',
    unpublishModalUserCanRegisterText:
      "Klik på 'Afpublicer' for at afpublicere arrangementet. Bemærk at du ikke kan afpublicere arrangementet hvis du har tilmeldt en arrangør.",
    unpublishModalUserIsRegisteredText: 'Arrangementet er endnu ikke publiceret.',
    cancelModalSuccess: 'Arrangementet er nu aflyst.',
    cancelModalFailure: 'Arrangementet kunne ikke aflyses. Kontakt en netværkskoordinator.',
    cancelModalButtonAction: 'Aflys',
    cancelModalButtonAbort: 'Tilbage',
    cancelModalCanCancelText:
      "Klik på 'Aflys' for at aflyse arrangementet. Alle tilmeldinger vil blive afmeldt og, hvis der er deltagerbetaling vil der blive sendt en kreditnota. Alle tilmeldte deltagere vil modtage en aflysnings-mail og en aflysnings-sms.",
    cancelModalIsCancelledText: 'Arrangementet kan ikke aflyses. Kontakt en netværkskoordinator.',
    priceEditDisabledText: 'Arrangementet har tilmeldinger og priserne kan derfor ikke redigeres.',
    dateEditDisabledText:
      'Arrangementet er afholdt eller aflyst og datoerne kan derfor ikke redigeres',
  },
};

export default texts;
