import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import * as meetupActions from '../../store/meetups/actions';
import * as uiActions from '../../store/ui/actions';
import { TMeetup, TParticipantPrice } from '../../store/meetups/types';
import { FieldsParticipantPrices } from '../../helpers/fields';
import MeetupEditBudget from './MeetupEditBudget';
import {
  Button,
  Checkbox,
  Grid,
  Group,
  Paragraph,
  TextField,
  useMediaQuery,
  useTheme,
  Footnote,
} from '@ingeniorforeningen/figurine-core';

export default function MeetupEditParticipantPrices() {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints?.sm})`);
  const dispatch = useDispatch();
  const language = useSelector((state: TState) => state.ui.language);
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const participantTypes = useSelector((state: TState) => state.meetups.participantTypes);
  // Variable updated only on user input and is passed to MeetupEditBudget to make it
  // recalculate budget.
  const [participantTypesChanges, setParticipantTypesChanges] = useState<
    Partial<TParticipantPrice>[] | undefined
  >();
  const [participantPricesInitialized, setParticipantPricesInitialized] = useState(false);

  useEffect(() => {
    dispatch(meetupActions.getParticipantTypes());
    dispatch(uiActions.setCurrentStepValid(false));
  }, []);

  // Initialize meetup participant prices (from available types) and fill in existing values (if any).
  useEffect(() => {
    if (!participantPricesInitialized && participantTypes.length !== 0 && meetup) {
      const participantPrices: TParticipantPrice[] = participantTypes.map((value) => {
        const existingPrice = meetup.ParticipantPrices?.find(
          (existingValue) => existingValue.ParticipantTypeId === value.Id,
        );

        return {
          ParticipantTypeId: value.Id,
          Name: value.Name,
          Price: existingPrice?.Price ?? null,
          MaxQuantity: existingPrice?.MaxQuantity || null,
          ExpectedQuantity: existingPrice?.ExpectedQuantity || null,
          Active: !!existingPrice?.Active,
        };
      });

      setParticipantPricesInitialized(true);
      const meetupInitialized = { ...meetup, ParticipantPrices: participantPrices };
      dispatch(meetupActions.setMeetup(meetupInitialized));
      // Override MeetupEdit standard step validation.
      setTimeout(() => onInputChangeValidate(meetupInitialized), 200);
    }
  }, [participantTypes, meetup]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (meetup.ParticipantPrices) {
      let value = null;

      if (e.target.name === FieldsParticipantPrices.Active) {
        value = Boolean(e.target.checked);
      }
      if (
        (e.target.name === FieldsParticipantPrices.Price ||
          e.target.name === FieldsParticipantPrices.MaxQuantity ||
          e.target.name === FieldsParticipantPrices.ExpectedQuantity) &&
        e.target.value
      ) {
        value = Number(e.target.value);
      }
      const participantTypesUpdated = Array.from(meetup.ParticipantPrices);
      participantTypesUpdated[index] = {
        ...participantTypesUpdated[index],
        [e.target.name as keyof TParticipantPrice]: value,
      };
      // Clear price and max participants if participant type is deactivated.
      if (e.target.name === FieldsParticipantPrices.Active && !value) {
        participantTypesUpdated[index].Price = null;
        participantTypesUpdated[index].MaxQuantity = null;
        participantTypesUpdated[index].ExpectedQuantity = null;
      }

      const meetupUpdated = { ...meetup, ParticipantPrices: participantTypesUpdated };
      dispatch(meetupActions.setMeetup(meetupUpdated));
      setParticipantTypesChanges(participantTypesUpdated);
      onInputChangeValidate(meetupUpdated);
    }
  };

  const onInputChangeValidate = (meetup: Partial<TMeetup>) => {
    // Validate at least one participant type has been selected.
    const minParticipantPricesActiveValid = !!meetup.ParticipantPrices?.some(
      (value) => value.Active,
    );

    let pricesValid = true;

    // Validate price is not empty for active participant types.
    if (minParticipantPricesActiveValid) {
      pricesValid = !meetup.ParticipantPrices?.some(
        (value) => value.Active && (value.Price === undefined || value.Price === null),
      );
    }

    dispatch(uiActions.setCurrentStepValid(minParticipantPricesActiveValid && pricesValid));
  };

  const addBudget = () => {
    if (!meetup.Budget) {
      dispatch(
        meetupActions.setMeetup({
          ...meetup,
          Budget: {
            Catering: null,
            MeetingExpenses: null,
            OtherExpenses: null,
            OtherIncome: null,
            ParticipantPayment: null,
            Speaker: null,
            TicketExpenses: null,
            TotalBudget: null,
            TotalExpenses: null,
            TotalIncome: null,
            TravelExpenses: null,
          },
        }),
      );
    }
  };

  const hasSignups = meetup.SeatsOccupied != null && meetup.SeatsOccupied !== 0;

  return (
    <>
      {hasSignups && (
        <Grid container>
          <Grid item xs={10} md={8}>
            <Paragraph text={texts[language].priceEditDisabledText} variant="error" />
          </Grid>
        </Grid>
      )}

      {meetup.ParticipantPrices?.map((participantPrice, index) => (
        <Group key={participantPrice.ParticipantTypeId} mb="xl" noWrap={!isMobile} spacing="xl">
          <Group grow style={{ flexBasis: 320 }} align="center">
            <Checkbox
              id={`checkbox${index}`}
              name={FieldsParticipantPrices.Active}
              checked={participantPrice.Active}
              onChange={(e) => onInputChange(e, index)}
              text={participantPrice.Name || ''}
              cypress={`checkbox${index}`}
            />
          </Group>
          <Group grow>
            <Group direction="column">
              {index === 0 && (
                <Footnote text={texts[language].participantTypePrice.label} ml="xs" />
              )}
              <TextField
                type="number"
                label={texts[language].participantTypePrice.label}
                name={FieldsParticipantPrices.Price}
                placeholder={texts[language].participantTypePrice.placeholder}
                onChange={(e) => onInputChange(e, index)}
                value={participantPrice.Active ? participantPrice.Price?.toString() || '' : ''}
                min={0}
                disabled={!participantPrice.Active || hasSignups}
                cypress={`${FieldsParticipantPrices.Price}${index}`}
                required
              />
            </Group>

            <Group direction="column">
              {index === 0 && <Footnote text={texts[language].participantTypeMax.label} ml="xs" />}
              <TextField
                type="number"
                name={FieldsParticipantPrices.MaxQuantity}
                label={texts[language].participantTypeMax.label}
                placeholder={texts[language].participantTypeMax.placeholder}
                onChange={(e) => onInputChange(e, index)}
                value={
                  participantPrice.MaxQuantity ? participantPrice.MaxQuantity.toString() || '' : ''
                }
                min={1}
                disabled={!participantPrice.Active}
                cypress={`${FieldsParticipantPrices.MaxQuantity}${index}`}
                showOptionalInLabel={false}
              />
            </Group>
            {meetup.Budget && (
              <Group direction="column">
                {index === 0 && (
                  <Footnote text={texts[language].participantTypeExpected.label} ml="xs" />
                )}
                <TextField
                  type="number"
                  name={FieldsParticipantPrices.ExpectedQuantity}
                  label={texts[language].participantTypeExpected.label}
                  placeholder={texts[language].participantTypeExpected.placeholder}
                  onChange={(e) => onInputChange(e, index)}
                  value={
                    participantPrice.ExpectedQuantity
                      ? participantPrice.ExpectedQuantity.toString()
                      : ''
                  }
                  min={1}
                  disabled={!participantPrice.Active}
                  cypress={`${FieldsParticipantPrices.ExpectedQuantity}${index}`}
                  showOptionalInLabel={false}
                />
              </Group>
            )}
          </Group>
        </Group>
      ))}

      {!meetup.Budget ? (
        <Group justify="center" py="xl">
          <Button onClick={() => addBudget()} text="Tilføj budget" cypress="addBudgetButton" />
        </Group>
      ) : (
        <Grid container>
          <Grid item xs={10} md={10}>
            <MeetupEditBudget participantTypesChanges={participantTypesChanges} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
