import TrackingManager from '@ingeniorforeningen/tracking';
import { appInsights } from './appInsights';

const useGtmProdContainer = runtimeConfig.useGtmProdContainer;
const trackingManager = new TrackingManager(useGtmProdContainer);
export { trackingManager };

const SOLUTION_NAME = 'meetup-create';

const sanitizePathForTracking = (path: string) => {
  // Prevent undesirable regex formatting by Prettier
  // prettier-ignore
  const regExAfterQuestionmark : RegExp = /\?(.*)/;
  return path.replace(regExAfterQuestionmark, '');
};

export const trackPageView = (path: string) => {
  trackingManager.page.view({
    isLoggedIn: true,
    section: SOLUTION_NAME,
    pageTitle: sanitizePathForTracking(path),
  });
};

export const trackFormStarted = (formName: string) => {
  trackingManager.forms.started({
    name: formName,
  });
};

export const trackFormCompleted = (formName: string) => {
  trackingManager.forms.completed({
    name: formName,
  });
};

export const trackError = (error: Error, errorCode: number) => {
  trackingManager.page.error({
    errorcode: errorCode,
  });
  appInsights.trackException({ exception: error, properties: { errorCode: errorCode } });
};

export const trackPromotion = (
  path: string,
  title: string,
  url: string,
  placement: number,
  categoryName: string,
  subCategoryName: string,
  employmentTypeName: string,
) => {
  trackingManager.promotion.clicked({
    pageTitle: sanitizePathForTracking(path),
    pageSection: SOLUTION_NAME,
    promotionTitle: title,
    promotionUrl: url,
    promotionPlacement: placement,
    ...(categoryName && { caseCategory: categoryName }),
    ...(subCategoryName && { caseSubCategory: subCategoryName }),
    ...(employmentTypeName && { employmentType: employmentTypeName }),
  });
};
