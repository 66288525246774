import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as meetupActions from '../store/meetups/actions';
import { TState } from '../store/rootReducer';
import { TMeetup } from '../store/meetups/types';

export default function useDraft(): {
  draft: Partial<TMeetup> | undefined;
  saveDraft: () => void;
  resumeDraft: () => void;
  removeDraft: () => void;
} {
  const dispatch = useDispatch();
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const [draft, setDraft] = useState<Partial<TMeetup> | undefined>();

  useEffect(() => {
    const meetupRaw = localStorage.getItem('meetupDraft');

    if (meetupRaw) {
      setDraft(JSON.parse(meetupRaw) as TMeetup);
    }
  }, []);

  const saveDraft = () => {
    const meetupDraft = R.clone(meetup);
    meetupDraft.IsDraft = true;
    localStorage.setItem('meetupDraft', JSON.stringify(meetupDraft));
    setDraft(meetupDraft);
  };

  const resumeDraft = async () => {
    if (draft) {
      dispatch(meetupActions.setMeetup(draft));
    }
  };

  const removeDraft = () => {
    localStorage.removeItem('meetupDraft');
    setDraft(undefined);
  };

  return { draft, saveDraft, resumeDraft, removeDraft };
}
