import React, { useEffect, useState } from 'react';
import QRCode, { QRCodeToDataURLOptions } from 'qrcode';
import { Loader } from '@ingeniorforeningen/figurine-core';

const qrCodeOptions: QRCodeToDataURLOptions = {
  errorCorrectionLevel: 'H',
  width: 400,
  margin: 0,
  color: { light: '#0000' }, // Makes the QR-code transparent
};

interface QRCodeGeneratorProps {
  text: string;
  alt: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ text, alt }) => {
  const [qrCodeDataUrl, setQRCodeDataUrl] = useState('');

  useEffect(() => {
    QRCode.toDataURL(text, qrCodeOptions, (error, dataUrl) => {
      if (!error) {
        setQRCodeDataUrl(dataUrl);
      }
    });
  }, [text]);

  return qrCodeDataUrl ? (
    <img style={{ maxWidth: 400 }} src={qrCodeDataUrl} alt={alt} />
  ) : (
    <Loader size="lg" />
  );
};

export default QRCodeGenerator;
