import { combineReducers } from 'redux';
import uiReducer from './ui/reducers';
import meetupReducer from './meetups/reducers';
import { TUiState } from './ui/types';
import { TMeetupState } from './meetups/types';

export type TState = {
  ui: TUiState;
  meetups: TMeetupState;
};

export const rootReducer = combineReducers({
  ui: uiReducer,
  meetups: meetupReducer,
});
