import { AuthConsumer } from './AuthProvider';

export default function Logout() {
  return (
    <AuthConsumer>
      {({ logout }) => {
        logout();
        return <></>;
      }}
    </AuthConsumer>
  );
}
