import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import texts from '../../texts/texts';
import { TState } from '../../store/rootReducer';
import getErrorMessage from '../../helpers/errorMessage';
import { FieldsDescription } from '../../helpers/fields';
import useDebounce from '../../hooks/useDebounce';
import * as uiActions from '../../store/ui/actions';
import * as meetupActions from '../../store/meetups/actions';
import useFieldEvent from '../../hooks/useFieldEvent';

import {
  Caption,
  Checkbox,
  Grid,
  Paragraph,
  TextAreaField,
  TextField,
} from '@ingeniorforeningen/figurine-core';

const STEP_NUMBER = 1;

export default function MeetupEditDescription() {
  const dispatch = useDispatch();
  const location = useLocation();
  const language = useSelector((state: TState) => state.ui.language);
  const meetup = useSelector((state: TState) => state.meetups.meetup);
  const fieldValidationState = useSelector((state: TState) => state.ui.fieldValidation);
  const [editorState, setEditorState] = useState<any>();
  const [descriptionText, setDescriptionText] = useState('');
  const debouncedDescriptionText = useDebounce(descriptionText, 500) as string;
  const { onInputChange, onInputBlur } = useFieldEvent(STEP_NUMBER);

  const createEditorState = (description: string) => {
    const blocksFromHTML = htmlToDraft(description || '');
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(state));
  };

  useEffect(() => {
    const description = meetup?.Description || '';
    const locationState = (location.state as { copy: boolean }) || { copy: false };

    if (!editorState) {
      if (window.location.pathname === '/arrangementer/opret') {
        // If the custom location state property copy is set to true, we know that the copy
        // process has started.
        if (locationState.copy) {
          // But only when the IsCopy property on the actual meetup is set, the copy process
          // is complete and the description editor can be initialized.
          if (meetup.IsCopy) {
            createEditorState(description);
          }
        } else if (description === '') {
          setEditorState(EditorState.createEmpty());
        } else {
          createEditorState(description);
        }
      } else if (meetup.MeetupNumber) {
        createEditorState(description);
      }
    }
  }, [meetup]);

  useEffect(() => {
    if (debouncedDescriptionText && debouncedDescriptionText.length > 1) {
      dispatch(
        uiActions.updateFieldValidation({
          step: STEP_NUMBER,
          fields: [FieldsDescription.Description],
          meetup: {
            ...meetup,
            [FieldsDescription.Description]: debouncedDescriptionText,
          },
          showMessage: true,
        }),
      );
    }
  }, [debouncedDescriptionText]);

  return (
    <>
      <Grid container spacing="xl">
        <Grid item sm={8}>
          <TextField
            name={FieldsDescription.Title}
            label={texts[language].title.label}
            placeholder={texts[language].title.placeholder}
            description={texts[language].title.helpText}
            onBlur={onInputBlur}
            value={meetup.Title || ''}
            onChange={onInputChange}
            error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsDescription.Title)}
            required
            cypress="title"
          />
          <TextAreaField
            name={FieldsDescription.Teaser}
            label={texts[language].teaser.label}
            placeholder={texts[language].teaser.placeholder}
            description={texts[language].teaser.helpText}
            onBlur={onInputBlur}
            value={meetup.Teaser || ''}
            onChange={onInputChange}
            error={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsDescription.Teaser)}
            maxLength={255}
            cypress="teaser"
          />
        </Grid>
        <Grid item sm={8} spacing={0}>
          <Paragraph text={texts[language].description.label} mb="xs" mt="xl" />
          <span data-cypress="description">
            <Editor
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline'],
                },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H2', 'H3'],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                },
              }}
              editorState={editorState}
              onEditorStateChange={(e) => {
                setEditorState(e);
                const content = draftToHtml(convertToRaw(e.getCurrentContent()));
                dispatch(
                  meetupActions.setMeetup({
                    ...meetup,
                    [FieldsDescription.Description]: content,
                  }),
                );
                // Used for debouncing description validation.
                setDescriptionText(content);
              }}
              wrapperStyle={{
                background: 'white',
                borderRadius: 16,
                boxShadow: '0 4px 10px 0 rgb(0 0 0 / 10%)',
                paddingBottom: 40,
                overflow: 'hidden',
              }}
              toolbarStyle={{
                borderRadius: 16,
                border: 0,
                padding: '13px 16px 0 16px',
                marginBottom: 0,
              }}
              editorStyle={{
                padding: '13px 20px',
                minHeight: 200,
                maxHeight: 500,
              }}
            />{' '}
          </span>

          <Caption text={texts[language].description.helpText} mt="md" variant="neutral" />

          <Paragraph
            variant="error"
            text={getErrorMessage(fieldValidationState, STEP_NUMBER, FieldsDescription.Description)}
          />
        </Grid>

        <Grid item sm={8}>
          <Checkbox
            id="checkboxIsRelevantForInternationals"
            name={FieldsDescription.IsRelevantForInternationals}
            checked={meetup.IsRelevantForInternationals}
            onChange={onInputChange}
            text={texts[language].isRelevantForInternationals.label}
            cypress="isRelevantForInternationals"
            mt="xl"
          />
          <Caption variant="neutral" text={texts[language].isRelevantForInternationals.helpText} />
        </Grid>
      </Grid>
    </>
  );
}
